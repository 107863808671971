import { ReactNode } from "react"
import { Info } from "@mui/icons-material"
import Icon from "../.././icons/Icon"

interface InfoIconProps {
    info: string | ReactNode
}

export default function InfoIcon({ info }: InfoIconProps) {
    return (
        <Icon
            component={Info}
            fontSize="small"
            tooltipProps={{
                content: info,
                sx: { width: 200 },
                anchorOrigin: { vertical: "center", horizontal: "left" },
                transformOrigin: { vertical: "center", horizontal: "right" }
            }}
        />
    )
}
