import { RefObject, useCallback, useEffect, useState } from "react"
import { IVisualEditor } from "@encoway/cui-application-components/src/visualizationService.types"
import eventBus from "../../eventBus"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { Button, Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import VisualizationSlice from "../../../../../../features/visualization/visualization.slice"
import useAr from "../../../../../../features/visualization/useAr"
import ErrorSlice from "../../../../../../features/error/error.slice"
import readError from "../../../../../../features/error/utils/readError"
import useDialog from "../../../../../../components/dialog/useDialog"
import { ArResponse, IVisualizationNodeExtended } from "../../../../../../features/visualization/visualization.api.types"
import AbbArDialog from "./AbbArDialog"
import { ResizableBox } from "react-resizable"
import { ResizeHandle } from "../../../../../../components/resizeHandle/ResizeHandle"
import { ITheme } from "@fluentui/react"
import { IAppSettings } from "@encoway/cui-application-components/src/appUtil"
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities"
import { AbbVisualization } from "./AbbVisualization"
import useVisualization from "../../../../../../features/visualization/useVisualization"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"
import { NumberValue } from "@encoway/c-services-js-client"

export interface IAbbVisualizationComponentProps {
    theme: ITheme
    settings: IAppSettings
    visualEditorRef: RefObject<IVisualEditor>
    styles?: IStyleFunctionOrObject<any, any>
}

export function AbbVisualizationComponent({ styles, theme, visualEditorRef }: IAbbVisualizationComponentProps) {
    const dialog = useDialog()
    const [arResponse, setArResponse] = useState<ArResponse>()
    const isFullscreen = useAppSelector(state => state.visualization.isFullscreen)
    const fullscreenElement = Array.from(document.getElementsByClassName("configuration")).at(0)
    const dispatch = useAppDispatch()
    const ar = useAr()

    const visualizationQuery = useVisualization()

    useEffect(() => {
        dispatch(VisualizationSlice.actions.setVisible(true))
        return () => {
            dispatch(VisualizationSlice.actions.setVisible(false))
        }
    }, [dispatch])

    const fullscreenChangeEventHandler = useCallback(
        (event: Event) => {
            if (!document.fullscreenElement && event.target) {
                const fullscreenElement = event.target as HTMLElement
                dispatch(VisualizationSlice.actions.setFullscreen(false))
                fullscreenElement.removeEventListener("fullscreenchange", fullscreenChangeEventHandler)
                document.body.appendChild(document.getElementById("fluent-default-layer-host")!)
            }
        },
        [dispatch]
    )

    useEffect(() => {
        if (isFullscreen && fullscreenElement) {
            fullscreenElement
                .requestFullscreen()
                .then(() => fullscreenElement.addEventListener("fullscreenchange", fullscreenChangeEventHandler))
                .then(() => fullscreenElement.appendChild(document.getElementById("fluent-default-layer-host")!))
                .catch(e => readError(e).then(error => dispatch(ErrorSlice.actions.set(error))))
        }
    }, [fullscreenElement, dispatch, isFullscreen, fullscreenChangeEventHandler])

    const openDialog = () => {
        ar.update().then(setArResponse)
        dialog.open()
    }

    return visualizationQuery.visualization ? (
        <Stack spacing={2} my={3}>
            {/* @ts-ignore */}
            <ResizableBox height={400} width={Infinity} resizeHandles={["s"]} minConstraints={[undefined, 400]} handle={<ResizeHandle />}>
                <AbbVisualization
                    settings={visualizationQuery.settings?.settings}
                    visualization={visualizationQuery.visualization}
                    locale={L10n.currentFullLocale()}
                    theme={theme}
                    styles={styles}
                    eventBus={eventBus}
                    onContextMenu={createContextMenu(visualizationQuery)}
                    visualEditorForwardRef={visualEditorRef}
                />
            </ResizableBox>

            <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={openDialog}>
                    {L10n.format(TranslationKeys.pages.configuration.visualization.ar.buttonLabel)}
                </Button>
                {isFullscreen && (
                    <Button variant="contained" onClick={() => document.exitFullscreen()}>
                        {L10n.format(TranslationKeys.pages.configuration.visualization.exitFullscreenButtonLabel)}
                    </Button>
                )}
            </Stack>
            <AbbArDialog close={dialog.close} isOpen={dialog.isOpen} qr={arResponse?.qr} error={ar.error} isLoading={ar.isLoading} />
        </Stack>
    ) : null
}

const determineNodesToChangeState = (selectionCharacteristics: string, visualizationQuery: ReturnType<typeof useVisualization>) => {
    const nodes = visualizationQuery.visualization?.cloud?.graph().nodes().slice(0) as IVisualizationNodeExtended[]
    return nodes?.filter(node =>
        Object.keys(selectionCharacteristics).some(characteristicKey => {
            const characteristicValue = CatalogUtils.getCharacteristicValue<NumberValue>(node.props.product.product, characteristicKey)
            if (!characteristicValue) {
                // characteristic is not existing at all on the product
                return false
            }
            // in an object, for text values not
            return characteristicValue.value === selectionCharacteristics[characteristicKey as keyof typeof selectionCharacteristics]
        })
    )
}
const createContextMenu = (visualizationQuery: ReturnType<typeof useVisualization>) => {
    return (
        node: IVisualizationNodeExtended,
        dismissMenu: () => void,
        selectedContextAnchor: object,
        visualizationQuery: ReturnType<typeof useVisualization>
    ) => {
        if (selectedContextAnchor == null) {
            return { items: [] }
        }
        try {
            const customContextMenuCharacteristic = JSON.parse(CatalogUtils.getCharacteristicValue(node.props.product.product, "contextAnchorItems")!)
            // create context menu entry for every key of the json out of the characteristic
            const customItems = Object.keys(customContextMenuCharacteristic).map(menuItemKey => {
                const contextMenuEntry = customContextMenuCharacteristic[menuItemKey]
                // determine if the 'on' or 'off' toggle action should be used
                const toggleIsOn = node.state.state[menuItemKey] === true
                // choose the action/presentation of the state that is currently not set
                const itemDefinition = toggleIsOn ? contextMenuEntry.off : contextMenuEntry.on
                // create item with attributes that are mandatory
                return {
                    key: menuItemKey,
                    onClick: () => {
                        // set state in nodes that match the selectorCharacteristics of the item
                        const nodesToSetStateFor = determineNodesToChangeState(contextMenuEntry.selector || {}, visualizationQuery)
                        nodesToSetStateFor?.forEach(selectedNode => selectedNode.state.setState(itemDefinition.state))
                        node.state.setState({ [menuItemKey]: !toggleIsOn })
                        dismissMenu && dismissMenu()
                    },
                    ...(itemDefinition.icon && { iconProps: { iconName: itemDefinition.icon } }),
                    ...(itemDefinition.iconOnly && { iconOnly: itemDefinition.iconOnly }),
                    ...(itemDefinition.name && { name: L10n.format(itemDefinition.name) })
                }
            })

            return {
                items: customItems,
                replace: true // override default menu items
            }
        } catch (e) {
            return { items: [] }
        }
    }
}
