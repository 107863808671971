import theme from "../../../../../App.theme"

const ProductCardStyles = (isConfigurable: boolean, disabled: boolean) => {
    return {
        card: {
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
            border: "1px solid " + theme.palette.divider,
            height: "100%",
            width: 300,
            textAlign: "center",
            cursor: "context-menu",
            ":hover": {
                boxShadow: disabled ? "none" : theme.shadows.at(5)
            }
        },
        stack: {
            alignItems: "center",
            width: "100%",
            maxWidth: "100%"
        },
        productImage: {
            height: "200px",
            width: "100%",
            opacity: disabled ? 0.5 : 1
        },
        PRODUCT_NAME: {
            fontWeight: "bold",
            fontSize: theme.typography.h6.fontSize,
            opacity: disabled ? 0.5 : 1
        },
        description: {
            fontSize: theme.typography.body2.fontSize,
            opacity: disabled ? 0.5 : 1
        },
        expandDescriptionButton: {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
            opacity: disabled ? 0.5 : 1,
            ":hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                cursor: "default"
            }
        },
        configureButton: {
            backgroundColor: theme.palette.divider,
            color: theme.palette.text.primary,
            display: !isConfigurable ? "none" : undefined,
            width: "100%",
            maxWidth: "100%"
        },
        configureButtonIcon: {
            color: disabled ? theme.palette.text.disabled : theme.palette.text.primary
        },
        addCompositionButton: {
            width: "100%",
            maxWidth: "100%"
        }
    }
}

export default ProductCardStyles
