import dimensioningApi from "../../../../../../../features/dimensioning/dimensioning.api"
import CatalogApi from "../../../../../../../features/catalog/catalog.api"
import { CharacteristicIds } from "../../../../../../../features/catalog/catalog.constants"
import { GridRenderCellParams, GridRowClassNameParams } from "@mui/x-data-grid"
import { DcsDimensioningResultEntry } from "../../../../../../../features/dimensioning/dimensioning.types"
import { Button } from "@mui/material"
import { AbbDimensioningDcsColumns } from "./AbbDimensioningDcsColumns"
import DataGrid, { DataGridProps } from "../../../../../../../components/dataGrid/DataGrid"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { ReactNode } from "react"
import { getIdsOfEnabledDcDimensioningResults, getIdsOfBestDcDimensioningResults, mapDimensioningDcColumns } from "../AbbDimensioningDc.utils"

interface AbbDimensioningDcsDataGridProps {
    configurationId: string
    parameterId: string
    renderProductNameCell: (params: GridRenderCellParams<DcsDimensioningResultEntry>) => ReactNode
    dataGridProps?: Partial<DataGridProps>
}

export function AbbDimensioningDcsDataGrid({ configurationId, parameterId, renderProductNameCell, dataGridProps }: AbbDimensioningDcsDataGridProps) {
    const dimensioningDcsQuery = dimensioningApi.useDimensioningDcsQuery({ configurationId, parameterId })
    const characteristics = [
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcModuleFrameSize).data,
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcModuleDimension).data,
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcThyristorMaxTemp).data,
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcProductName).data,
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcThyristorTemp).data,
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcCurrent).data,
        CatalogApi.useCharacteristicQuery(CharacteristicIds.DcMargin).data
    ]

    const highlighted = getIdsOfBestDcDimensioningResults(dimensioningDcsQuery.data)
    const enabled = getIdsOfEnabledDcDimensioningResults(dimensioningDcsQuery.data)

    const getRowClassName = (params: GridRowClassNameParams<DcsDimensioningResultEntry>) => (highlighted.includes(params.row.id) ? "highlighted" : "")

    return (
        <>
            <Button variant="contained" size="large" onClick={dimensioningDcsQuery.refetch}>
                {L10n.format(TranslationKeys.pages.configuration.dimensioningButtonLabel)}
            </Button>
            <DataGrid
                {...dataGridProps}
                columns={mapDimensioningDcColumns(AbbDimensioningDcsColumns, characteristics, renderProductNameCell)}
                rows={dimensioningDcsQuery.data?.filter(r => enabled.includes(r.id)) ?? []}
                getRowClassName={getRowClassName}
                loading={dimensioningDcsQuery.isFetching}
            />
        </>
    )
}
