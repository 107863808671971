import { useEffect, useState } from "react"
import { L10n } from "@encoway/l10n"
import { Button, Paper, Stack } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import TranslationKeys from "../../features/translations/TranslationKeys"
import NewProjectDialog from "../../components/project/newProjectDialog/NewProjectDialog"
import ProjectsDataGrid from "./components/projectsDataGrid/ProjectsDataGrid"
import BackToLink from "../../components/links/backToLink/BackToLink"
import SearchInput from "../../components/input/searchInput/SearchInput"
import Dialog from "../../components/dialog/Dialog"
import useDialog from "../../components/dialog/useDialog"
import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import LockedProjectDialog from "../../components/project/lockedProjectDialog/LockedProjectDialog"
import RouterPaths from "../../router/constants/RouterPaths"
import { useSearchParams } from "react-router-dom"
import { Constants } from "@encoway/sales-api-js-client"
import SalesApi from "../../features/sales/sales.api"
import { SalesDocumentProperties, SalesDocumentsPaging } from "../../features/sales/sales.constants"
import SalesUtils from "../../features/sales/sales.utils"
import useOpenSalesDocumentHandler from "../../features/sales/hooks/useOpenSalesDocumentHandler"
import useDeleteSalesDocumentHandler from "../../features/sales/hooks/useDeleteSalesDocumentHandler"

function getFilterModelBySearchParams(searchParams: URLSearchParams) {
    const gridFilterItem = Array.from(searchParams.entries())
        .map(([key, value]) => ({ field: key, value: value, operator: Constants.Operator.Equal }))
        .find(item => Object.values(SalesDocumentProperties).includes(item.field as any))
    return gridFilterItem ? { items: [gridFilterItem] } : undefined
}

export default function ProjectsPage() {
    const dialog = useDialog()
    const [searchParams] = useSearchParams()
    const [searchValue, setSearchValue] = useState<string>("")
    const defaultFilterModel = getFilterModelBySearchParams(searchParams)
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(defaultFilterModel)

    useEffect(() => {
        setFilterModel(getFilterModelBySearchParams(searchParams))
    }, [searchParams])

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: SalesDocumentsPaging.DEFAULT_SORT_FIELD,
            sort: SalesDocumentsPaging.DEFAULT_SORT_DIRECTION
        }
    ])

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: SalesDocumentsPaging.INITIAL_PAGE,
        pageSize: SalesDocumentsPaging.INITIAL_PAGE_SIZE
    })

    const salesDocuments = SalesApi.useSalesDocumentsQuery({
        searchValue: searchValue,
        pagingLoadConfig: SalesUtils.salesDocuments.getPaging(paginationModel, sortModel),
        filterConfig: SalesUtils.salesDocuments.getFilter(filterModel)
    })

    const openSalesDocumentHandler = useOpenSalesDocumentHandler()
    const deleteSalesDocumentHandler = useDeleteSalesDocumentHandler()
    const [copySalesDocument] = SalesApi.useCopySalesDocumentMutation()

    return (
        <section>
            <Stack spacing={3}>
                <BackToLink text={L10n.format(TranslationKeys.pages.dashboard.pageName)} route={RouterPaths.DASHBOARD} />

                <Stack direction="row" spacing={3}>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={dialog.open}>
                        {L10n.format(TranslationKeys.project.newProject.button.label)}
                    </Button>
                    <SearchInput onSearch={setSearchValue} placeholder={L10n.format(TranslationKeys.pages.projects.searchInputPlaceholder)} />
                </Stack>

                <Paper>
                    <ProjectsDataGrid
                        rows={salesDocuments.data?.data || []}
                        rowCount={salesDocuments.data?.totalCount ?? 0}
                        onSelectProject={openSalesDocumentHandler.open}
                        onDeleteProject={deleteSalesDocumentHandler.setSalesDocumentToDelete}
                        onCopyProject={copySalesDocument}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        filterModel={filterModel}
                        onFilterModelChange={setFilterModel}
                    />
                </Paper>

                <LockedProjectDialog
                    open={!!openSalesDocumentHandler.lockedSalesDocument}
                    projectCanBeOpened={openSalesDocumentHandler.lockedSalesDocumentCanBeOpened}
                    lockedBy={openSalesDocumentHandler.lockedSalesDocument?.properties.quote_locked_by}
                    onOpenLockedProject={openSalesDocumentHandler.openLockedSalesDocument}
                    onCancelOpen={openSalesDocumentHandler.cancel}
                />

                <NewProjectDialog {...dialog} />

                <Dialog
                    open={!!deleteSalesDocumentHandler.salesDocumentToDelete}
                    onClose={deleteSalesDocumentHandler.cancel}
                    title={L10n.format(TranslationKeys.project.deleteDialog.title)}
                    message={L10n.format(TranslationKeys.project.deleteDialog.message)}
                    defaultDialogActions={{
                        onConfirm: deleteSalesDocumentHandler.confirm,
                        onCancel: deleteSalesDocumentHandler.cancel,
                        confirmButtonLabel: TranslationKeys.project.deleteDialog.confirmButtonLabel,
                        cancelButtonLabel: TranslationKeys.project.deleteDialog.cancelButtonLabel
                    }}
                />
            </Stack>
        </section>
    )
}
