import React, { useState } from "react"
import { Button, Card, Stack, Typography, useMediaQuery } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ProductGroup } from "@encoway/c-services-js-client"
import ProductGroupCardStyles from "./ProductGroupCard.styles"
import useTextBox from "../../../../../components/textBox/useTextBox"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import TextBox from "../../../../../components/textBox/TextBox"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { CharacteristicIds } from "../../../../../features/catalog/catalog.constants"
import ABBLogo from "../../../../../assets/logo.svg"
import Image from "../../../../../components/image/Image"
import { NewTabLink } from "../../../../../components/links/newTabLink/NewTabLink"

export enum ProductTileSize {
    Default,
    Big
}

interface ProductGroupTileProps {
    productGroup: ProductGroup
    onSelect: (productGroup: ProductGroup) => void
    size?: ProductTileSize
    disabled?: boolean
}

export default function ProductGroupCard({ productGroup, onSelect, size = ProductTileSize.Default, disabled = false }: ProductGroupTileProps) {
    const [hover, setHover] = useState<boolean>(false)
    const touchIsPrimaryInput = useMediaQuery("(hover: none)")
    const textBox = useTextBox()
    const catalogLink = CatalogUtils.getCharacteristicValue<string>(productGroup, CharacteristicIds.CatalogLink)
    const imageSrc = CatalogUtils.getMediaUri(productGroup, CharacteristicIds.ProductImage)
    const styles = ProductGroupCardStyles(size, hover || touchIsPrimaryInput, textBox.isExpanded, disabled)

    return size !== ProductTileSize.Big ? (
        <Card
            component={Stack}
            direction="row"
            spacing={2}
            sx={styles.card}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            data-cy={`ProductGroupCard.${productGroup.id}`}
        >
            <Stack spacing={1} alignItems="center">
                <Image
                    src={imageSrc}
                    fallbackImageProps={{
                        src: ABBLogo,
                        alt: "image of " + productGroup.name,
                        sx: styles.image
                    }}
                />
                <NewTabLink href={catalogLink} text={L10n.format(TranslationKeys.pages.project.catalog.link)} disabled={!catalogLink || disabled} />
            </Stack>
            <Stack spacing={1}>
                <Typography sx={styles.name}>{productGroup.name}</Typography>
                <TextBox
                    text={CatalogUtils.getCharacteristicValue<string>(productGroup, CharacteristicIds.ShortText) || ""}
                    sx={styles.description}
                    expand={textBox.isExpanded}
                    onExpansionChange={textBox.setIsExpanded}
                    characterLimit={80}
                    buttonProps={{ sx: styles.expandDescriptionButton }}
                />
                <Button data-cy={`ProductGroupCard.${productGroup.id}.Button`} onClick={disabled ? undefined : () => onSelect(productGroup)} sx={styles.button}>
                    {L10n.format(TranslationKeys.pages.project.catalog.selectProductGroupButtonLabel)}
                </Button>
            </Stack>
        </Card>
    ) : (
        <Card
            component={Stack}
            spacing={1}
            sx={styles.card}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            data-cy={`ProductGroupCard.${productGroup.id}`}
        >
            <Image
                src={imageSrc}
                fallbackImageProps={{
                    src: ABBLogo,
                    alt: "image of " + productGroup.name,
                    sx: styles.image
                }}
            />
            <Typography data-cy={"Typography:ProductGroupCard"} sx={styles.name}>
                {productGroup.name}
            </Typography>
            <TextBox
                text={CatalogUtils.getCharacteristicValue<string>(productGroup, CharacteristicIds.ShortText) || ""}
                sx={styles.description}
                expand={textBox.isExpanded}
                onExpansionChange={textBox.setIsExpanded}
                characterLimit={135}
                buttonProps={{ sx: styles.expandDescriptionButton }}
            />
            <NewTabLink href={catalogLink} text={L10n.format(TranslationKeys.pages.project.catalog.link)} disabled={!catalogLink || disabled} />
            <Button data-cy={`ProductGroupCard.${productGroup.id}.Button`} onClick={disabled ? undefined : () => onSelect(productGroup)} sx={styles.button}>
                {L10n.format(TranslationKeys.pages.project.catalog.selectProductGroupButtonLabel)}
            </Button>
        </Card>
    )
}
