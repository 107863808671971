import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import DataGrid from "../../../../../components/dataGrid/DataGrid"
import Row from "./components/row/Row"
import { createContext, useEffect, useMemo, useState } from "react"
import lineItemsDataGridColumns from "./constants/lineItemsDataGridColumns"
import getCellValue from "./utils/getCellValue"
import setCellValue from "./utils/setCellValue"
import { GridCellEditStopParams, GridCellParams, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid"
import LineItemsDataGridStyles, { FOOTER_ROW_ID } from "./LineItemsDataGrid.styles"
import { BomNode } from "../../../../../features/sales/sales.types"
import { LineItemProperties } from "../../../../../features/sales/sales.constants"
import SalesUtils from "../../../../../features/sales/sales.utils"
import SalesApi from "../../../../../features/sales/sales.api"

interface LineItemDataGridProps {
    lineItems: BomNode[]
    onUpdateProperty: (lineItem: BomNode, property: string, value: any) => void
    onToggle: (lineItem: BomNode) => void
    onConfigure: (lineItem: BomNode) => void
    onDuplicate: (lineItem: BomNode) => void
    onDelete: (lineItem: BomNode) => void
    onGenerateDocument: (lineItem: BomNode) => void
    onMove: (lineItemId: string, parentLineItemId?: string, previousLineItemId?: string) => void
    calculationViewIsSelected: boolean
}

export const LineItemDataGridContext = createContext<LineItemDataGridProps | undefined>(undefined)

export default function LineItemsDataGrid(props: LineItemDataGridProps) {
    const { lineItems, onUpdateProperty } = props
    const salesDocument = SalesApi.useSalesDocumentQuery().data!
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})

    useEffect(() => {
        setColumnVisibilityModel({
            [LineItemProperties.ABB_LINE_ITEM_ID]: false,
            [LineItemProperties.CONFIGURATION_NAME]: false,
            [LineItemProperties.CREATED_BY]: false,
            [LineItemProperties.CREATED_AT]: false,
            [LineItemProperties.MODIFIED_AT]: false,
            [LineItemProperties.MODIFIED_BY]: false,
            [LineItemProperties.ARTICLE_ID]: !props.calculationViewIsSelected,
            [LineItemProperties.LIST_PRICE]: props.calculationViewIsSelected,
            [LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY]: props.calculationViewIsSelected,
            [LineItemProperties.MULTIPLIER]: props.calculationViewIsSelected,
            [LineItemProperties.PRICEGROUP]: props.calculationViewIsSelected,
            [LineItemProperties.TOTAL_LIST_PRICE]: props.calculationViewIsSelected
        })
    }, [props.calculationViewIsSelected])

    const footerRow = {
        expanded: true,
        level: 0,
        item: {
            lineItemId: FOOTER_ROW_ID,
            permission: [],
            propertyPermissions: {},
            configurationPermission: [],
            properties: {
                [LineItemProperties.QUOTE_RELEVANT]: L10n.format(TranslationKeys.pages.project.composition.total) as any,
                [LineItemProperties.TOTAL_LIST_PRICE]: salesDocument.calculationProperties.totalListPrice,
                [LineItemProperties.TOTAL_TRANSFER_PRICE]: salesDocument.calculationProperties.totalTransferPrice
            }
        }
    }

    const columns: GridColDef[] = useMemo(
        () =>
            lineItemsDataGridColumns.map(column => ({
                ...column,
                headerName: column.headerName ? L10n.format(column.headerName) : column.headerName,
                valueGetter: column.valueGetter ?? getCellValue,
                valueSetter: column.valueSetter ?? (params => setCellValue(params, column.field)),
                editable: column.editable !== false
            })),
        []
    )

    const onCellEditStop = (params: GridCellEditStopParams<BomNode, any, any>): void => {
        onUpdateProperty(params.row, params.field, params.value)
    }

    const isCellEditable = (params: GridCellParams<BomNode>) => {
        return (
            SalesUtils.lineItems.isEditableLineItemProperty(params.row.item, params.field) &&
            params.row.item.lineItemId !== FOOTER_ROW_ID &&
            params.row.item.properties.COMMON_OPTION_SET_BY_SYSTEM !== "true"
        )
    }

    return (
        <LineItemDataGridContext.Provider value={props}>
            <DataGrid
                rows={lineItems.concat(footerRow)}
                getRowId={row => row.item.lineItemId}
                getRowClassName={params => (FOOTER_ROW_ID === params.id.toString() ? FOOTER_ROW_ID : "")}
                sx={LineItemsDataGridStyles}
                columns={columns}
                noRowsLabel={L10n.format(TranslationKeys.pages.project.composition.noLineItemsFound)}
                noResultsOverlayLabel={L10n.format(TranslationKeys.pages.project.composition.noLineItemResults)}
                hideFooter
                isCellEditable={isCellEditable}
                onCellEditStop={onCellEditStop}
                slots={{ row: Row }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={setColumnVisibilityModel}
            />
        </LineItemDataGridContext.Provider>
    )
}
