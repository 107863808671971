import SnackbarStyles from "./Snackbar.styles"
import { Alert as MuiAlert, LinearProgress, Snackbar as MuiSnackbar } from "@mui/material"
import { Snackbar as ISnackbar } from "../../../../../features/snackbar/snackbar.slice"
import { useCallback, useEffect, useState } from "react"

const INTERVAL = 1000 / 60

interface SnackbarProps extends ISnackbar {
    onClose: (id: number) => void
}

export default function Snackbar({ id, message, severity, autoHideDuration, onClose }: SnackbarProps) {
    const [lifeTime, setLifeTime] = useState(0)

    const updateNow = useCallback(() => {
        setLifeTime(prevState => prevState + INTERVAL)
    }, [])

    const close = useCallback(() => {
        onClose(id)
    }, [id, onClose])

    useEffect(() => {
        if (autoHideDuration && lifeTime < autoHideDuration) {
            const interval = window.setInterval(updateNow, INTERVAL)
            return () => window.clearInterval(interval)
        } else if (autoHideDuration) {
            close()
        }
    }, [autoHideDuration, lifeTime, updateNow, close])

    return (
        <MuiSnackbar
            open
            onClose={(_event, reason) => (reason !== "clickaway" ? close() : undefined)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={SnackbarStyles.snackbar}
        >
            <MuiAlert onClose={close} severity={severity} sx={SnackbarStyles.alert}>
                {message}
                {autoHideDuration ? (
                    <LinearProgress sx={SnackbarStyles.progressBar} variant="determinate" color={severity} value={(lifeTime / autoHideDuration) * 100} />
                ) : null}
            </MuiAlert>
        </MuiSnackbar>
    )
}
