import useGenericForm from "../../../../components/forms/genericForm/useGenericForm"
import getProjectInformationInputs from "./getProjectInformationInputs"
import getCustomerInformationInputs from "./getCustomerInformationInputs"
import getGeneralInformationInputs from "./getGeneralInformationInputs"
import getContactInformationInputs from "./getContactInformationInputs"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import SalesApi from "../../../../features/sales/sales.api"
import CatalogApi from "../../../../features/catalog/catalog.api"
import { CharacteristicIds } from "../../../../features/catalog/catalog.constants"
import { AbbSalesDocumentEntity, AbbSalesDocumentProperty } from "../../../../features/sales/sales.types"
import SalesUtils from "../../../../features/sales/sales.utils"

export default function useProjectForms(
    salesDocument?: AbbSalesDocumentEntity,
    updateSalesDocumentProperty?: (property: AbbSalesDocumentProperty, value: string | number) => void
) {
    const disableInput = (property: AbbSalesDocumentProperty) => {
        return salesDocument ? !SalesUtils.salesDocument.isEditableProperty(property, salesDocument) : false
    }

    const properties = salesDocument?.properties ?? {}
    const update = updateSalesDocumentProperty ?? (() => undefined)

    const priceLists = SalesApi.usePriceListsQuery().data
    const priceList = priceLists?.find(priceList => priceList.priceListId === salesDocument?.properties.quote_pricelist)
    const localSalesUnit = CatalogApi.useCharacteristicQuery(CharacteristicIds.LocalSalesUnit).data
    const projectInformationInputs = getProjectInformationInputs(localSalesUnit, priceList)
    const projectInformationForm = useGenericForm(projectInformationInputs, properties, update, disableInput)

    const industrySegment = CatalogApi.useCharacteristicQuery(CharacteristicIds.IndustrySegment).data
    const destinationCountry = CatalogApi.useCharacteristicQuery(CharacteristicIds.DestinationCountry).data
    const customerInformationInputs = getCustomerInformationInputs(industrySegment, destinationCountry)
    const customerInformationForm = useGenericForm(customerInformationInputs, properties, update, disableInput)

    const generalInformationForm = useGenericForm(getGeneralInformationInputs(), properties, update, disableInput)
    const contactInformationForm = useGenericForm(getContactInformationInputs(), properties, update, disableInput)

    const forms = [
        {
            title: L10n.format(TranslationKeys.pages.project.details.generalInformation),
            isValid: generalInformationForm.isValid,
            formProps: generalInformationForm.formProps
        },
        {
            title: L10n.format(TranslationKeys.pages.project.details.projectInformation),
            isValid: projectInformationForm.isValid,
            formProps: projectInformationForm.formProps
        },
        {
            title: L10n.format(TranslationKeys.pages.project.details.customerInformation),
            isValid: customerInformationForm.isValid,
            formProps: customerInformationForm.formProps
        },
        {
            title: L10n.format(TranslationKeys.pages.project.details.contactInformation),
            isValid: contactInformationForm.isValid,
            formProps: contactInformationForm.formProps
        }
    ]

    const numberOfInvalidInputs =
        generalInformationForm.numberOfInvalidInputs +
        projectInformationForm.numberOfInvalidInputs +
        customerInformationForm.numberOfInvalidInputs +
        contactInformationForm.numberOfInvalidInputs

    return {
        forms,
        numberOfInvalidInputs
    }
}
