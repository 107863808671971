import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import {
    CatalogService,
    Characteristic,
    ProductGroup,
    ProductGroupResult,
    ProductGroupsResult,
    ProductResult,
    Selection,
    SelectProductsResult
} from "@encoway/c-services-js-client"
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import readError from "../error/utils/readError"
import CatalogUtils from "./catalog.utils"
import { CharacteristicIds } from "./catalog.constants"
import { FetchApi } from "../fetch/fetch.api"
import { DocumentType } from "./catalog.types"
import BusySlice from "../busy/busy.slice"

const CatalogApi = createApi({
    reducerPath: "catalogApi",
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        products: builder.query<SelectProductsResult, Selection>({
            queryFn: (selection, api) => wrapCatalogQuery(api, catalogService => catalogService.products(selection))
        }),

        product: builder.query<ProductResult, string>({
            queryFn: (productId, api) => wrapCatalogQuery(api, catalogService => catalogService.product(productId))
        }),

        productGroup: builder.query<ProductGroupResult, string>({
            queryFn: (productGroupId, api) => wrapCatalogQuery(api, catalogService => catalogService.group(productGroupId))
        }),

        productGroups: builder.query<ProductGroup[], string[]>({
            queryFn: async (productGroupIds, api) => {
                return await wrapCatalogQuery(api, async catalogService => {
                    const promiseResult = await Promise.allSettled(productGroupIds.map(id => catalogService.group(id)))
                    return promiseResult.reduce((pgs: ProductGroup[], entry) => (entry.status === "fulfilled" ? pgs.concat(entry.value.productGroup) : pgs), [])
                })
            }
        }),

        subGroups: builder.query<ProductGroupsResult, string>({
            queryFn: (productGroupId, api) => wrapCatalogQuery(api, catalogService => catalogService.subgroups(productGroupId))
        }),

        characteristic: builder.query<Characteristic, string>({
            queryFn: (characteristicId, api) => wrapCatalogQuery(api, catalogService => catalogService.characteristic(characteristicId))
        }),

        documentTypes: builder.query<DocumentType[], string>({
            queryFn: async (documentTypeId, api) => {
                return wrapCatalogQuery(api, async catalogService => {
                    const productResult = await catalogService.product(documentTypeId)
                    const mediaUri = CatalogUtils.getMediaUri(productResult.product, CharacteristicIds.DocumentTypes)!
                    const result = await api.dispatch(FetchApi.endpoints.fetch.initiate({ url: mediaUri })).unwrap()
                    return result[CharacteristicIds.DocumentTypes]
                })
            }
        })
    })
})

export default CatalogApi

async function wrapCatalogQuery<T>(api: BaseQueryApi, query: (catalogService: CatalogService) => Promise<T>) {
    try {
        api.dispatch(BusySlice.actions.setBusy())
        const catalogService = (api.getState() as any).catalog.catalogService // as any necessary because as RootState would cause circular dependency
        const result = await query(catalogService)
        return { data: result }
    } catch (e) {
        return { error: await readError(e) }
    } finally {
        api.dispatch(BusySlice.actions.setIdle())
    }
}
