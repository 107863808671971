import { Parameter, ParameterProps } from "@encoway/cui-configurator-components"
import { IRenderFunction, Label } from "@fluentui/react"
import { ParameterTO } from "@encoway/c-services-js-client"
import AbbParameterLabelStyles from "./AbbParameterLabel.styles"
import React from "react"
import { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import CuiUtils, { NEGATIV_INFINITY_SYMBOL, POSITIV_INFINITY_SYMBOL } from "../../utils/CuiUtils"

type AbbParameterProps = ParameterProps & { hideParameter: boolean }

const AbbParameter = (props: AbbParameterProps) => {
    const renderLabel: IRenderFunction<ParameterTO> = data => {
        return (
            <Label required={data?.mandatory} styles={AbbParameterLabelStyles}>
                {data?.translatedName}
            </Label>
        )
    }

    const formattedData: ParameterTO = CuiUtils.formatParameter(props.data)
    const getRangeText = () => {
        if (formattedData.originalMinValue !== NEGATIV_INFINITY_SYMBOL || formattedData.originalMaxValue !== POSITIV_INFINITY_SYMBOL) {
            return L10n.format(TranslationKeys.pages.configuration.abbInputField.rangeText, {
                minValue: formattedData.originalMinValue,
                maxValue: formattedData.originalMaxValue
            })
        }
    }

    if (props.hideParameter) {
        return null
    } else if (formattedData.viewPort === "inputField" && formattedData.valueType?.startsWith("RANGE_")) {
        return (
            <Stack direction="row" justifyContent="space-between" alignItems="end">
                <Parameter {...props} onRenderLabel={renderLabel} />
                <Typography sx={{ fontSize: "13px" }} paddingRight={2.5} paddingBottom={0.5}>
                    {getRangeText()}
                </Typography>
            </Stack>
        )
    } else {
        return <Parameter {...props} onRenderLabel={renderLabel} />
    }
}

export default AbbParameter
