import { KeyboardEvent } from "react"
import { Product } from "@encoway/c-services-js-client"
import { GridRenderCellParams } from "@mui/x-data-grid"
import TextField from "@mui/material/TextField"
import ProductQuickSelectionColumns from "./ProductQuickSelectionColumns"
import ProductQuickSelectionDataGridStyles from "./ProductQuickSelectionDataGrid.styles"
import DataGrid from "../../../../../../components/dataGrid/DataGrid"
import InfoIcon from "../../../../../../components/icons/infoIcon/InfoIcon"
import ProductInformation from "./productInformation/ProductInformation"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"
import { useSearchParams } from "react-router-dom"
import { SEARCH_PARAM } from "../../ProductSelection"
import CatalogApi from "../../../../../../features/catalog/catalog.api"
import { CharacteristicIds, ViewIds } from "../../../../../../features/catalog/catalog.constants"
import SalesApi from "../../../../../../features/sales/sales.api"

interface ProductQuickSelectionDataGridProps {
    productGroupId: string
    characteristicFilters: Record<string, any>
    updateProductQuantity: (productId: string, quantity: number) => void
    selectedProducts: Record<string, number>
}

export default function ProductQuickSelectionDataGrid({
    productGroupId,
    characteristicFilters,
    updateProductQuantity,
    selectedProducts
}: ProductQuickSelectionDataGridProps) {
    const [searchParams] = useSearchParams()
    const searchValue = searchParams.get(SEARCH_PARAM) ?? undefined
    const productCategory = SalesApi.useSalesDocumentQuery().data?.properties.PRODUCT_CATEGORY

    const productSelection = CatalogUtils.getProductSelection(productGroupId, characteristicFilters, ViewIds.QUICK_SELECTION, ViewIds.SALES_FILTER, searchValue)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)

    const changeQuantity = (productId: string, value: number) => {
        value = isNaN(value) ? 0 : value
        if (value >= 0 && value <= 999) {
            updateProductQuantity(productId, value)
        }
    }

    const preventDefault = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key !== "Backspace" && !/^\d+$/.test(event.key)) {
            event.preventDefault()
        }
    }

    const renderInfoCell = (params: GridRenderCellParams<Product>) => {
        return <InfoIcon info={<ProductInformation productId={params.row.id} />} />
    }

    const renderQuantityCell = (params: GridRenderCellParams<Product>) => {
        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(params.row, CharacteristicIds.ProductCategory)
        return (
            <TextField
                value={selectedProducts[params.row.id] || 0}
                onChange={event => changeQuantity(params.row.id, parseInt(event.target.value))}
                sx={{ width: "8ch" }}
                disabled={!!productCategory && !!characteristicValue && productCategory !== characteristicValue}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 999, sx: { boxSizing: "border-box" } } }}
                onKeyDown={preventDefault}
            />
        )
    }

    const characteristics = productsQuery.data ? CatalogUtils.sortCharacteristicsByView(productsQuery.data, ViewIds.QUICK_SELECTION).characteristics : []

    return (
        <DataGrid
            sx={ProductQuickSelectionDataGridStyles}
            rows={productsQuery.data?.products ?? []}
            getRowId={row => row.id}
            getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? "" : "quickSelectionBackground")}
            columns={ProductQuickSelectionColumns(renderQuantityCell, renderInfoCell, characteristics)}
            hideFooter={(productsQuery.data?.products.length ?? 0) <= 100}
        />
    )
}
