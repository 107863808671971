import TextField, { TextFieldProps } from "@mui/material/TextField"
import InfoIcon from "../icons/infoIcon/InfoIcon"

export interface InputProps extends Omit<TextFieldProps, "label"> {
    label: string
    infoText?: string
}

export default function Input({ label, infoText, ...props }: InputProps) {
    const id = label?.replace(" ", "_") + "_input"
    return (
        <TextField
            data-cy={`TextField:${id}`}
            label={
                <>
                    <span>{label}</span>
                    {infoText ? <InfoIcon info={infoText} /> : null}
                </>
            }
            id={id}
            inputProps={{
                "data-cy": `Input.${id}`
            }}
            autoComplete="on"
            {...props}
        />
    )
}
