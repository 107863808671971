import React, { memo, useEffect } from "react"
import { AppSwitch, ComponentName as ApplicationComponent, registerDefaultApplicationComponents } from "@encoway/cui-application-components"
import { ComponentName as ConfiguratorComponent, registerDefaultConfiguratorComponents } from "@encoway/cui-configurator-components"
import CuiTheme from "./Cui.theme"
import AbbTab from "./components/AbbTab/AbbTab"
import AbbInputField from "./components/AbbInputField/AbbInputField"
import AbbDropdown from "./components/AbbDropdown/AbbDropdown"
import Table from "./components/Table/Table"
import AbbParameter from "./components/AbbParameter/AbbParameter"
import AbbSection from "./components/AbbSection/AbbSection"
import MatlabButton from "./components/MatlabButton/MatlabButton"
import Graph from "./components/Graph/Graph"
import MatlabWarning from "./components/MatlabWarning/MatlabWarning"
import AbbMultilineDisplay from "./components/AbbMultilineDisplay/AbbMultilineDisplay"
import eventBus, { EventBusEvent } from "./eventBus"
import AbbTabs from "./components/AbbTabs/AbbTabs"
import AbbLinkedTree from "./components/AbbLinkedTree/AbbLinkedTree"
import AbbNotReadyCount from "./components/AbbNotReadyCount/AbbNotReadyCount"
import AbbTabsFooter from "./components/AbbTabsFooter/AbbTabsFooter"
import AbbRoot from "./components/AbbRoot/AbbRoot"
import AbbCheckbox from "./components/AbbCheckbox/AbbCheckbox"
import AbbStateIcon from "./components/AbbStateIcon/AbbStateIcon"
import { AbbVisualizationWidget } from "./components/AbbVisualization/AbbVisualizationWidget"
import AbbDetailDropdown from "./components/AbbDetailDropdown/AbbDetailDropdown"
import AbbFilterDetailDropdown from "./components/AbbFilterDetailDropdown/AbbFilterDetailDropdown"
import CuiProperties from "./constants/CuiProperties"
import { BorderLayoutDefinition, IBorderStyles } from "@encoway/cui-application-components/src/components/CUIApp/CUIApp.types"
import AbbOverlaySpinner from "./components/AbbSpinner/AbbOverlaySpinner"
import { AbbOverlaySpinnerTrigger } from "./components/AbbSpinner/AbbOverlaySpinnerTrigger"
import CuiStyles from "./Cui.styles"
import { AbbDimensioningMv } from "./components/AbbDimensioningMv/AbbDimensioningMv"
import AbbDimensioningDc from "./components/AbbDimensioningDc/AbbDimensioningDc"
import { AbbVisualization } from "./components/AbbVisualization/AbbVisualization"
import { useAppDispatch } from "../../../../store/store"
import { CuiQuantityWithCustomValueFormatting } from "./components/AbbQuantity/CuiQuantityWithCustomValueFormatting"
import { CuiAdditionalValueWithCustomValueFormatting } from "./components/AbbAdditionalValue/CuiAdditionalValueWithCustomValueFormatting"
import SalesSlice from "../../../../features/sales/sales.slice"
import dimensioningApi, { dimensioningApiTags } from "../../../../features/dimensioning/dimensioning.api"
import AbbPrice from "./components/AbbPrice/AbbPrice"
import useCuiAppSettings from "../../hooks/useCuiAppSettings"
import Notification from "./components/Notification/Notification"
import { CuiViewPorts } from "./constants/CuiViewPorts"
import useVisualization from "../../../../features/visualization/useVisualization"
import visualizationApi, { visualizationApiTags } from "../../../../features/visualization/visualization.api"
import { IVisualEditor } from "@encoway/cui-application-components/src/visualizationService.types"
import { AbbVisualizationWrapper } from "./components/AbbVisualization/AbbVisualizationWrapper"

registerDefaultApplicationComponents()
    .override(ApplicationComponent.BusyOverlay, AbbOverlaySpinnerTrigger)
    .override(ApplicationComponent.Header, () => null)
    .override(ApplicationComponent.InitialLoadingSpinner, AbbOverlaySpinner)
    // Customizing for ABBM-404 => created FBK-1385
    .override(ApplicationComponent.Visualization, AbbVisualization)

registerDefaultConfiguratorComponents()
    .override(ConfiguratorComponent.AdditionalValue, CuiAdditionalValueWithCustomValueFormatting)
    .override(ConfiguratorComponent.Checkbox, AbbCheckbox)
    .override(ConfiguratorComponent.DetailDropdown, AbbDetailDropdown)
    .override(ConfiguratorComponent.Dropdown, AbbDropdown)
    .override(ConfiguratorComponent.InputField, AbbInputField)
    .override(ConfiguratorComponent.LinkedTree, AbbLinkedTree)
    .override(ConfiguratorComponent.Parameter, AbbParameter)
    .override(ConfiguratorComponent.Price, AbbPrice)
    .override(ConfiguratorComponent.NotReadyCount, AbbNotReadyCount)
    .override(ConfiguratorComponent.Quantity, CuiQuantityWithCustomValueFormatting)
    .override(ConfiguratorComponent.Root, AbbRoot)
    .override(ConfiguratorComponent.Section, AbbSection)
    .override(ConfiguratorComponent.Spinner, AbbOverlaySpinner)
    .override(ConfiguratorComponent.StateIcon, AbbStateIcon)
    .override(ConfiguratorComponent.Tab, AbbTab)
    .override(ConfiguratorComponent.TabsFooter, AbbTabsFooter)
    .override(ConfiguratorComponent.Tabs, AbbTabs)
    .override(CuiViewPorts.FilterDetailDropdown, AbbFilterDetailDropdown)
    .override(CuiViewPorts.Graph, Graph)
    .override(CuiViewPorts.MatlabButton, MatlabButton)
    .override(CuiViewPorts.MatlabWarning, MatlabWarning)
    .override(CuiViewPorts.MultilineDisplay, AbbMultilineDisplay)
    .override(CuiViewPorts.Table, Table)
    .override(CuiViewPorts.Notification, Notification)
    .override(CuiViewPorts.Visualization, AbbVisualizationWidget)
    .override(CuiViewPorts.Dimensioning, AbbDimensioningDc)
    .override(CuiViewPorts.DimensioningMv, AbbDimensioningMv)

const dispatchEvent = (e: any, dispatch: ReturnType<typeof useAppDispatch>) => {
    if (e.event === EventBusEvent.UpdateState || e.event === EventBusEvent.InitialState) {
        dispatch(SalesSlice.actions.setConfigurationContainer(e.rawState.rootContainer))
    }
    if (e.event === EventBusEvent.UpdateState || e.event === EventBusEvent.InitialState) {
        dispatch(SalesSlice.actions.setConfigurationContainer(e.rawState.rootContainer))
    }
    if (e.event === EventBusEvent.ParameterChanged && e.name !== "DCS880_modules") {
        dispatch(dimensioningApi.util?.invalidateTags([dimensioningApiTags.DC_DIMENSIONING]))
    }
    if (e.event === EventBusEvent.UpdateState) {
        dispatch(SalesSlice.actions.setConfigurationIsSavedToFalse())
    }
}
function Cui() {
    const dispatch = useAppDispatch()
    const settings = useCuiAppSettings()
    const visualization = useVisualization()

    useEffect(() => {
        dispatch(visualizationApi.util?.invalidateTags([visualizationApiTags.VISUALIZATION]))
        return eventBus.onValue((e: any) => dispatchEvent(e, dispatch))
    }, [dispatch])

    useEffect(() => {
        if (visualization.visualization) {
            return eventBus
                .filter((e: any) => e.event === EventBusEvent.UpdateState)
                .onValue(() => {
                    visualization.update && visualization.update()
                })
        }
    }, [visualization])

    useEffect(() => {
        if (visualization.visualization) {
            return () => {
                visualization.visualization?.cloud.clear()
            }
        }
    }, [visualization.visualization])

    const visualEditorRef = React.createRef<IVisualEditor>()

    return settings ? (
        <>
            <AppSwitch
                styles={CuiStyles.appSwitch}
                settings={settings}
                eventBus={eventBus}
                layout={CuiProperties.LAYOUT as BorderLayoutDefinition}
                borderStyles={CuiProperties.BORDER_STYLES as IBorderStyles}
                ref={visualEditorRef}
                theme={CuiTheme}
            />
            {visualization.visualization && <AbbVisualizationWrapper visualEditorRef={visualEditorRef} settings={settings} theme={CuiTheme} />}
        </>
    ) : null
}

export default memo(Cui)
