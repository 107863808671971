import { Stack } from "@fluentui/react/lib/Stack"
import { Text } from "@fluentui/react/lib/Text"
import React from "react"
import { IProcessedStyleSet } from "@fluentui/react/lib/Styling"
import { IDetailDropdownStyles } from "@encoway/cui-configurator-components/src/components/DetailDropdown/DetailDropdown.types"
import DetailDropdownShortTextStyles from "./DetailDropdownShortText.styles"
import { IDetailDropdownOption } from "../FilterDetailDropdown.types"

interface IShortTextProps {
    classNames: IProcessedStyleSet<IDetailDropdownStyles>
    dropdownOption: IDetailDropdownOption
    showShortText: boolean
}

const DetailDropdownShortText = (props: IShortTextProps) => {
    const { dropdownOption, showShortText, classNames } = props

    const optionShortText = dropdownOption.shortText
    if (showShortText && optionShortText) {
        return (
            <Stack.Item className={DetailDropdownShortTextStyles.getShortTextClass(dropdownOption, classNames)}>
                <Text>{optionShortText}</Text>
            </Stack.Item>
        )
    }
    return null
}

export default DetailDropdownShortText
