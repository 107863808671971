import { createPortal } from "react-dom"
import { AbbVisualizationComponent } from "./AbbVisualizationComponent"
import React, { RefObject } from "react"
import { IAppSettings } from "@encoway/cui-application-components"
import { IVisualEditor } from "@encoway/cui-application-components/src/visualizationService.types"
import { ITheme } from "@fluentui/react"

interface IVisualizationWrapperProps {
    visualEditorRef: RefObject<IVisualEditor>
    settings: IAppSettings

    theme: ITheme
}

/**
 * Renders the visualization in a portal to ensure that it is always available while a configuration is running.
 * This is important since the configuration and visualization linking is initialized in the Visualization React component.
 *
 * The portal is rendered in the document body and provides a content div.
 * This content div can be moved to the widget in the configuration ui. See AbbVisualizationSupport
 */
/* eslint-disable react-hooks/exhaustive-deps */
export const AbbVisualizationWrapper = (props: IVisualizationWrapperProps) => {
    return createPortal(
        <div style={{ overflow: "hidden", height: 0 }} id="abb.visualization.portal">
            <div id="abb.visualization.content">
                <AbbVisualizationComponent visualEditorRef={props.visualEditorRef} settings={props.settings} theme={props.theme} />
            </div>
        </div>,
        document.body
    )
}
