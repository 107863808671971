import { OpenIdConnect } from "@encoway/sales-showroom-auth"
import { PropsWithChildren } from "react"
import { ContentWithToken } from "./ContentWithToken"
import { RemoveState } from "./RemoveState"
import EnvironmentApi from "../../features/environment/environment.api"

export default function AuthorizedContent(props: PropsWithChildren<unknown>) {
    const environment = EnvironmentApi.usePublicEnvironmentQuery().data
    const redirectUri = window.location.protocol + "//" + window.location.host + window.location.pathname
    return environment ? (
        <RemoveState>
            <OpenIdConnect
                clientId={environment.clientId}
                realm=""
                authenticationServerUrl={environment.issuerUri}
                expectedIssuerUrl={environment.expectedIssuerUri || environment.issuerUri}
                redirectUri={redirectUri}
            >
                <ContentWithToken>{props.children}</ContentWithToken>
            </OpenIdConnect>
        </RemoveState>
    ) : null
}
