import { NavLink } from "react-router-dom"
import AppTitleWithLogoStyles from "./AppTitleWithLogo.styles"
import AppLogo from "./appLogo/AppLogo"
import { Divider, Link, Stack, Typography } from "@mui/material"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { L10n } from "@encoway/l10n"

export default function AppTitleWithLogo() {
    return (
        <Link component={NavLink} to="/" underline="none" sx={AppTitleWithLogoStyles.link}>
            <AppLogo sx={AppTitleWithLogoStyles.logo} />
            <Divider orientation="vertical" flexItem />
            <Stack>
                <Typography variant="h6" noWrap component="span" sx={AppTitleWithLogoStyles.title}>
                    {L10n.format(TranslationKeys.components.header.title)}
                </Typography>
                <Typography variant="subtitle2" noWrap component="span" sx={AppTitleWithLogoStyles.subtitle}>
                    {L10n.format(TranslationKeys.components.header.subTitle)}
                </Typography>
            </Stack>
        </Link>
    )
}
