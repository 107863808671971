import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import UserStyles from "./User.styles"
import { L10n } from "@encoway/l10n"
import SessionExpirationCountdown from "./SessionExpirationCountdown"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { Settings } from "../../../../../settings"
import environmentApi from "../../../../../features/environment/environment.api"
import { useDecodedToken } from "../../../../../features/oidc/hooks/useDecodedToken"
import { useLogout } from "../../../../../features/oidc/hooks/useLogout"

export default function User() {
    const environment = environmentApi.usePublicEnvironmentQuery().data!
    const decodedToken = useDecodedToken()
    const logout = useLogout()
    const name = decodedToken ? decodedToken.username : ""
    return (
        <>
            <Box component="div" sx={UserStyles.wrapper}>
                <Typography variant="caption" sx={UserStyles.text}>
                    <Box data-cy="username" sx={UserStyles.textWelcome}>
                        {L10n.format(TranslationKeys.components.header.welcome)} {name}
                    </Box>
                    <Box>
                        <SessionExpirationCountdown logout={logout} />
                    </Box>
                    <Box>
                        <Box component="span" color="primary.main">
                            {environment.systemEnvironment.toUpperCase()}
                        </Box>{" "}
                        {Settings.configuration.options.articleName} {environment.version}
                    </Box>
                </Typography>
            </Box>
        </>
    )
}
