import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps as MuiBreadcrumbsProps, Link, Typography } from "@mui/material"
import BreadcrumbsStyles from "./Breadcrumbs.styles"
import { NavLink } from "react-router-dom"

export interface Breadcrumb {
    label: string
    route?: string
}

interface BreadcrumbsProps extends Omit<MuiBreadcrumbsProps, "children"> {
    breadcrumbs: Breadcrumb[]
}

export default function Breadcrumbs({ breadcrumbs, ...props }: BreadcrumbsProps) {
    return (
        <MuiBreadcrumbs {...props}>
            {breadcrumbs.map((breadcrumb, index) =>
                index < breadcrumbs.length - 1 ? (
                    <Link component={NavLink} key={breadcrumb.label} to={breadcrumb.route || ""} sx={BreadcrumbsStyles.link}>
                        {breadcrumb.label}
                    </Link>
                ) : (
                    <Typography key={breadcrumb.label} sx={BreadcrumbsStyles.typography}>
                        {breadcrumb.label}
                    </Typography>
                )
            )}
        </MuiBreadcrumbs>
    )
}
