import { GridFilterInputDate, GridFilterInputValue, GridFilterOperator } from "@mui/x-data-grid"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import { Constants } from "@encoway/sales-api-js-client"

export const getDateGridFilterOperators = (): GridFilterOperator[] => [
    {
        label: L10n.format(TranslationKeys.components.dataGrid.filters.before),
        value: Constants.Operator.Less,
        InputComponent: GridFilterInputDate,
        InputComponentProps: { type: "date" },
        getApplyFilterFn: () => () => true
    },
    {
        label: L10n.format(TranslationKeys.components.dataGrid.filters.after),
        value: Constants.Operator.Greater,
        InputComponent: GridFilterInputDate,
        InputComponentProps: { type: "date" },
        getApplyFilterFn: () => () => true
    }
]

export const getContainsGridFilterOperator = (): GridFilterOperator => ({
    label: L10n.format(TranslationKeys.components.dataGrid.filters.contains),
    value: Constants.Operator.Like,
    InputComponent: GridFilterInputValue,
    getApplyFilterFn: () => () => true
})
