import { useCallback, useContext, useEffect, useRef } from "react"
import { GridRow, GridRowProps } from "@mui/x-data-grid"
import RowStyles, { OverlayStyles, RowPreviewStyles } from "./Row.styles"
import BomService from "../../../../../../../features/sales/services/BomService"
import { LineItemDataGridContext } from "../../LineItemsDataGrid"
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd"
import { getDragAndDropHoverPosition, getHoverPositionFrom, getParentFrom, isFooter } from "./rowUtils"
import { AbbLineItem, AbbSalesDocumentEntity } from "../../../../../../../features/sales/sales.types"
import SalesUtils from "../../../../../../../features/sales/sales.utils"
import SalesApi from "../../../../../../../features/sales/sales.api"
import { FOOTER_ROW_ID } from "../../LineItemsDataGrid.styles"

const DRAG_AND_DROP_ITEM_TYPE = "lineItems"

export type DragAndDropHoverPosition = "top" | "center" | "bottom" | undefined

interface DragAndDropItem {
    lineItemId: string
}

export default function Row(gridRowProps: GridRowProps) {
    const { lineItems, onToggle, onMove } = useContext(LineItemDataGridContext)!
    const ref = useRef<HTMLDivElement>(null)
    const lineItem: AbbLineItem = gridRowProps.row?.item
    const salesDocument = SalesApi.useSalesDocumentQuery().data!

    const hasUpdatePermission = (lineItem?: AbbLineItem | AbbSalesDocumentEntity) => !!lineItem && lineItem.permission.includes("UPDATE")
    const canDrag = useCallback(
        (monitor: DragSourceMonitor) => {
            const rect = ref.current?.getElementsByClassName("DragAndDropButton")[0]?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return !!(rect && clientOffset && clientOffset.x >= rect.x && clientOffset.x <= rect.x + rect.width)
        },
        [ref]
    )

    const getHoverPosition = useCallback(monitor => getHoverPositionFrom(monitor, ref), [ref])

    const canDrop = useCallback(
        (item: DragAndDropItem, monitor: DropTargetMonitor) => {
            const hoverPosition = getHoverPosition(monitor)
            if (hoverPosition === "top" || hoverPosition === "bottom") {
                const bomNode = BomService.getBomNode(lineItem.lineItemId, lineItems)!
                return bomNode && !isFooter(lineItem) && hasUpdatePermission(getParentFrom(bomNode, salesDocument))
            } else if (hoverPosition === "center") {
                return !isFooter(lineItem) && hasUpdatePermission(lineItem) && SalesUtils.lineItems.isFolder(lineItem)
            }
            return false
        },
        [lineItem, getHoverPosition, lineItems, salesDocument]
    )

    const drop = useCallback(
        (item: DragAndDropItem, monitor: DropTargetMonitor) => {
            const hoverPosition = getHoverPosition(monitor)
            if (hoverPosition === "top") {
                const bomNode = BomService.getBomNode(lineItem.lineItemId, lineItems)!
                const previousBomNode = BomService.getPreviousBomNode(bomNode, lineItems)
                onMove(item.lineItemId, bomNode?.parent?.item.lineItemId, previousBomNode?.item.lineItemId)
            } else if (hoverPosition === "bottom") {
                const bomNode = BomService.getBomNode(lineItem.lineItemId, lineItems)
                onMove(item.lineItemId, bomNode?.parent?.item.lineItemId, lineItem.lineItemId)
            } else if (hoverPosition === "center") {
                onMove(item.lineItemId, lineItem.lineItemId)
            }
        },
        [lineItems, onMove, lineItem, getHoverPosition]
    )

    const dropCollect = useCallback(
        (monitor: DropTargetMonitor) => {
            const rect = ref.current?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return {
                hover: rect && clientOffset && canDrop(monitor.getItem(), monitor) ? getDragAndDropHoverPosition(clientOffset, rect) : undefined,
                dropNotAllowed: !!monitor.getItem() && !canDrop(monitor.getItem(), monitor)
            }
        },
        [ref, canDrop]
    )

    const [dragProps, dragRef] = useDrag(
        () => ({
            type: DRAG_AND_DROP_ITEM_TYPE,
            item: { lineItemId: lineItem.lineItemId },
            collect: monitor => ({ difference: monitor.getDifferenceFromInitialOffset(), isDragging: monitor.isDragging() }),
            canDrag: canDrag
        }),
        [lineItem, canDrag]
    )

    const [dropProps, dropRef] = useDrop(
        () => ({
            accept: DRAG_AND_DROP_ITEM_TYPE,
            collect: dropCollect,
            drop: drop,
            canDrop: canDrop
        }),
        [ref, drop, lineItems, onToggle]
    )

    useEffect(() => {
        dragRef(ref.current)
        dropRef(ref.current)
    }, [ref, dragRef, dropRef])

    return gridRowProps.rowId === FOOTER_ROW_ID ? (
        <GridRow {...gridRowProps} ref={ref} style={RowStyles(dropProps.hover, dragProps.isDragging)} />
    ) : (
        <>
            <div style={{ position: "relative" }}>
                <div style={OverlayStyles(dropProps.dropNotAllowed)} />
                <GridRow {...gridRowProps} ref={ref} style={RowStyles(dropProps.hover, dragProps.isDragging)} />
            </div>
            {dragProps.isDragging && <GridRow {...gridRowProps} style={RowPreviewStyles(gridRowProps.index, ref.current, dragProps.difference)} />}
        </>
    )
}
