import { Box, Card, Stack, Typography } from "@mui/material"
import { Product } from "@encoway/c-services-js-client"
import NewsCardStyles from "./NewsCard.styles"
import TimeAgo from "javascript-time-ago"
import { Settings } from "../../../../../settings"
import Logo from "../../../../../assets/logo.svg"
import Image from "../../../../../components/image/Image"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { CharacteristicIds } from "../../../../../features/catalog/catalog.constants"

export interface RecentProjectsStackItemProps {
    product: Product
}

export default function NewsCard({ product }: RecentProjectsStackItemProps) {
    const timeAgo = new TimeAgo(Settings.locale)
    const date = CatalogUtils.getCharacteristicValue<string>(product, CharacteristicIds.Date)
    const text = CatalogUtils.getCharacteristicValue<string>(product, CharacteristicIds.LongText)
    return (
        <Card component={Stack} spacing={1} sx={NewsCardStyles.card}>
            <Image
                src={CatalogUtils.getMediaUri(product, CharacteristicIds.ProductImage)}
                style={{ width: "100%" }}
                fallbackImageProps={{ src: Logo, alt: "image of " + product.name, sx: NewsCardStyles.image }}
            />
            <Stack>
                <Typography sx={NewsCardStyles.title}>{product.name}</Typography>
                {date ? <Typography sx={NewsCardStyles.timeAgo}>{timeAgo.format(new Date(date))}</Typography> : null}
            </Stack>
            {text ? <Box dangerouslySetInnerHTML={{ __html: text }} /> : null}
        </Card>
    )
}
