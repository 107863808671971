import { ICalloutProps } from "@fluentui/react/lib/Callout"
import { IDropdownProps as IFluentDropdownProps } from "@fluentui/react/lib/Dropdown"
import { ISearchBox } from "@fluentui/react/lib/SearchBox"
import { IProcessedStyleSet } from "@fluentui/react/lib/Styling"
import { classNamesFunction, getId, IRenderFunction, styled } from "@fluentui/react/lib/Utilities"
import { merge } from "lodash"
import React, { FocusEvent, ForwardedRef, KeyboardEvent, MouseEvent, MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { L10n } from "@encoway/l10n"
import { DetailDropdownStyles, Dropdown, VIEWPORT_PROPERTY_VALUE } from "@encoway/cui-configurator-components"
import { IDropdownWithState, onRenderItem } from "@encoway/cui-configurator-components/src/components/Dropdown/Dropdown.types"
import {
    FilterDetailDropdownProps,
    IDetailDropdownOption,
    IFilterDetailDropdownStyles,
    ISearchBoxState,
    SearchInfo,
    ViewportProperties
} from "./FilterDetailDropdown.types"
import FilterDetailDropdownStyles from "./FilterDetailDropdown.styles"
import { createDropdownOption, determineMaxOptionsCount, filterOptions, getOptions, searchRegExp } from "./FilterDetailDropdown.utils"
import FilterDetailDropdownOption from "./Option/FilterDetailDropdownOption"
import FilterDetailDropdownTitle from "./Title/FilterDetailDropdownTitle"
import FilterDetailDropdownWrapper from "./DropdownWrapper/FilterDetailDropdownWrapper"

const ID_PREFIX = "encoway-search-dropdown-wrapper"

/**
 * Renders a FilterDetailDropdown for the possible values of a ParameterTO.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/FilterDropdown/FilterDropdown.tsx)
 * - [IFilterDropdownStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/FilterDropdown/FilterDropdown.styles.ts)
 * - [IFilterDropdownProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/FilterDropdown/FilterDropdown.types.ts)
 * - [MS Fluent Dropdown](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dropdown)
 *
 * @visibleName FilterDetailDropdown
 */
function IFilterDetailDropdown(props: FilterDetailDropdownProps, dropdownForwardRef: ForwardedRef<IDropdownWithState>) {
    const { styles, theme, imageResolution, data, onRenderInputComponent, calloutProps, focusProps, ...delegatedProps } = props
    const viewPortProperties = props.data.viewPortProperties as ViewportProperties
    const limitOptions = viewPortProperties?.FILTER_DROPDOWN_LIMITED === VIEWPORT_PROPERTY_VALUE.True
    const [showOptionsMaxCount] = useState(determineMaxOptionsCount(data.values?.length || 0, limitOptions, viewPortProperties?.FILTER_DROPDOWN_MAX_OPTIONS))

    const [dropdownData, setDropdownData] = useState({ ...data })
    const [invisibleOptions, setInvisibleOptions] = useState<string[]>([])
    const [lastSearchValueSearch, setLastSearchValueSearch] = useState<SearchInfo>({} as SearchInfo)
    const [maxOptionsInfoIsVisible, setMaxOptionsInfoIsVisible] = useState(false)
    const [countFilteredOptions, setCountFilteredOptions] = useState(data.values?.length || 0)
    const [searchBoxWrapperId] = useState(getId(ID_PREFIX))

    const [searchBoxState, setSearchBoxState] = useState<ISearchBoxState>({
        className: FilterDetailDropdownStyles.searchBoxDefault,
        hasFocus: false
    })
    // (Sometimes) required to trigger a re-filtering when the selection is changed
    const currentSelection =
        data.values
            ?.filter(v => v.selected)
            .map(v => v.value)
            .join() || ""
    const classNames = classNamesFunction()(styles, theme) as IProcessedStyleSet<IFilterDetailDropdownStyles>

    // refs
    const dropdownRef = useRef<IDropdownWithState>(null) || (dropdownForwardRef as MutableRefObject<IDropdownWithState>)
    const searchBoxRef = useRef<ISearchBox>(null)

    const maxOptionsInfo = useMemo(() => {
        if (maxOptionsInfoIsVisible) {
            return L10n.format("Configuration.FilterDetailDropdown.OptionsInfo", {
                showMaxOptions: showOptionsMaxCount,
                countOptions: countFilteredOptions
            }).toUpperCase()
        }
        return null
    }, [maxOptionsInfoIsVisible, showOptionsMaxCount, countFilteredOptions])

    const toggleSearchBox = () => {
        const isDropdownOpen: boolean = dropdownRef.current?.state?.isOpen
        setSearchBoxState({
            className: isDropdownOpen ? FilterDetailDropdownStyles.searchBoxVisible : FilterDetailDropdownStyles.searchBoxDefault,
            hasFocus: isDropdownOpen
        })
    }
    /**
     * When the value list changes (e.g. via the configuration) or
     * when the filter/search-string changes,
     * we need to update the value list of the underlying dropdown,
     * which is restricted by the filter/search-string entered by the user.
     */
    useEffect(() => {
        const newData = merge({}, data) // deep-copy
        const filteredValues = filterOptions(data.values, lastSearchValueSearch.test)
        // what we wanna see (cut of filtered values with options max count)
        const visibleValues = limitOptions ? filteredValues.slice(0, showOptionsMaxCount) : filteredValues
        // what we additionally need in the background
        // - all selected values NOT in visible values
        const invisibleValues = data.values?.filter(v => v.selected && visibleValues.indexOf(v) < 0) || []

        // for the UI
        setCountFilteredOptions(filteredValues.length)

        newData.values = [...invisibleValues, ...visibleValues]

        // remember the ones that should be hidden
        setInvisibleOptions(invisibleValues.map(v => v.value))
        setMaxOptionsInfoIsVisible(filteredValues.length > showOptionsMaxCount)
        setDropdownData(newData)
    }, [data, limitOptions, showOptionsMaxCount, lastSearchValueSearch, currentSelection])

    useEffect(() => {
        if (dropdownRef.current && searchBoxState.hasFocus) {
            // Without setTimeout the SearchBox looses focus after being opened, and it starts to flicker.
            setTimeout(() => {
                searchBoxRef.current?.focus()
            }, 50)
        }
    }, [dropdownRef, searchBoxState])

    const onSearching = (_event?: React.ChangeEvent<HTMLInputElement>, searchValue?: string) => {
        if (lastSearchValueSearch.value !== searchValue) {
            setLastSearchValueSearch({
                value: searchValue,
                test: searchRegExp(searchValue)
            })
        }
    }

    const dropdownWrapperOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if ((e.code === "ArrowDown" || e.code === "ArrowUp") && dropdownRef.current) {
            setSearchBoxState({
                className: searchBoxState.className,
                hasFocus: false
            })
            dropdownRef.current.focus()
            e.preventDefault()
        }
    }

    // Needed a wrapper for the SearchBox and FluentDropdown, to display the combined components like before.
    const renderDropdown: IRenderFunction<IFluentDropdownProps> = (dropdownProps, defaultRender) => (
        <FilterDetailDropdownWrapper
            searchBoxWrapperId={searchBoxWrapperId}
            searchBoxState={searchBoxState}
            searchBoxRef={searchBoxRef}
            onSearching={onSearching}
            onKeyUp={dropdownWrapperOnKeyUp}
            dropdownProps={dropdownProps}
            defaultRender={defaultRender}
            classNames={classNames}
        />
    )

    const renderInputComponent: IRenderFunction<IFluentDropdownProps> = (ddProps, defaultRender) => {
        if (onRenderInputComponent) {
            return onRenderInputComponent(ddProps, p => renderDropdown(p, defaultRender))
        }
        return renderDropdown(ddProps, defaultRender)
    }

    const propsPreventDismissOnEvent: (ev: Event | FocusEvent | KeyboardEvent | MouseEvent) => boolean = e =>
        Boolean(calloutProps?.preventDismissOnEvent && calloutProps.preventDismissOnEvent(e))

    const myCalloutProps: ICalloutProps = {
        // Don't close the FilterDetailDropdown options list when searchable and the SearchBox has focus.
        preventDismissOnEvent: event =>
            (typeof (event.target as HTMLDivElement)?.closest === "function"
                ? (event.target as HTMLDivElement).closest(`#${searchBoxWrapperId}`) !== null
                : false) || propsPreventDismissOnEvent(event),
        // When the FilterDetailDropdown option list opens or close, then show / hide the SearchBox.
        onLayerMounted: calloutProps?.onLayerMounted
            ? () => {
                  toggleSearchBox()
                  calloutProps.onLayerMounted && calloutProps.onLayerMounted()
              }
            : toggleSearchBox,
        onRestoreFocus: calloutProps?.onRestoreFocus
            ? p => {
                  toggleSearchBox()
                  calloutProps.onRestoreFocus && calloutProps.onRestoreFocus(p)
              }
            : toggleSearchBox,
        style: {
            maxHeight: "45vh"
        }
    }

    const showImage = props.data.viewPortProperties?.DETAIL_DROPDOWN_IMAGE === VIEWPORT_PROPERTY_VALUE.True
    const showShortText = props.data.viewPortProperties?.DETAIL_DROPDOWN_SHORT_TEXT === VIEWPORT_PROPERTY_VALUE.True

    const renderOption: onRenderItem = (dropdownOption: IDetailDropdownOption, changeValue, defaultRender) => {
        return (
            <FilterDetailDropdownOption
                showImage={showImage}
                showShortText={showShortText}
                imageResolution={imageResolution}
                mediaLink={props.mediaLink}
                dropdownOption={dropdownOption}
                invisibleOptions={invisibleOptions}
                onRenderItem={props.onRenderItem}
                changeValue={changeValue}
                defaultRender={defaultRender}
                classNames={classNames}
            />
        )
    }

    const renderTitle: IRenderFunction<IDetailDropdownOption> = (selectedDropdownOption, defaultRender) => {
        // Note: This code does not(!) support multiselection
        return (
            <FilterDetailDropdownTitle
                showImage={showImage}
                imageResolution={imageResolution}
                mediaLink={props.mediaLink}
                selectedDropdownOption={selectedDropdownOption}
                defaultRender={defaultRender}
                classNames={classNames}
            />
        )
    }

    return (
        <Dropdown
            data={dropdownData}
            onRenderInputComponent={renderInputComponent}
            focusProps={merge({}, focusProps || {}, {
                forceFocusInsideTrap: false,
                isClickableOutsideFocusTrap: true
            })}
            calloutProps={merge({}, calloutProps || {}, myCalloutProps)}
            styles={styles}
            theme={theme}
            ref={dropdownRef}
            {...delegatedProps}
            onRenderItem={renderOption}
            onRenderTitle={renderTitle}
            onCreateDropdownOption={createDropdownOption}
            onGetOptions={getOptionsDefault => getOptions(getOptionsDefault, maxOptionsInfo)}
        />
    )
}

const IFilterDetailDropdownWithForwardRef = React.forwardRef((props: FilterDetailDropdownProps, dropdownForwardRef: ForwardedRef<IDropdownWithState>) =>
    IFilterDetailDropdown(props, dropdownForwardRef)
)

IFilterDetailDropdownWithForwardRef.displayName = "FilterDetailDropdown"

export const FilterDetailDropdown = styled(IFilterDetailDropdownWithForwardRef, DetailDropdownStyles)
