import { InputFieldProps } from "@encoway/cui-configurator-components"
import { Box } from "@mui/material"
import Row from "./Row"
import { TableStyles } from "./Table.styles"
import { useAppSelector } from "../../../../../../store/store"
import ColumnHeader from "./ColumnHeader"
import TableUtils from "./utils/TableUtils"

export default function Table(props: InputFieldProps) {
    const configurationContainer = useAppSelector(state => state.sales.configurationContainer)!
    const table = TableUtils.getTable(props, configurationContainer)
    return (
        <Box component="div" sx={TableStyles.box}>
            <Box component="table" sx={TableStyles.table}>
                <thead>
                    <tr>
                        <Box key="head" component="th" sx={TableStyles.thSticky} />
                        {table.columns.map(column => (
                            <ColumnHeader key={column.id} column={column} widgetProps={props} />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {table.rows.map(row => (
                        <Row key={row.id} row={row} widgetProps={props} />
                    ))}
                </tbody>
            </Box>
        </Box>
    )
}
