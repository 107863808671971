import { Avatar, Box, IconButton } from "@mui/material"
import { L10n } from "@encoway/l10n"
import UserAvatarStyles from "./UserAvatar.styles"
import useDialog from "../../../../../components/dialog/useDialog"
import Dialog from "../../../../../components/dialog/Dialog"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { useDecodedToken } from "../../../../../features/oidc/hooks/useDecodedToken"
import { useLogout } from "../../../../../features/oidc/hooks/useLogout"

export default function UserAvatar() {
    const dialog = useDialog()
    const logout = useLogout()
    const decodedToken = useDecodedToken()
    const name = decodedToken ? decodedToken.name : ""
    const initials = name
        ? name
              .split(" ")
              .map(n => n[0])
              .join("")
        : ""
    return (
        <>
            <Box component="div" sx={UserAvatarStyles.wrapper}>
                <IconButton size="small" component="label" onClick={dialog.open}>
                    <Avatar alt={name} sx={UserAvatarStyles.avatar}>
                        {initials}
                    </Avatar>
                </IconButton>
            </Box>

            <Dialog
                open={dialog.isOpen}
                onClose={dialog.close}
                title={L10n.format(TranslationKeys.components.header.logoutDialog.title)}
                message={L10n.format(TranslationKeys.components.header.logoutDialog.message)}
                defaultDialogActions={{
                    onConfirm: logout,
                    onCancel: dialog.close,
                    confirmButtonLabel: TranslationKeys.components.header.logoutDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.components.header.logoutDialog.cancelButtonLabel
                }}
            />
        </>
    )
}
