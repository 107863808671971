import { unstable_BlockerFunction, unstable_useBlocker, useBeforeUnload } from "react-router-dom"

export type ShouldBlock = boolean | unstable_BlockerFunction

export default function useBlocker(shouldBlock: ShouldBlock, shouldBlockUnload: boolean) {
    useBeforeUnload(event => {
        if (shouldBlockUnload) {
            event.preventDefault()
            event.returnValue = ""
            return event.returnValue
        }
    })

    return unstable_useBlocker(shouldBlock)
}
