import { GenericInput, InputType } from "../../../../components/forms/genericForm/GenericForm"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { Characteristic } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"

export default function getCustomerInformationInputs(industrySegment?: Characteristic, destinationCountry?: Characteristic): GenericInput[] {
    return [
        {
            type: InputType.Dropdown,
            id: SalesDocumentProperties.INDUSTRY_SEGMENT,
            label: industrySegment?.name || "",
            values: industrySegment ? CatalogUtils.getPossibleValues(industrySegment, true).map(pv => ({ value: pv.id, text: pv.value as string })) : []
        },
        {
            type: "number",
            id: SalesDocumentProperties.CUSTOMER_NUMBER,
            label: L10n.format(TranslationKeys.project.properties.customerNumber)
        },
        {
            id: SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
            label: L10n.format(TranslationKeys.project.properties.salesforceOpportunityNumber)
        },
        {
            id: SalesDocumentProperties.CUSTOMER,
            label: L10n.format(TranslationKeys.project.properties.customer)
        },
        {
            id: SalesDocumentProperties.END_CUSTOMER,
            label: L10n.format(TranslationKeys.project.properties.endCustomer)
        },
        {
            type: InputType.Dropdown,
            id: SalesDocumentProperties.DESTINATION_COUNTRY,
            label: L10n.format(TranslationKeys.project.properties.destinationCountry),
            values: destinationCountry ? CatalogUtils.getPossibleValues(destinationCountry, true).map(pv => ({ value: pv.id, text: pv.value as string })) : []
        }
    ]
}
