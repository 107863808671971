import theme from "../../../../../../App.theme"

const ProjectActionsStyles = {
    button: {
        color: theme.palette.text.primary,
        ":hover": {
            backgroundColor: theme.palette.background.default
        }
    }
}

export default ProjectActionsStyles
