import { Stack } from "@mui/material"
import compareStrings from "../../utils/compareStrings"
import RecentProjectsWidget from "./components/recentProjectsWidget/RecentProjectsWidget"
import DocumentsWidget from "./components/documentsWidget/DocumentsWidget"
import HelpWidget from "./components/helpWidget/HelpWidget"
import NewsWidget from "./components/newsWidget/NewsWidget"
import CatalogUtils from "../../features/catalog/catalog.utils"
import CatalogApi from "../../features/catalog/catalog.api"
import { CharacteristicIds, ProductGroupIds } from "../../features/catalog/catalog.constants"

const selection = CatalogUtils.getProductSelection(ProductGroupIds.News)

export default function DashboardPage() {
    const news = (CatalogApi.useProductsQuery(selection).data?.products ?? [])
        .slice()
        .sort((n1, n2) =>
            compareStrings(
                CatalogUtils.getCharacteristicValue(n1, CharacteristicIds.Date),
                CatalogUtils.getCharacteristicValue(n2, CharacteristicIds.Date),
                true
            )
        )
    return (
        <Stack spacing={10} marginTop={8}>
            <RecentProjectsWidget />
            <DocumentsWidget hide />
            <HelpWidget />
            <NewsWidget news={news} />
        </Stack>
    )
}
