import theme from "../../../App.theme"

const BackToLinkStyles = {
    alignItems: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    flexShrink: 0,
    fontSize: theme.typography.body1.fontSize,
    padding: 0,
    textDecoration: "none"
}

export default BackToLinkStyles
