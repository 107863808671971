import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { Settings } from "../../settings"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../translations/TranslationKeys"
import { NumberValue, Product } from "@encoway/c-services-js-client"
import CatalogUtils from "../catalog/catalog.utils"
import { CharacteristicIds } from "../catalog/catalog.constants"
import { VisualizationService } from "@encoway/cui-application-components/dist/cjs/visualizationService"
import { getProductSettings, getVisualization, wrapVisualizationApiQuery } from "./visualization.api.utils"
import { ArResponse, IVisualizationNodeExtended, VisualizationResponse } from "./visualization.api.types"
import { ArDefaultValues } from "./visualization.api.constants"
import { IAppSettings } from "@encoway/cui-application-components"
import { getEnvironment } from "../environment/environment.utils"
import { Visualization } from "@encoway/visual-editor"
import { IVisualizationNode } from "@encoway/cui-application-components/dist/cjs/visualizationService.types"
import { Bus } from "baconjs"
import { merge } from "lodash"

export const visualizationApiTags = {
    VISUALIZATION: "VisualizationInstance"
} as const

const VisualizationApi = createApi({
    reducerPath: "visualizationApi",
    baseQuery: fakeBaseQuery<Error>(),
    tagTypes: Object.values(visualizationApiTags),
    endpoints: builder => ({
        visualization: builder.query<VisualizationResponse | undefined, IAppSettings | undefined>({
            queryFn: async (cuiAppSettings, api) => {
                return wrapVisualizationApiQuery(api, async () => {
                    if (cuiAppSettings) {
                        const productSettings = await getProductSettings(api, cuiAppSettings.configuration.options.articleName)
                        const settings: any = merge({}, cuiAppSettings.visualization, productSettings.settings)

                        const visualization = await Visualization.load(settings.baseUrl, settings.version, settings.token)
                        visualization.cloud.addServer({
                            name: "default",
                            baseUrl: cuiAppSettings.configuration.baseUrl,
                            jsessionid: cuiAppSettings.configuration.jsessionid,
                            token: cuiAppSettings.configuration.token,
                            locale: L10n.currentFullLocale(),
                            credentials: cuiAppSettings.configuration.credentials,
                            options: {
                                configuration: cuiAppSettings.configuration.settings
                            }
                        })

                        const visArguments = {
                            id: cuiAppSettings.configuration.options.articleName,
                            configurationId: cuiAppSettings.configuration.options.configurationId
                        }

                        const node = (await visualization.cloud?.mount("", "product", "article", visArguments, "default", true)) as IVisualizationNodeExtended

                        node.configuration!.disposeHeartbeat()

                        //flag to indicate that new updates are available
                        let rerunUpdate = false
                        //store active update
                        let synchronizedUpdate: Promise<void> | undefined

                        const runUpdate = async () => {
                            do {
                                rerunUpdate = false
                                const evtBus: Bus<any> = visualization.cloud.graph().eventBus()
                                const updating = evtBus
                                    .debounce(1000)
                                    .filter((e: any) => !e.pending)
                                    .firstToPromise()
                                visualization?.cloud
                                    .graph()
                                    .nodes()
                                    .forEach((n: IVisualizationNode) => n.update())
                                await updating
                                //update the visualization as long as new updates are triggred
                            } while (rerunUpdate)
                        }

                        const update = async () => {
                            if (synchronizedUpdate) {
                                //prevent a concurrent update. instead trigger a fresh update after the active update
                                rerunUpdate = true
                                await synchronizedUpdate
                            } else {
                                //Start a new update if no update is running
                                synchronizedUpdate = runUpdate()
                                await synchronizedUpdate
                                synchronizedUpdate = undefined
                            }
                        }

                        return {
                            visualization,
                            node,
                            settings: settings,
                            update
                        }
                    }
                })
            },
            providesTags: [visualizationApiTags.VISUALIZATION]
        }),

        ar: builder.query<ArResponse, { product: Product | undefined; id: string | undefined; cuiAppSettings: IAppSettings }>({
            queryFn: async ({ product, id, cuiAppSettings }, api) => {
                return await wrapVisualizationApiQuery(
                    api,
                    async () => {
                        const visualizationResponse = await getVisualization(api, cuiAppSettings)
                        const environment = await getEnvironment(api)
                        const scene = visualizationResponse.visualization.cloud.graph().scene()
                        const exporter = await visualizationResponse.visualization.useExtension(
                            "3d-exporter",
                            Settings.visualization.version,
                            environment.visualizationToken
                        )
                        // @ts-ignore
                        const result = await exporter.exportScene("glb", scene, { compress: true })
                        const glb = result.content

                        const response = await fetch(environment.arServiceHost + "/upload", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: `Bearer ${environment.visualizationToken}`
                            },
                            body: JSON.stringify({
                                id: id,
                                placement:
                                    (product && CatalogUtils.getCharacteristicValue(product, CharacteristicIds.ArPlacement)) ?? ArDefaultValues.PLACEMENT,
                                scaleFactor:
                                    (product && CatalogUtils.getCharacteristicValue<NumberValue>(product, CharacteristicIds.ArScaleFactor)?.value) ??
                                    ArDefaultValues.SCALE_FACTOR,
                                retentionTime:
                                    (product && CatalogUtils.getCharacteristicValue<NumberValue>(product, CharacteristicIds.RetentionTime)?.value) ??
                                    environment.defaultArRetentionTime,
                                glb: Buffer.from(glb).toString("base64")
                            }),
                            withCredentials: true,
                            credentials: "include"
                        } as RequestInit)

                        if (response.ok) {
                            return await response.json()
                        } else {
                            throw new Error(L10n.format(TranslationKeys.pages.configuration.visualization.ar.errorMessage))
                        }
                    },
                    L10n.format(TranslationKeys.busy.visualization.ar)
                )
            }
        }),

        renderings: builder.query<Record<string, string> | undefined, IAppSettings>({
            queryFn: async (cuiAppSettings, api) => {
                return await wrapVisualizationApiQuery(
                    api,
                    async () => {
                        const visualizationResponse = await getVisualization(api, cuiAppSettings)
                        const visualization = visualizationResponse.visualization
                        const renderSettings = await VisualizationService.determineRenderSettings(
                            visualization,
                            cuiAppSettings.configuration.options.articleName,
                            cuiAppSettings.visualization
                        )
                        return await VisualizationService.render(visualization, renderSettings)
                    },
                    L10n.format(TranslationKeys.busy.visualization.image)
                )
            }
        })
    })
})

export default VisualizationApi
