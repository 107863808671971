import { AbbLineItem, BomNode } from "../sales.types"

export default class BomService {
    static mapLineItem(lineItem: AbbLineItem, parent?: BomNode): BomNode {
        return {
            item: lineItem,
            level: parent ? parent.level + 1 : 0,
            expanded: false,
            parent: parent
        }
    }

    static mapLineItems(lineItems?: AbbLineItem[], parent?: BomNode): BomNode[] | undefined {
        return lineItems?.map(lineItem => this.mapLineItem(lineItem, parent))
    }

    static getAllChildrenLineItemIds(parent: BomNode): string[] {
        const ids = parent.children?.map(child => child.item.lineItemId) ?? []
        const reqrusiveids = parent.children?.reduce((result: string[], child) => result.concat(this.getAllChildrenLineItemIds(child)), []) ?? []
        return ids.concat(reqrusiveids)
    }

    static flattenBomTree(bomNodes: BomNode[]): BomNode[] {
        return bomNodes.reduce((flattenedNodes: BomNode[], bomNode: BomNode) => {
            return flattenedNodes.concat(bomNode, bomNode.expanded ? this.flattenBomTree(bomNode.children || []) : [])
        }, [])
    }

    static getExpandedLineItems(bomNodes: BomNode[]): string[] {
        return BomService.flattenBomTree(bomNodes)
            .filter(node => node.expanded)
            .map(node => node.item.lineItemId)
    }

    static createBomTree(rootLineItems: AbbLineItem[], lineItems: { parentLineItemId: string; children: AbbLineItem[] }[], parent?: BomNode): BomNode[] {
        const bomNodes = this.mapLineItems(rootLineItems, parent)!
        return bomNodes.map(bomNode => {
            const children = lineItems.filter(lineItem => lineItem.parentLineItemId === bomNode.item.lineItemId).at(0)?.children
            bomNode.expanded = !!children
            bomNode.children = children ? this.createBomTree(children, lineItems, bomNode) : undefined
            return bomNode
        })
    }

    static toggleBomNode(bomNode: BomNode, bomNodes?: BomNode[], children?: AbbLineItem[]): BomNode[] | undefined {
        return bomNodes?.reduce((result: BomNode[], currentNode: BomNode) => {
            if (bomNode.item.lineItemId === currentNode.item.lineItemId) {
                const updatedBomNode = { ...currentNode, expanded: !currentNode.expanded }
                updatedBomNode.children = this.mapLineItems(children, updatedBomNode) ?? updatedBomNode.children
                return result.concat(updatedBomNode)
            } else {
                return result.concat({
                    ...currentNode,
                    children: this.toggleBomNode(bomNode, currentNode.children, children)
                })
            }
        }, [])
    }

    static getBomNode(lineItemId: string, bomNodes: BomNode[]): BomNode | undefined {
        for (const bomNode of bomNodes) {
            if (bomNode.item.lineItemId === lineItemId) {
                return bomNode
            } else if (bomNode.children) {
                const result = this.getBomNode(lineItemId, bomNode.children)
                if (result) {
                    return result
                }
            }
        }
    }

    static getPreviousBomNode(bomNode: BomNode, bomNodes: BomNode[]): BomNode | undefined {
        const parentChildren = bomNode.parent?.children ?? bomNodes.filter(node => !node.parent)
        const index = parentChildren.findIndex(node => node.item.lineItemId === bomNode.item.lineItemId)
        return index > 0 ? parentChildren.at(index - 1) : undefined
    }
}
