import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Settings } from "../../settings"
import prepareHeaders from "../../store/prepareHeaders"
import { Data, Layout } from "plotly.js"

interface GraphParams {
    parameterName: string
    configurationId: string
    dateToPreventCaching: string
}

export interface GraphDefinition {
    data: Data[]
    layout?: Partial<Layout>
}

export const GraphApi = createApi({
    reducerPath: "graphApi",
    baseQuery: fetchBaseQuery({
        baseUrl: Settings.configuration.baseUrl,
        prepareHeaders: prepareHeaders
    }),
    endpoints: builder => ({
        graph: builder.query<GraphDefinition, GraphParams>({
            query: params => ({
                url: `/api/features/configuration/${params.configurationId}/graph/${params.parameterName}`,
                responseHandler: "text"
            }),
            transformResponse: (response: string) => JSON.parse(response)
        })
    })
})

export const { useGraphQuery } = GraphApi
