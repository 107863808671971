import { Outlet } from "react-router-dom"
import AppStyles from "../../App.styles"
import Header from "./components/header/Header"
import Container from "@mui/material/Container"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { Stack } from "@mui/material"
import Progress from "./components/progress/Progress"
import SnackbarStack from "./components/snackbarStack/SnackbarStack"
import SnackbarSlice from "../../features/snackbar/snackbar.slice"

export default function RootPage() {
    const progress = useAppSelector(state => state.progress)
    const snackbars = useAppSelector(state => state.snackbar.snackbars)
    const dispatch = useAppDispatch()

    return (
        <Stack sx={AppStyles.wrapper}>
            <Header />
            <SnackbarStack snackbars={snackbars} onClose={id => dispatch(SnackbarSlice.actions.close(id))} />
            {progress.counter !== progress.total ? <Progress {...progress} /> : null}
            <Container component="main" maxWidth="xl" sx={AppStyles.content}>
                <Outlet />
            </Container>
        </Stack>
    )
}
