import useDialog from "../../../../../components/dialog/useDialog"
import Dialog from "../../../../../components/dialog/Dialog"
import GenericForm from "../../../../../components/forms/genericForm/GenericForm"
import { useState } from "react"
import useGenericForm from "../../../../../components/forms/genericForm/useGenericForm"
import useNewArticleFormInputs from "./useNewArticleFormInputs"
import useLineItems from "../../../../../features/sales/hooks/useLineItems"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"

import { AbbLineItemProperties } from "../../../../../features/sales/sales.types"

export default function NewArticleDialog(props: ReturnType<typeof useDialog> & ReturnType<typeof useLineItems>) {
    const [inputValues, setInputValues] = useState<Partial<AbbLineItemProperties>>({})

    const updateInputValues = (property: keyof Partial<AbbLineItemProperties>, value: any) => {
        setInputValues(prevState => ({ ...prevState, [property]: value }))
    }

    const submit = async () => {
        await props.addCustomLineItem({ ...inputValues, name: inputValues.label })
        props.close()
        setInputValues({})
    }

    const close = () => {
        props.close()
        setInputValues({})
    }

    const inputs = useNewArticleFormInputs()
    const form = useGenericForm(inputs, inputValues, updateInputValues)

    return (
        <Dialog
            withDividers
            title={L10n.format(TranslationKeys.lineItem.newArticle.dialog.title)}
            open={props.isOpen}
            onClose={close}
            defaultDialogActions={{
                onConfirm: submit,
                disableConfirm: !form.isValid,
                onCancel: close,
                cancelButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.cancelButtonLabel),
                confirmButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.submitButtonLabel)
            }}
        >
            <GenericForm {...form.formProps} columns={1} spacing={2} />
        </Dialog>
    )
}
