import { AppBar, Container, Toolbar } from "@mui/material"
import AppTitleWithLogo from "./appTitleWithLogo/AppTitleWithLogo"
import MobileMenu from "./menus/mobileMenu/MobileMenu"
import MainMenu from "./menus/mainMenu/MainMenu"
import HeaderStyles from "./Header.styles"
import Language from "./language/Language"
import UserAvatar from "./userAvatar/UserAvatar"
import HomeIcon from "@mui/icons-material/Home"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import User from "./user/User"
import RouterPaths from "../../../../router/constants/RouterPaths"

export default function Header() {
    const pages = [
        { label: L10n.format(TranslationKeys.pages.dashboard.pageName), route: RouterPaths.DASHBOARD, icon: <HomeIcon /> },
        { label: L10n.format(TranslationKeys.pages.projects.pageName), route: RouterPaths.PROJECTS }
    ]
    return (
        <AppBar position="sticky" color="inherit" sx={HeaderStyles.AppBar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <MobileMenu menuEntries={pages} />
                    <AppTitleWithLogo />
                    <MainMenu menuEntries={pages} />
                    <User />
                    <Language />
                    <UserAvatar />
                </Toolbar>
            </Container>
        </AppBar>
    )
}
