import { GridColDef } from "@mui/x-data-grid"
import { CharacteristicIds } from "../../../../../../../features/catalog/catalog.constants"

export const AbbDimensioningDctColumns: GridColDef[] = [
    { field: CharacteristicIds.DcProductName, width: 200 },
    { field: CharacteristicIds.DcCurrent, width: 200 },
    { field: CharacteristicIds.DcMargin, maxWidth: 150 },
    { field: CharacteristicIds.DcModuleFrameSize, width: 200 },
    { field: CharacteristicIds.DcModuleDimension, width: 200 }
]
