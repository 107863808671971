import { useCallback, useEffect, useState } from "react"
import BomService from "../services/BomService"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"
import SalesApi from "../sales.api"
import { AbbLineItem, AbbSalesDocumentProperties, BomNode } from "../sales.types"
import { LineItemProperties } from "../sales.constants"

export default function useLineItems() {
    const [bomNodes, setBomNodes] = useState<BomNode[]>([])
    const [lineItemToDelete, setLineItemToDelete] = useState<BomNode | undefined>()

    const [triggerGetLineItems] = SalesApi.useLazyLineItemsQuery()
    const [triggerDeleteLineItems] = SalesApi.useDeleteLineItemsMutation()
    const [triggerDuplicateLineItem] = SalesApi.useDuplicateLineItemMutation()
    const [triggerAddFolder] = SalesApi.useAddFolderMutation()
    const [triggerAddCustomLineItem] = SalesApi.useAddCustomLineItemMutation()
    const [triggerUpdateLineItem] = SalesApi.useUpdateLineItemMutation()
    const [triggerMoveLineItem] = SalesApi.useMoveLineItemMutation()

    const getLineItems = useCallback(
        async (lineItemId?: string) => {
            const query = triggerGetLineItems(lineItemId)
            query.unsubscribe()
            return await query.unwrap()
        },
        [triggerGetLineItems]
    )

    const loadLineItems = useCallback(async () => {
        const lineItems = await getLineItems()
        setBomNodes(BomService.mapLineItems(lineItems) ?? [])
    }, [getLineItems])

    useEffect(() => {
        loadLineItems()
    }, [loadLineItems])

    const updateBomNodes = async (deletedLineItems?: string[]) => {
        const expandedLineItems = BomService.getExpandedLineItems(bomNodes).filter(id => !deletedLineItems?.includes(id))
        const promiseResult = await Promise.allSettled(expandedLineItems.map(id => getLineItems(id)))
        const map = promiseResult.reduce((result: { parentLineItemId: string; children: AbbLineItem[] }[], entry, index) => {
            return entry.status !== "fulfilled" ? result : result.concat({ parentLineItemId: expandedLineItems[index], children: entry.value })
        }, [])
        const rootLineItems = await getLineItems()
        setBomNodes(BomService.createBomTree(rootLineItems, map))
    }

    const toggle = async (node: BomNode) => {
        const lineItems = !node.expanded && !node.children ? await getLineItems(node.item.lineItemId) : undefined
        setBomNodes(prevState => BomService.toggleBomNode(node, prevState, lineItems) ?? [])
    }

    const deleteLineItem = async () => {
        setLineItemToDelete(undefined)
        await triggerDeleteLineItems([lineItemToDelete!.item.lineItemId]).unwrap()
        await updateBomNodes([lineItemToDelete!.item.lineItemId, ...BomService.getAllChildrenLineItemIds(lineItemToDelete!)])
    }

    const duplicateLineItem = async (node: BomNode) => {
        const response = await triggerDuplicateLineItem([[node.item.lineItemId], node.parent?.item.lineItemId, node.item.lineItemId]).unwrap()
        Object.values(response.addedChildLineItems).forEach((lineItems: AbbLineItem[]) => {
            lineItems?.forEach(async item => {
                await triggerUpdateLineItem([
                    item.lineItemId,
                    {
                        [LineItemProperties.CONFIGURATION_NAME]: `${item.properties.CONFIGURATION_NAME} ${L10n.format(TranslationKeys.lineItem.copyAppendix)}`
                    }
                ]).unwrap()
            })
        })
        await updateBomNodes()
    }

    const addFolder = async () => {
        await triggerAddFolder([L10n.format(TranslationKeys.pages.project.composition.newFolderName), undefined, "FIRST"]).unwrap()
        await updateBomNodes()
    }

    const addCustomLineItem = async (inputValues: Partial<AbbSalesDocumentProperties>) => {
        await triggerAddCustomLineItem(inputValues).unwrap()
        await updateBomNodes()
    }

    const updateLineItem = async (node: BomNode, property: string, value: any) => {
        setBomNodes(prevState =>
            prevState.map(bomNode =>
                bomNode.item.lineItemId === node.item.lineItemId
                    ? {
                          ...bomNode,
                          item: {
                              ...bomNode.item,
                              properties: {
                                  ...bomNode.item.properties,
                                  [property]: value
                              }
                          }
                      }
                    : bomNode
            )
        )
        await triggerUpdateLineItem([node.item.lineItemId, { [property]: value }]).unwrap()
        await updateBomNodes()
    }

    const moveLineItem = async (selectedLineItemId: string, parentLineItemId?: string, previousLineItemId?: string) => {
        if (selectedLineItemId !== parentLineItemId) {
            await triggerMoveLineItem([[selectedLineItemId], parentLineItemId, previousLineItemId]).unwrap()
            await updateBomNodes()
        }
    }

    return {
        data: BomService.flattenBomTree(bomNodes),
        toggle: toggle,
        duplicate: duplicateLineItem,
        delete: deleteLineItem,
        lineItemToDelete,
        setLineItemToDelete,
        cancelDelete: () => setLineItemToDelete(undefined),
        addFolder,
        addCustomLineItem,
        updateLineItem,
        moveLineItem
    }
}
