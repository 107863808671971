import { ContainerTO } from "@encoway/c-services-js-client"
import { ComponentFactory } from "@encoway/react-configurator"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import { Box } from "@mui/material"
import { TableStyles } from "./Table.styles"

type RowProps = {
    row: ContainerTO
    widgetProps: InputFieldProps
}

const Row = ({ row, widgetProps }: RowProps) => {
    return (
        <tr>
            <Box key={row.id} component="th" sx={TableStyles.thrSticky}>
                {row.translatedName}
            </Box>
            {row.children.map(column =>
                column.parameters.map(parameter => (
                    <Box key={column.id} component="td" sx={TableStyles.td}>
                        {ComponentFactory.instanceOf(parameter.viewPort!, { ...widgetProps, data: parameter, hideParameter: true })}
                    </Box>
                ))
            )}
        </tr>
    )
}

export default Row
