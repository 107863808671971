import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import AbbSpinner from "../AbbSpinner/AbbSpinner"
import { Dialog, DialogContent, PrimaryButton } from "@fluentui/react"

type Props = {
    close: () => void
    isOpen: boolean
    qr?: string
    error?: string
    isLoading: boolean
}

const AbbArDialog = ({ isOpen, close, qr, error, isLoading }: Props) => (
    <Dialog
        dialogContentProps={{
            title: L10n.format(TranslationKeys.pages.configuration.visualization.ar.dialogTitle),
            showCloseButton: true,
            subText: L10n.format(TranslationKeys.pages.configuration.visualization.ar.dialogText),
            isMultiline: true,
            styles: {
                subText: {
                    whiteSpace: "pre-wrap"
                }
            }
        }}
        onDismiss={close}
        hidden={!isOpen}
    >
        <DialogContent>
            {isLoading ? (
                <AbbSpinner />
            ) : (
                <div>
                    {qr && <img src={qr} alt={L10n.format(TranslationKeys.pages.configuration.visualization.ar.qrCodeAltText)} />}
                    {!!error && error}
                </div>
            )}
        </DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton onClick={close} text={L10n.format(TranslationKeys.pages.configuration.visualization.ar.dialogCloseButton)} />
        </div>
    </Dialog>
)

export default AbbArDialog
