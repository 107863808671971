import { ILinkedTreeStyles } from "@encoway/cui-configurator-components"
import theme from "../../../../../../App.theme"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const AbbLinkedTreeStyles: ILinkedTreeStyles = {
    resizeBar: {
        backgroundColor: "transparent"
    },
    nav: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: CuiStyleConstants.CONTAINER_BORDER_RADIUS,
        marginRight: 16,
        padding: 8,
        button: {
            backgroundColor: "transparent !important",
            color: theme.palette.text.primary
        },
        ".is-selected": {
            backgroundColor: theme.palette.background.default,
            borderRadius: 4
        }
    }
}

export default AbbLinkedTreeStyles
