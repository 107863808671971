import environmentApi from "../../environment/environment.api"
import { useIdToken } from "@encoway/sales-showroom-auth"
import SalesApi from "../../sales/sales.api"

export const useLogout = () => {
    const environment = environmentApi.usePublicEnvironmentQuery().data!
    const [triggerClose] = SalesApi.useCloseMutation()
    const idToken = useIdToken()
    return async () => {
        try {
            await triggerClose()
        } catch (err) {
            console.warn("failed to end session", err)
        }
        const endSessionUri = environment.endSessionUri
        const idTokenValue = idToken && idToken()
        if (!idTokenValue) {
            console.error("missing id token value")
        } else if (!endSessionUri) {
            console.error("missing end session uri")
        } else {
            const redirectUri = window.location.origin
            window.location.href = endSessionUri + "?id_token_hint=" + idTokenValue + "&post_logout_redirect_uri=" + redirectUri
        }
    }
}
