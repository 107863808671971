import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Visualization } from "@encoway/visual-editor"

export interface VisualizationState {
    visualization?: Visualization
    isVisible: boolean
    isFullscreen: boolean
}

const initialState: VisualizationState = {
    isVisible: false,
    isFullscreen: false
}

const VisualizationSlice = createSlice({
    name: "visualizationSlice",
    initialState,
    reducers: {
        setVisualization: (state, action: PayloadAction<VisualizationState["visualization"]>) => {
            // @ts-ignore
            state.visualization = action.payload
        },
        setVisible: (state, action: PayloadAction<VisualizationState["isVisible"]>) => {
            state.isVisible = action.payload
        },
        setFullscreen: (state, action: PayloadAction<VisualizationState["isFullscreen"]>) => {
            state.isFullscreen = action.payload
        }
    }
})

export default VisualizationSlice
