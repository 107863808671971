import Dialog from "../../../../components/dialog/Dialog"
import { TError } from "../../../../features/error/error.slice"
import TranslationKeys from "../../../../features/translations/TranslationKeys"

type Props = {
    error?: TError
    onClose: () => void
}

const ErrorDialog = (props: Props) => {
    return props.error ? (
        <Dialog
            open={true}
            title={props.error.name}
            onClose={props.onClose}
            message={props.error.message}
            interpretMessageAsDangerouslySetInnerHTML={"activateDangerouslySetInnerHTML" in props.error && props.error.activateDangerouslySetInnerHTML}
            customDialogActions={{
                actions: [
                    {
                        label: TranslationKeys.error.button,
                        onClick: props.onClose,
                        variant: "contained"
                    }
                ]
            }}
        />
    ) : null
}

export default ErrorDialog
