const GenerateDocumentsButtonStyles = {
    button: {
        display: "inline-flex",
        flexShrink: 0
    },
    disabledButton: {
        opacity: 0.5,
        cursor: "context-menu",
        display: "inline-flex"
    },
    popover: {
        "> .MuiPaper-root": {
            minWidth: 240,
            padding: 2
        }
    }
}

export { GenerateDocumentsButtonStyles }
