import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../features/translations/TranslationKeys"
import { GenericInput, InputType } from "../../forms/genericForm/GenericForm"
import CatalogUtils from "../../../features/catalog/catalog.utils"
import CatalogApi from "../../../features/catalog/catalog.api"
import { CharacteristicIds } from "../../../features/catalog/catalog.constants"
import { SalesDocumentProperties } from "../../../features/sales/sales.constants"

export default function useNewProjectFormInputs(skip: boolean): GenericInput[] {
    const industrySegment = CatalogApi.useCharacteristicQuery(CharacteristicIds.IndustrySegment, { skip }).data
    const currencyCode = CatalogApi.useCharacteristicQuery(CharacteristicIds.ProjectCurrency, { skip }).data
    return [
        {
            id: SalesDocumentProperties.PROJECT_NAME,
            label: L10n.format(TranslationKeys.project.properties.projectName),
            mandatory: true,
            maxLength: 50
        },
        {
            type: InputType.Dropdown,
            id: SalesDocumentProperties.INDUSTRY_SEGMENT,
            label: industrySegment?.name || "",
            values: industrySegment
                ? CatalogUtils.getPossibleValues(industrySegment, true).map(pv => ({
                      value: pv.id,
                      text: pv.value as string
                  }))
                : []
        },
        {
            type: InputType.Dropdown,
            id: SalesDocumentProperties.QUOTE_CURRENCY,
            label: currencyCode?.name || "",
            values: currencyCode
                ? CatalogUtils.getPossibleValues(currencyCode, true).map(pv => ({
                      value: pv.id,
                      text: pv.value as string
                  }))
                : [],
            mandatory: true
        },
        {
            id: SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
            label: L10n.format(TranslationKeys.project.properties.salesforceOpportunityNumber),
            maxLength: 20
        }
    ]
}
