import DashboardWidget from "../dashboardWidget/DashboardWidget"
import { Button, Card, Grid, Stack, Typography } from "@mui/material"
import ProjectCard from "./projectCard/ProjectCard"
import AddIcon from "@mui/icons-material/Add"
import { L10n } from "@encoway/l10n"
import RecentProjectsWidgetStyles from "./RecentProjectsWidget.styles"
import useDialog from "../../../../components/dialog/useDialog"
import useNavigate from "../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import NewProjectDialog from "../../../../components/project/newProjectDialog/NewProjectDialog"
import LockedProjectDialog from "../../../../components/project/lockedProjectDialog/LockedProjectDialog"
import { useDecodedToken } from "../../../../features/oidc/hooks/useDecodedToken"
import SalesApi from "../../../../features/sales/sales.api"
import { Constants, Paging, SalesDocumentFilter } from "@encoway/sales-api-js-client"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import useOpenSalesDocumentHandler from "../../../../features/sales/hooks/useOpenSalesDocumentHandler"

export default function RecentProjectsWidget() {
    const dialog = useDialog()
    const navigate = useNavigate()
    const decodedToken = useDecodedToken()
    const filterConfig = decodedToken?.username
        ? new SalesDocumentFilter().add(SalesDocumentProperties.CREATED_BY, Constants.Operator.Equal, decodedToken.username, Constants.Type.String)
        : undefined
    const pagingLoadConfig = new Paging().limit(4).sortField(SalesDocumentProperties.MODIFIED_AT).descending()
    const salesDocuments = SalesApi.useSalesDocumentsQuery({ filterConfig, pagingLoadConfig })
    const openSalesDocumentHandler = useOpenSalesDocumentHandler()
    return (
        <DashboardWidget title={L10n.format(TranslationKeys.pages.dashboard.recentProjectsWidget.title)}>
            <Stack spacing={4}>
                <Grid container spacing={4}>
                    {salesDocuments.data?.data.map(salesDocument => (
                        <Grid item key={salesDocument.salesDocumentId}>
                            <ProjectCard
                                key={salesDocument.salesDocumentId}
                                project={salesDocument}
                                onOpenProject={openSalesDocumentHandler.open}
                                sx={RecentProjectsWidgetStyles.card}
                            />
                        </Grid>
                    ))}
                    <Grid item>
                        <Card sx={RecentProjectsWidgetStyles.viewAllCard} onClick={() => navigate.toProjectsOverview()}>
                            <Typography data-cy={"Typography.ViewAllProjects"}>
                                {L10n.format(TranslationKeys.pages.dashboard.recentProjectsWidget.viewAllProjects)}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
                <Stack direction="row">
                    <Button
                        variant="contained"
                        startIcon={<AddIcon sx={RecentProjectsWidgetStyles.buttonIcon} />}
                        onClick={dialog.open}
                        data-cy={"newProjectButton"}
                        sx={RecentProjectsWidgetStyles.button}
                    >
                        {L10n.format(TranslationKeys.project.newProject.button.label)}
                    </Button>
                </Stack>
            </Stack>

            <NewProjectDialog {...dialog} />

            <LockedProjectDialog
                open={!!openSalesDocumentHandler.lockedSalesDocument}
                projectCanBeOpened={openSalesDocumentHandler.lockedSalesDocumentCanBeOpened}
                lockedBy={openSalesDocumentHandler.lockedSalesDocument?.properties.quote_locked_by}
                onOpenLockedProject={openSalesDocumentHandler.openLockedSalesDocument}
                onCancelOpen={openSalesDocumentHandler.cancel}
            />
        </DashboardWidget>
    )
}
