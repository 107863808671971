import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { Characteristic, Paging, PriceList, SalesDocumentFilter, SalesService, UpdateServerResponse } from "@encoway/sales-api-js-client"
import { addProduct, wrapSalesApiQuery } from "./sales.api.utils"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../translations/TranslationKeys"
import SnackbarSlice from "../snackbar/snackbar.slice"
import ProgressSlice from "../progress/progress.slice"
import { AbbLineItem, AbbLineItemProperties, AbbSalesDocumentEntity, AbbSalesDocumentProperties } from "./sales.types"
import { SalesDocumentProperties } from "./sales.constants"
import {
    AddFolderArgs,
    DuplicateLineItemArgs,
    GetSalesDocumentsArgs,
    GetSalesDocumentsResponse,
    MoveLineItemArgs,
    PrintArgs,
    PrintResponse,
    UpdateLineItemArgs,
    UpdateSalesDocumentArgs
} from "./sales.api.types"

export const SalesApiTags = {
    SALES_DOCUMENTS: "salesDocuments",
    SALES_DOCUMENT: "salesDocument",
    LINE_ITEM: "lineItem",
    LINE_ITEMS: "lineItems"
} as const

const SalesApi = createApi({
    reducerPath: "salesApi",
    tagTypes: Object.values(SalesApiTags),
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        // Queries:
        salesDocuments: builder.query<GetSalesDocumentsResponse, GetSalesDocumentsArgs>({
            providesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: (args, api) => wrapSalesApiQuery(api, salesService => salesService.custom.call("/search/projects", args))
        }),

        salesDocument: builder.query<AbbSalesDocumentEntity | undefined, void>({
            providesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: async (_args, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    const salesDocumentId = (api.getState() as any).sales.salesDocumentId
                    if (salesDocumentId) {
                        const salesDocument = await salesService.salesDocument.get()
                        salesDocument.salesDocumentId = salesDocumentId // necessary because salesDocumentIds from getProjects are different
                        return salesDocument
                    }
                })
            }
        }),

        lineItems: builder.query<AbbLineItem[], string | void>({
            providesTags: [SalesApiTags.LINE_ITEMS],
            queryFn: (lineItemId, api) => wrapSalesApiQuery(api, salesService => salesService.lineItems.get(lineItemId ?? undefined))
        }),

        lineItem: builder.query<AbbLineItem, string>({
            providesTags: [SalesApiTags.LINE_ITEM],
            queryFn: (lineItemId, api) => wrapSalesApiQuery(api, salesService => salesService.custom.call("/lineItem/get", { lineItemId }))
        }),

        priceLists: builder.query<PriceList[], void>({
            queryFn: (_arg, api) => wrapSalesApiQuery(api, salesService => salesService.masterData.priceLists())
        }),

        filterCharacteristics: builder.query<Characteristic[], string>({
            queryFn: (productGroupId, api) => wrapSalesApiQuery(api, salesService => salesService.masterData.filterCharacteristics(productGroupId))
        }),

        // Mutations:

        createSalesDocument: builder.mutation<AbbSalesDocumentEntity, Partial<AbbSalesDocumentProperties>>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: async (properties, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    const salesDocument = await salesService.salesDocuments.create("quote")
                    await salesService.salesDocument.update(properties, {})
                    await salesService.salesDocument.save()

                    const paging = new Paging().limit(1).sortField(SalesDocumentProperties.CREATED_AT).descending()
                    const filter = new SalesDocumentFilter().add(SalesDocumentProperties.CREATED_BY, "=", salesDocument.properties.quote_created_by, "string")

                    // Needed because salesService.salesDocument functions return salesDocument with wrong salesDocumentId
                    return (await salesService.custom.call("/search/projects", { pagingLoadConfig: paging, filterConfig: filter })).data.at(0)
                })
            }
        }),

        openSalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: async (salesDocumentId, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    await salesService.custom.call("/salesdocuments/getbyids", [salesDocumentId]) // necessary because sales document must be loaded before opening it
                    await salesService.salesDocuments.open(salesDocumentId, true)
                })
            }
        }),

        closeSalesDocument: builder.mutation<void, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: (_args, api) => wrapSalesApiQuery(api, salesService => salesService.salesDocuments.create("quote"))
        }),

        close: builder.mutation<string, void>({
            queryFn: (_args, api) => wrapSalesApiQuery(api, salesService => salesService.close())
        }),

        deleteSalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: (salesDocumentId, api) => wrapSalesApiQuery(api, salesService => salesService.salesDocuments.delete(salesDocumentId))
        }),

        copySalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: async (salesDocumentId, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    await salesService.salesDocuments.copy(salesDocumentId)
                    await salesService.salesDocument.save()
                })
            }
        }),

        updateSalesDocument: builder.mutation<AbbSalesDocumentEntity, UpdateSalesDocumentArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: (args, api) => wrapSalesApiQuery(api, salesService => salesService.salesDocument.update(...args))
        }),

        saveSalesDocument: builder.mutation<AbbSalesDocumentEntity, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: (_args, api) => wrapSalesApiQuery(api, salesService => salesService.salesDocument.save())
        }),

        setSalesDocumentStatus: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS, SalesApiTags.SALES_DOCUMENT],
            queryFn: async (status, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    const state = api.getState() as any
                    const salesDocument = SalesApi.endpoints?.salesDocument.select()(state).data!
                    const currentStatus = salesDocument.properties.quote_status!
                    await salesService.process.execute(currentStatus, status)
                })
            }
        }),

        addFolder: builder.mutation<UpdateServerResponse, AddFolderArgs>({
            invalidatesTags: [SalesApiTags.LINE_ITEMS],
            queryFn: (args, api) => wrapSalesApiQuery(api, salesService => salesService.lineItems.addFolder(...args))
        }),

        addProduct: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS],
            queryFn: async (productId, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    await salesService.lineItems.addProduct(productId)
                    const successMessage = L10n.format(TranslationKeys.pages.project.catalog.productSelection.addToCompositionSuccessMessage)
                    api.dispatch(SnackbarSlice.actions.open({ message: successMessage, severity: "success" }))
                })
            }
        }),

        addProducts: builder.mutation<void, Record<string, number>>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS],
            queryFn: async (productSelection, api) => {
                return await wrapSalesApiQuery(api, async () => {
                    const total = Object.values(productSelection).reduce((total, amount) => total + amount)
                    api.dispatch(ProgressSlice.actions.start({ total, translationKey: TranslationKeys.pages.project.catalog.quickSelection.progress }))
                    const result = await Promise.allSettled(Object.entries(productSelection).map(([productId, amount]) => addProduct(api, productId, amount)))
                    if (result.filter(r => r.status === "rejected").length === 0) {
                        const translationKey = TranslationKeys.pages.project.catalog.quickSelection.addToCompositionSuccessMessage
                        const message = L10n.format(translationKey, { numberOfProductsToAdd: total })
                        api.dispatch(SnackbarSlice.actions.open({ severity: "success", message }))
                    }
                })
            }
        }),

        addCustomLineItem: builder.mutation<UpdateServerResponse, Partial<AbbLineItemProperties>>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS],
            queryFn: async (properties, api) => {
                return await wrapSalesApiQuery(api, async salesService => {
                    return await salesService.customLineItems.addCustom({ name: "customLineItem", ...properties, isCustomLineItem: true })
                })
            }
        }),

        updateLineItem: builder.mutation<UpdateServerResponse, UpdateLineItemArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.LINE_ITEM],
            queryFn: (args, api) =>
                wrapSalesApiQuery(api, salesService => salesService.lineItems.update(...args), L10n.format(TranslationKeys.busy.lineItem.update))
        }),

        moveLineItem: builder.mutation<UpdateServerResponse, MoveLineItemArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.LINE_ITEM],
            queryFn: (args, api) => wrapSalesApiQuery(api, salesService => salesService.lineItems.move(...args))
        }),

        deleteLineItems: builder.mutation<UpdateServerResponse, string[]>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.LINE_ITEM],
            queryFn: (lineItemIds, api) => wrapSalesApiQuery(api, salesService => salesService.lineItems.delete(lineItemIds))
        }),

        duplicateLineItem: builder.mutation<UpdateServerResponse, DuplicateLineItemArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS],
            queryFn: (args, api) => wrapSalesApiQuery(api, salesService => salesService.lineItems.duplicate(...args))
        }),

        createConfiguration: builder.mutation<string, string>({
            queryFn: (productId, api) => wrapSalesApiQuery(api, salesService => salesService.configuration.create(productId))
        }),

        openConfiguration: builder.mutation<string, string>({
            queryFn: (lineItemId, api) => wrapSalesApiQuery(api, salesService => salesService.configuration.open(lineItemId))
        }),

        addConfiguration: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS],
            queryFn: (_args, api) =>
                wrapSalesApiQuery(api, salesService => salesService.configuration.add(), L10n.format(TranslationKeys.busy.configuration.add))
        }),

        saveConfiguration: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS],
            queryFn: (_args, api) =>
                wrapSalesApiQuery(api, salesService => salesService.configuration.save(), L10n.format(TranslationKeys.busy.configuration.save))
        }),

        stopConfiguration: builder.mutation<void, void>({
            queryFn: async (_args, api) => {
                const salesService: SalesService = (api.getState() as any).sales.salesService
                try {
                    await salesService.configuration.stop()
                    return { data: undefined }
                } catch (e) {
                    return { error: { name: "Error", message: "Stop configuration failed." } }
                }
            }
        }),

        print: builder.mutation<PrintResponse, PrintArgs>({
            queryFn: (args, api) => wrapSalesApiQuery(api, salesService => salesService.custom.call("/print", args))
        })
    })
})

export default SalesApi
