import { ISearchBox, SearchBox } from "@fluentui/react/lib/SearchBox"
import { IProcessedStyleSet, mergeStyles, registerIcons } from "@fluentui/react/lib/Styling"
import React, { KeyboardEventHandler, Ref } from "react"
import { IFilterDetailDropdownStyles, ISearchBoxState } from "../FilterDetailDropdown.types"
import { FilterIcon } from "@fluentui/react-icons-mdl2"
import { IRenderFunction } from "@fluentui/react/lib/Utilities"
import { IDropdownProps as IFluentDropdownProps } from "@fluentui/react/lib/components/Dropdown/Dropdown.types"

const FILTER = "Filter"
registerIcons(
    {
        icons: {
            [FILTER]: <FilterIcon />
        }
    },
    { disableWarnings: true }
)

interface FilterDetailDropdownWrapperProps {
    searchBoxWrapperId?: string
    searchBoxState: ISearchBoxState
    searchBoxRef: Ref<ISearchBox>
    onSearching: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>
    dropdownProps?: IFluentDropdownProps
    defaultRender?: IRenderFunction<IFluentDropdownProps>
    classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>
}

const FilterDetailDropdownWrapper = (props: FilterDetailDropdownWrapperProps) => {
    const { searchBoxWrapperId, searchBoxState, searchBoxRef, onSearching, onKeyUp, dropdownProps, defaultRender, classNames } = props

    return (
        <div style={{ width: "100%" }}>
            <div className={classNames.searchBoxContainer} id={searchBoxWrapperId}>
                <SearchBox
                    className={mergeStyles(classNames.searchBox, searchBoxState.className)}
                    autoComplete="off"
                    showIcon={true}
                    componentRef={searchBoxRef}
                    placeholder={FILTER}
                    iconProps={{ iconName: FILTER }}
                    onChange={onSearching}
                    onKeyUp={onKeyUp}
                />
            </div>
            {defaultRender && defaultRender(dropdownProps)}
        </div>
    )
}

export default FilterDetailDropdownWrapper
