import theme from "../../../../../App.theme"

export const FOOTER_ROW_ID = "footer_row_id"

const LineItemsDataGridStyles = {
    [`.${FOOTER_ROW_ID}`]: {
        backgroundColor: theme.palette.background.default + " !important",
        borderTopColor: theme.palette.common.black + " !important",
        borderBottomColor: theme.palette.common.black + " !important",
        borderTopWidth: "1px !important",
        borderBottomWidth: "1px !important",
        order: 1
    },
    "& .MuiDataGrid-row:hover": {
        cursor: "default"
    },
    "& .MuiDataGrid-cell--editable": {
        cursor: "pointer"
    }
}
export default LineItemsDataGridStyles
