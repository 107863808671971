import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { GenericInput } from "../../../../components/forms/genericForm/GenericForm"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"

export default function getGeneralInformationInputs(): GenericInput[] {
    return [
        {
            id: SalesDocumentProperties.QUOTE_ID,
            label: L10n.format(TranslationKeys.project.properties.id)
        },
        {
            id: SalesDocumentProperties.CREATED_BY,
            label: L10n.format(TranslationKeys.project.properties.createdBy)
        },
        {
            type: "date",
            id: SalesDocumentProperties.CREATED_AT,
            label: L10n.format(TranslationKeys.project.properties.createdAt)
        },
        {
            type: "date",
            id: SalesDocumentProperties.MODIFIED_AT,
            label: L10n.format(TranslationKeys.project.properties.modifiedAt)
        },
        {
            id: SalesDocumentProperties.MODIFIED_BY,
            label: L10n.format(TranslationKeys.project.properties.modifiedBy)
        },
        {
            id: SalesDocumentProperties.ADDITIONAL_INFORMATION,
            label: L10n.format(TranslationKeys.project.properties.additionalInformation)
        }
    ]
}
