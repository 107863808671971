import { getTheme } from "@fluentui/react"
import { IDropdownStyles } from "@encoway/cui-configurator-components/src/components/Dropdown/Dropdown.types"
import CuiStyleConstants from "../../constants/CuiStyleConstants"
import muiTheme from "../../../../../../App.theme"

const theme = getTheme()

const AbbDropdownStyles: IDropdownStyles = {
    optionConflict: {
        color: muiTheme.palette.text.disabled
    },
    dropdown: {
        selectors: {
            ".ms-Dropdown-title": {
                borderWidth: CuiStyleConstants.FIELD_BORDER_WIDTH,
                borderRadius: CuiStyleConstants.FIELD_BORDER_RADIUS
            },
            ".ms-Dropdown-titleIsPlaceHolder": {
                borderColor: CuiStyleConstants.FIELD_BORDER_COLOR
            },
            ".ms-Dropdown-caretDown": {
                color: theme.palette.neutralPrimary,
                fontSize: theme.fonts.tiny.fontSize
            }
        }
    },
    notReady: {
        selectors: {
            ".ms-Dropdown-title": {
                borderColor: theme.palette.themePrimary
            }
        }
    }
}

export default AbbDropdownStyles
