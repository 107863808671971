import { getTheme, ILabelStyles } from "@fluentui/react"

const theme = getTheme()

const AbbParameterLabelStyles: ILabelStyles = {
    root: {
        ":after": {
            color: theme.palette.themePrimary
        }
    }
}

export default AbbParameterLabelStyles
