export const CharacteristicIds = {
    ProductImage: "Product_image",
    CatalogLink: "CATALOGUE_LINK",
    Date: "Date",
    LongText: "long_text_gui",
    ShortText: "short_text_gui",
    GraphDefinition: "GraphDefinition",
    DocumentTypes: "documentTypes",
    Translations: "translationsFile",
    IndustrySegment: "INDUSTRY_SEGMENT",
    DestinationCountry: "DESTINATION_COUNTRY",
    ProjectCurrency: "PROJECT_CURRENCY",
    QuickSelectionEnabled: "QUICKSELECTION_ENABLED",
    LvFrameSize: "LV_FRAME_SIZE",
    ProductCategory: "PRODUCT_CATEGORY",
    LocalSalesUnit: "LOCAL_SALES_UNIT",
    ConfigurableProductReferenceModel: "CONFIG_REF_MODEL",
    ConfigurableProductReferencePreset: "CONFIG_REF_PRESET",
    DcModuleFrameSize: "DC_ModuleFrameSize",
    DcThyristorMaxTemp: "DC_ThyristorMaxTemp",
    DcModuleDimension: "DC_ModuleDimension",
    DcThyristorTemp: "DC_ThyristorTemp",
    DcCurrent: "DC_Current",
    DcMargin: "DC_Margin",
    DcProductName: "DC_ProductName",
    VisualizationSettings: "visualizationSettings",
    RetentionTime: "retentionTime",
    ArPlacement: "arPlacement",
    ArScaleFactor: "arScaleFactor"
} as const

export const ProductGroupIds = {
    Catalog: "_CATALOGUE",
    News: "NEWS"
} as const

export const ProductIds = {
    Translations: "application_texts",
    DocumentTypes: "document_types",
    ProjectDocumentTypes: "document_types_project"
} as const

export const ViewIds = {
    QUICK_SELECTION: "abb.quickselectionview",
    SALES_FILTER: "encoway.salesFilterView"
} as const
