import { useEffect, useState } from "react"
import { Box, Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import ConfigurationHeader from "./components/configurationHeader/ConfigurationHeader"
import Cui from "./components/cui/Cui"
import NavigateOptions from "../../router/constants/NavigateOptions"
import { AbbLineItemProperties } from "../../features/sales/sales.types"
import SalesApi from "../../features/sales/sales.api"

interface ConfigurationPageProps {
    onSave: (lineItemProperties: Partial<AbbLineItemProperties>, navigateOption: NavigateOptions) => void
    onStop: (navigateOption: NavigateOptions) => void
}

export default function ConfigurationPage({ onSave, onStop }: ConfigurationPageProps) {
    const salesDocument = SalesApi.useSalesDocumentQuery().data!

    const [shrinkHeader, setShrinkHeader] = useState(false)
    const handleScroll = () => setShrinkHeader(window.scrollY > 15)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    return !salesDocument.properties.quote_locked_by ? (
        <Stack spacing={3}>
            <ConfigurationHeader onSave={onSave} onStop={onStop} shrinkHeader={shrinkHeader} />
            <Cui />
        </Stack>
    ) : (
        <Box>{L10n.format(TranslationKeys.project.lockedDialog.message, { user: salesDocument.properties.quote_locked_by })}</Box>
    )
}
