import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { IDetailDropdownOption, IFilterDetailDropdownStyles } from "../FilterDetailDropdown.types"

const FilterDetailDropdownOptionStyles = (
    classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>,
    dropdownOption: IDetailDropdownOption,
    showImage: boolean,
    showShortText: boolean
) => {
    return {
        stackClassName: `${classNames.detailOption} ${dropdownOption.selected ? "encoway-cui-dropdown-selected-value" : ""}`,
        valueImage:
            dropdownOption.shortText && showShortText
                ? mergeStyles(classNames.imageContainer, {
                      marginLeft: "0.2rem"
                  })
                : classNames.imageContainer,
        optionStack: {
            marginTop: showImage ? "0.1rem" : undefined,
            overflow: "hidden",
            width: "100%"
        },
        optionStackTokens: {
            childrenGap: "0px"
        }
    }
}

export default FilterDetailDropdownOptionStyles
