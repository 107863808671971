import { Button, DialogActions as MuiDialogActions } from "@mui/material"
import DialogActionsStyles from "./DialogActions.styles"
import { ButtonProps } from "@mui/material/Button"
import { L10n } from "@encoway/l10n"

export interface ButtonPropsWithLabel extends ButtonProps {
    label: string
}

export interface DialogActionsProps {
    actions: ButtonPropsWithLabel[]
}

export default function DialogActions({ actions }: DialogActionsProps) {
    return (
        <MuiDialogActions data-cy={"MuiDialogActions"} disableSpacing={true} sx={DialogActionsStyles.actions}>
            {actions.map(action => (
                <Button data-cy={`Button.${action.label}`} key={action.label} {...action}>
                    {L10n.format(action.label)}
                </Button>
            ))}
        </MuiDialogActions>
    )
}
