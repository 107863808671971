import { ContainerTO, ValueType } from "@encoway/c-services-js-client"
import CuiUtils from "../../../utils/CuiUtils"
import checkForFaultyTableDefinition from "./checkForFaultyTableDefinition"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import compareStrings from "../../../../../../../utils/compareStrings"
import ViewPortProperties from "../../../constants/ViewPortProperties"

type ColumnSortComparator = (column1: ContainerTO, column2: ContainerTO) => number

export interface ITable {
    rows: ContainerTO[]
    columns: ContainerTO[]
}

export default class TableUtils {
    public static getTableKey(container: ContainerTO, index: number) {
        return container?.name && container.name + "-" + index
    }

    public static getTable(props: InputFieldProps, configurationContainer: ContainerTO): ITable {
        const viewPortPropertyValue = props.data.viewPortProperties?.[ViewPortProperties.SORT_COLUMNS_BY_ROW] as string | undefined
        const sortRow = viewPortPropertyValue ? CuiUtils.readViewportPropertyValue(viewPortPropertyValue) : undefined
        const container = this.getContainer(props.data.name, configurationContainer)
        const filteredRows = container.children.filter(row => row.name.startsWith("ROW_") && row.children.some(c => CuiUtils.hasParameters(c)))
        const columnsToShow = this.determineColumnsToShow(filteredRows)
        const sortComparator = sortRow ? this.getColumnSortComparator(filteredRows, sortRow) : undefined

        const table = {
            columns: this.getColumns(container, columnsToShow, sortComparator),
            rows: this.getRows(filteredRows, columnsToShow, sortComparator)
        }

        checkForFaultyTableDefinition(table)

        return table
    }

    private static getContainer(name: string, configurationContainer: ContainerTO) {
        return CuiUtils.walkContainers(
            configurationContainer,
            container => container.name.startsWith(name),
            container => container
        ).at(0)!
    }

    /**
     * returns set of table keys of columns that have at least one child with parameters.
     * @param rows rows of table
     * @private
     */
    private static determineColumnsToShow(rows: ContainerTO[]) {
        const columnsToShow = rows.reduce((result: string[], row) => {
            return result.concat(
                row.children.reduce((keys: string[], column, columnIndex) => {
                    return CuiUtils.hasParameters(column) ? [...keys, this.getTableKey(column, columnIndex)] : keys
                }, [])
            )
        }, [])
        return new Set(columnsToShow)
    }

    /**
     * determines the column order and returns a corresponding sort comparator function.
     * @param rows rows of table
     * @param sortRowName name of row that should be sorted
     * @private
     */
    private static getColumnSortComparator(rows: ContainerTO[], sortRowName: string): ColumnSortComparator | undefined {
        const columnOrder = rows
            .find(row => row.name === sortRowName)
            ?.children?.map(column => ({
                name: column.name,
                value: column.parameters.at(0)?.values?.at(0)?.value,
                valueType: column.parameters.at(0)?.valueType
            }))
            ?.sort((column1, column2) => {
                if (!column2.value) {
                    return -1
                } else if (!column1.value) {
                    return 1
                } else if (column1.valueType !== ValueType.String && column2.valueType !== ValueType.String) {
                    return parseFloat(column1.value) - parseFloat(column2.value)
                } else {
                    return compareStrings(column1.value, column2.value)
                }
            })
            .map(c => c.name)
        return columnOrder ? (column1: ContainerTO, column2: ContainerTO) => columnOrder.indexOf(column1.name) - columnOrder.indexOf(column2.name) : undefined
    }

    private static getRows(filteredRows: ContainerTO[], columnsToShow: Set<string>, sortComparator?: ColumnSortComparator): ContainerTO[] {
        return filteredRows.map(row => {
            const columns = row.children.filter((child, index) => columnsToShow.has(this.getTableKey(child, index)))
            const children = sortComparator ? [...columns].sort(sortComparator) : columns
            return { ...row, children: children }
        })
    }

    private static getColumns(container: ContainerTO, columnsToShow: Set<string>, sortComparator?: ColumnSortComparator) {
        const columns = container.children.filter((column, index) => column.name.startsWith("COLUMN_") && columnsToShow.has(this.getTableKey(column, index)))
        return sortComparator ? columns.sort(sortComparator) : columns
    }
}
