import { useCallback, useMemo, useState } from "react"
import { AbbSalesDocumentEntity } from "../sales.types"
import useNavigate from "../../../router/hooks/useNavigate"
import { useDecodedToken } from "../../oidc/hooks/useDecodedToken"

export default function useOpenSalesDocumentHandler() {
    const [lockedSalesDocument, setLockedSalesDocument] = useState<AbbSalesDocumentEntity | undefined>()
    const navigate = useNavigate()
    const idToken = useDecodedToken()

    const lockedSalesDocumentCanBeOpened = idToken?.username === lockedSalesDocument?.properties.quote_locked_by

    const cancel = useCallback(() => setLockedSalesDocument(undefined), [])

    const openLockedSalesDocument = useCallback(() => {
        setLockedSalesDocument(undefined)
        navigate.toProjectDetails(lockedSalesDocument!.salesDocumentId)
    }, [navigate, lockedSalesDocument])

    const open = useCallback(
        (salesDocument: AbbSalesDocumentEntity) => {
            if (!salesDocument.properties.quote_locked_by) {
                navigate.toProjectDetails(salesDocument.salesDocumentId)
            } else {
                setLockedSalesDocument(salesDocument)
            }
        },
        [navigate]
    )

    return useMemo(
        () => ({
            open,
            cancel,
            lockedSalesDocument,
            lockedSalesDocumentCanBeOpened,
            openLockedSalesDocument
        }),
        [open, cancel, lockedSalesDocument, lockedSalesDocumentCanBeOpened, openLockedSalesDocument]
    )
}
