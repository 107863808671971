import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { GenericInput, InputType } from "../../../../components/forms/genericForm/GenericForm"
import { Characteristic } from "@encoway/c-services-js-client"
import { PriceList } from "@encoway/sales-api-js-client"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"

export default function getProjectInformationInputs(localSalesUnit?: Characteristic, priceList?: PriceList): GenericInput[] {
    return [
        {
            id: SalesDocumentProperties.PROJECT_NAME,
            label: L10n.format(TranslationKeys.project.properties.projectName)
        },
        {
            id: SalesDocumentProperties.FACTORY_OFFER_REF,
            label: L10n.format(TranslationKeys.project.properties.factoryOfferRef)
        },
        {
            id: SalesDocumentProperties.FACTORY_SAP_ORDER_NUMBER,
            label: L10n.format(TranslationKeys.project.properties.factorySapOrderNumber)
        },
        {
            type: InputType.Dropdown,
            id: SalesDocumentProperties.LOCAL_SALES_UNIT,
            label: L10n.format(TranslationKeys.project.properties.localSalesUnit),
            mandatory: true,
            values: localSalesUnit ? CatalogUtils.getPossibleValues(localSalesUnit, true).map(pv => ({ value: pv.id, text: pv.value as string })) : []
        },
        {
            id: SalesDocumentProperties.PRICE_LIST,
            label: L10n.format(TranslationKeys.project.properties.priceList),
            disabled: true,
            value: priceList?.properties["sys_pl_id"]
        }
    ]
}
