import { useEffect, useState } from "react"
import { generatePath } from "react-router-dom"
import { Button, Divider, Stack, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ReadyState } from "@encoway/cui-application-components"
import ABBLogo from "../../../../assets/logo.svg"
import ConfigurationHeaderStyles from "./ConfigurationHeader.styles"
import BackToLink from "../../../../components/links/backToLink/BackToLink"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import GenerateDocumentsButton from "./generateDocumentsButton/GenerateDocumentsButton"
import SaveButton from "../../../../components/buttons/saveButton/SaveButton"
import Input from "../../../../components/input/Input"
import theme from "../../../../App.theme"
import Image from "../../../../components/image/Image"
import Container from "@mui/material/Container"
import RouterPaths from "../../../../router/constants/RouterPaths"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import NavigateOptions from "../../../../router/constants/NavigateOptions"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { CharacteristicIds } from "../../../../features/catalog/catalog.constants"
import { LineItemProperties } from "../../../../features/sales/sales.constants"
import { AbbLineItemProperties } from "../../../../features/sales/sales.types"
import useParams from "../../../../router/hooks/useParams"
import useConfigurationProduct from "../../../../features/sales/hooks/useConfigurationProduct"
import SalesApi from "../../../../features/sales/sales.api"
import useConfigurationService from "../../hooks/useConfigurationService"
import SalesSlice from "../../../../features/sales/sales.slice"
import VisualizationApi from "../../../../features/visualization/visualization.api"
import useCuiAppSettings from "../../hooks/useCuiAppSettings"
import IconButton from "@mui/material/IconButton"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import VisualizationSlice from "../../../../features/visualization/visualization.slice"
import useAr from "../../../../features/visualization/useAr"
import BusySlice from "../../../../features/busy/busy.slice"
import useVisualization from "../../../../features/visualization/useVisualization"

interface ConfigurationHeaderProps {
    onSave: (lineItemProperties: Partial<AbbLineItemProperties>, navigateOption: NavigateOptions) => void
    onStop: (navigateOption: NavigateOptions) => void
    shrinkHeader: boolean
}

export default function ConfigurationHeader({ onSave, onStop, shrinkHeader }: ConfigurationHeaderProps) {
    const [configurationName, setConfigurationName] = useState<string>()
    const visualizationIsVisible = useAppSelector(state => state.visualization.isVisible)
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const params = useParams()
    const dispatch = useAppDispatch()
    const product = useConfigurationProduct()
    const cuiAppSettings = useCuiAppSettings()
    const configurationService = useConfigurationService()
    const styles = ConfigurationHeaderStyles(shrinkHeader)
    const ar = useAr()
    const visualization = useVisualization()
    const salesDocument = SalesApi.useSalesDocumentQuery().data!
    const lineItem = SalesApi.useLineItemQuery(params.lineItemId!, { skip: !params.lineItemId }).data
    const [getRenderings] = VisualizationApi.useLazyRenderingsQuery()

    useEffect(() => {
        if (!configurationName) {
            setConfigurationName(lineItem?.properties.CONFIGURATION_NAME ?? product?.name)
        }
    }, [configurationName, lineItem, product])

    const updateConfigurationName = (name: string) => {
        setConfigurationName(name)
        dispatch(SalesSlice.actions.setConfigurationIsSavedToFalse())
    }

    const getConfigurationProperties = async () => {
        await visualization.render()
        const promiseResult = await Promise.allSettled([ar.update(), getRenderings(cuiAppSettings!).unwrap()])
        const imagesResult = promiseResult[1].status === "fulfilled" ? promiseResult[1].value : undefined
        const visualizationImage = imagesResult ? Object.values(imagesResult).at(0) : undefined
        const arQrCode = promiseResult[0].status === "fulfilled" ? promiseResult[0].value?.qr?.split(",").at(1) : undefined
        return {
            [LineItemProperties.AR_QR_CODE]: arQrCode,
            [LineItemProperties.VISUALIZATION_IMAGE]: visualizationImage
        }
    }

    const save = async (navigateOption: NavigateOptions) => {
        if (visualization.visualization) {
            const configurationProperties = await getConfigurationProperties()
            dispatch(BusySlice.actions.setBusy(L10n.format(TranslationKeys.busy.visualization.save)))
            await configurationService!.configurationProperties(configurationProperties)
            dispatch(BusySlice.actions.setIdle(L10n.format(TranslationKeys.busy.visualization.save)))
        }
        onSave({ [LineItemProperties.CONFIGURATION_NAME]: configurationName }, navigateOption)
    }

    const handleCancelButtonClick = () => {
        onStop(NavigateOptions.Navigate)
    }

    const activateFullscreen = () => {
        dispatch(VisualizationSlice.actions.setFullscreen(true))
    }

    return (
        <Stack spacing={1} sx={styles.stack}>
            <Container maxWidth="xl" sx={styles.container}>
                <Stack direction="row" justifyContent="space-between">
                    <BackToLink
                        text={salesDocument.properties.project_name}
                        route={generatePath(RouterPaths.PROJECT_DETAILS, { salesDocumentId: salesDocument.salesDocumentId })}
                    />
                    <Stack direction="row" spacing={2} alignItems="start" style={{ flexShrink: 0 }}>
                        <Button onClick={handleCancelButtonClick}>{L10n.format(TranslationKeys.pages.configuration.cancelButtonLabel)}</Button>
                        <IconButton disabled={!visualizationIsVisible} onClick={activateFullscreen}>
                            <FullscreenIcon />
                        </IconButton>
                        <GenerateDocumentsButton
                            lineItemId={lineItem?.lineItemId}
                            disabled={lineItem?.properties.readystate !== ReadyState.Ready || !configurationIsSaved}
                            disabledReason={L10n.format(L10n.format(TranslationKeys.pages.configuration.generateDocumentsButton.disabledReason))}
                        />
                        <SaveButton
                            label={L10n.format(TranslationKeys.pages.configuration.applyButton.label)}
                            disabled={configurationIsSaved || !configurationName}
                            onClick={save}
                            shouldBlockNavigation={!configurationIsSaved}
                            shouldBlockUnload={!configurationIsSaved}
                            menuItems={[
                                {
                                    text: L10n.format(TranslationKeys.pages.configuration.applyButton.stayOptionLabel),
                                    value: NavigateOptions.Stay,
                                    cypressId: "stayOptionLabel"
                                },
                                {
                                    text: L10n.format(TranslationKeys.pages.configuration.applyButton.navigateOptionLabel),
                                    value: NavigateOptions.Navigate,
                                    cypressId: "navigateOptionLabel"
                                }
                            ]}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Image
                        style={styles.productImage}
                        src={product ? CatalogUtils.getMediaUri(product, CharacteristicIds.ProductImage) : undefined}
                        fallbackImageProps={{ src: ABBLogo, alt: "image of " + lineItem?.properties.label }}
                    />
                    <Stack spacing={1}>
                        <Typography sx={styles.productName}>{lineItem?.properties.label}</Typography>
                        <Typography sx={styles.productShortText}>
                            {product && CatalogUtils.getCharacteristicValue(product, CharacteristicIds.ShortText)}
                        </Typography>
                    </Stack>
                </Stack>
                {!shrinkHeader && (
                    <Input
                        required
                        autoFocus
                        label={L10n.format(TranslationKeys.lineItem.properties.configurationName)}
                        value={configurationName ?? ""}
                        onChange={event => updateConfigurationName(event.target.value)}
                    />
                )}
            </Container>
            <Divider sx={{ boxShadow: theme.shadows[1] }} />
        </Stack>
    )
}
