import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import AuthorizedContent from "./components/authorizedContent/AuthorizedContent"
import TranslationsProvider from "./features/translations/TranslationsProvider"
import { RouterProvider } from "react-router-dom"
import router from "./router/constants/router"
import AbbOverlaySpinner from "./pages/configurationPage/components/cui/components/AbbSpinner/AbbOverlaySpinner"
import { useAppDispatch, useAppSelector } from "./store/store"
import ErrorSlice from "./features/error/error.slice"
import ErrorDialog from "./pages/rootPage/components/errorDialog/ErrorDialog"

export const Application = () => {
    const isBusy = useAppSelector(state => state.busy.isBusy)
    const token = useAppSelector(state => state.oidc.token)
    const error = useAppSelector(state => state.error.error)
    const dispatch = useAppDispatch()

    return (
        <TranslationsProvider>
            {isBusy || !token ? <AbbOverlaySpinner /> : null}

            {!isBusy && <ErrorDialog error={error} onClose={() => dispatch(ErrorSlice.actions.set(undefined))} />}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AuthorizedContent>
                    <RouterProvider router={router} />
                </AuthorizedContent>
            </LocalizationProvider>
        </TranslationsProvider>
    )
}
