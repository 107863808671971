import theme from "../../../../App.theme"

const CuiStyles = {
    appSwitch: {
        root: {
            marginTop: "230px !important",
            transition: `margin   ${theme.transitions.duration.complex}ms`,
            ".configuration": {
                backgroundColor: theme.palette.background.default
            }
        }
    }
}

export default CuiStyles
