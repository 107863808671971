import { GenericInput } from "../../../../components/forms/genericForm/GenericForm"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"

export default function getContactInformationInputs(): GenericInput[] {
    return [
        {
            id: SalesDocumentProperties.SALES_UNIT_CONTACT_NAME,
            label: L10n.format(TranslationKeys.project.properties.salesUnitContactName),
            mandatory: true
        },
        {
            type: "email",
            id: SalesDocumentProperties.SALES_UNIT_CONTACT_EMAIL,
            label: L10n.format(TranslationKeys.project.properties.salesUnitContactEmail),
            mandatory: true
        },
        {
            id: SalesDocumentProperties.AREA_SALES_MANAGER_NAME,
            label: L10n.format(TranslationKeys.project.properties.areaSalesManagerName),
            mandatory: true
        },
        {
            type: "email",
            id: SalesDocumentProperties.AREA_SALES_MANAGER_EMAIL,
            label: L10n.format(TranslationKeys.project.properties.areaSalesManagerEmail),
            mandatory: true
        }
    ]
}
