import { useState } from "react"
import { Button, IconButton, Stack } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CalculateIcon from "@mui/icons-material/Calculate"
import ListIcon from "@mui/icons-material/List"
import { L10n } from "@encoway/l10n"
import LineItemsDataGrid from "./lineItemsDataGrid/LineItemsDataGrid"
import useNavigate from "../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Dialog from "../../../../components/dialog/Dialog"
import useDialog from "../../../../components/dialog/useDialog"
import NewArticleDialog from "./customArticleDialog/NewArticleDialog"
import DocumentGenerationForm from "../../../../components/forms/documentGenerationForm/DocumentGenerationForm"
import theme from "../../../../App.theme"
import { ProductIds } from "../../../../features/catalog/catalog.constants"
import CatalogApi from "../../../../features/catalog/catalog.api"
import { BomNode } from "../../../../features/sales/sales.types"
import SalesApi from "../../../../features/sales/sales.api"
import useLineItems from "../../../../features/sales/hooks/useLineItems"
import usePrint from "../../../../features/sales/hooks/usePrint"

export default function ProjectComposition() {
    const [bomNodeToPrint, setBomNodeToPrint] = useState<BomNode>()
    const [calculationViewIsSelected, setCalculationViewIsSelected] = useState(false)

    const documentTypes = CatalogApi.useDocumentTypesQuery(ProductIds.DocumentTypes).data ?? []
    const salesDocument = SalesApi.useSalesDocumentQuery().data!

    const lineItems = useLineItems()
    const print = usePrint()
    const navigate = useNavigate()
    const dialog = useDialog()

    const submitDocumentGeneration = (documentTemplate: string, documentFormat: string) => {
        setBomNodeToPrint(undefined)
        print({ documentTemplate, documentFormat, lineItemId: bomNodeToPrint!.item.lineItemId })
    }

    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={() => navigate.toCatalog(salesDocument.salesDocumentId)}>
                        {L10n.format(TranslationKeys.pages.project.composition.addLineItemButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" endIcon={<AddIcon />} onClick={lineItems.addFolder}>
                        {L10n.format(TranslationKeys.pages.project.composition.addFolderButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" endIcon={<AddIcon />} onClick={() => dialog.open()}>
                        {L10n.format(TranslationKeys.lineItem.newArticle.button.label)}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <IconButton
                        color="secondary"
                        sx={{ backgroundColor: calculationViewIsSelected ? undefined : theme.palette.divider }}
                        onClick={() => setCalculationViewIsSelected(false)}
                    >
                        <ListIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        sx={{ backgroundColor: calculationViewIsSelected ? theme.palette.divider : undefined }}
                        onClick={() => setCalculationViewIsSelected(true)}
                    >
                        <CalculateIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <LineItemsDataGrid
                lineItems={lineItems.data}
                onToggle={lineItems.toggle}
                onConfigure={node => navigate.toConfiguration(salesDocument.salesDocumentId, node.item.lineItemId)}
                onDelete={lineItems.setLineItemToDelete}
                onDuplicate={lineItems.duplicate}
                onUpdateProperty={lineItems.updateLineItem}
                onGenerateDocument={setBomNodeToPrint}
                onMove={lineItems.moveLineItem}
                calculationViewIsSelected={calculationViewIsSelected}
            />

            <NewArticleDialog {...dialog} {...lineItems} />

            <Dialog title={L10n.format(TranslationKeys.lineItem.actions.print)} onClose={() => setBomNodeToPrint(undefined)} open={Boolean(bomNodeToPrint)}>
                <DocumentGenerationForm documentTypes={documentTypes} onSubmit={submitDocumentGeneration} />
            </Dialog>

            <Dialog
                open={!!lineItems.lineItemToDelete}
                onClose={lineItems.cancelDelete}
                title={L10n.format(TranslationKeys.lineItem.deleteDialog.title)}
                message={L10n.format(TranslationKeys.lineItem.deleteDialog.message)}
                defaultDialogActions={{
                    onConfirm: lineItems.delete,
                    onCancel: lineItems.cancelDelete,
                    confirmButtonLabel: TranslationKeys.lineItem.deleteDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.lineItem.deleteDialog.cancelButtonLabel
                }}
            />
        </Stack>
    )
}
