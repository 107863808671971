import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Settings } from "../../settings"
import prepareHeaders from "../../store/prepareHeaders"

export interface PublicEnvironment {
    systemEnvironment: string
    clientId: string
    version: string
    issuerUri: string
    expectedIssuerUri: string
    endSessionUri: string
}

interface Environment {
    visualizationToken: string
    arServiceHost: string
    defaultArRetentionTime: string
}

const EnvironmentApi = createApi({
    reducerPath: "environmentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: Settings.configuration.baseUrl,
        prepareHeaders: prepareHeaders
    }),
    endpoints: builder => ({
        environment: builder.query<Environment, void>({
            query: () => ({ url: "/api/features/environment" })
        }),
        publicEnvironment: builder.query<PublicEnvironment, void>({
            query: () => ({ url: "/api/features/public/environment" })
        })
    })
})

export default EnvironmentApi
