import { Stack } from "@fluentui/react/lib/Stack"
import ValueImage from "../ValueImage/ValueImage"
import { IProcessedStyleSet } from "@fluentui/react/lib/Styling"
import DetailDropdownShortText from "../DetailDropdown/DetailDropdownShortText"
import React from "react"
import { IDetailDropdownOption, IFilterDetailDropdownStyles } from "../FilterDetailDropdown.types"
import { changeValue, onRenderItem, renderOption } from "@encoway/cui-configurator-components"
import { mediaLink } from "@encoway/react-configurator"
import FilterDetailDropdownOptionStyles from "./FilterDetailDropdownOption.styles"

interface FilterDetailDropdownOptionProps {
    showImage: boolean
    showShortText: boolean
    imageResolution?: string
    mediaLink?: mediaLink
    dropdownOption: IDetailDropdownOption
    invisibleOptions: string[]
    onRenderItem?: onRenderItem
    changeValue: changeValue
    defaultRender: renderOption
    classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>
}

const FilterDetailDropdownOption = (props: FilterDetailDropdownOptionProps) => {
    const { showImage, showShortText, imageResolution, dropdownOption, invisibleOptions, onRenderItem, changeValue, defaultRender, classNames } = props

    // performance improvement (only selected values can be invisible)
    if (dropdownOption.selected && invisibleOptions.indexOf(`${dropdownOption.key}`) >= 0) {
        return null
    } else if (onRenderItem) {
        return onRenderItem(dropdownOption, changeValue, defaultRender)
    }

    const sx = FilterDetailDropdownOptionStyles(classNames, dropdownOption, showImage, showShortText)

    return (
        <Stack horizontal data-is-focusable onClick={() => changeValue && changeValue(dropdownOption)} key={dropdownOption.key} className={sx.stackClassName}>
            <ValueImage
                image={dropdownOption.image}
                imageResolution={imageResolution}
                mediaLink={props.mediaLink}
                className={sx.valueImage}
                size={"2rem"}
                showImage={showImage}
            />
            <Stack style={sx.optionStack} tokens={sx.optionStackTokens}>
                <Stack.Item>{defaultRender(dropdownOption, changeValue)}</Stack.Item>
                <DetailDropdownShortText classNames={classNames} dropdownOption={dropdownOption} showShortText={showShortText} />
            </Stack>
        </Stack>
    )
}

export default FilterDetailDropdownOption
