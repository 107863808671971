import theme from "../../../../App.theme"

export const HeaderBorderWidth = {
    bottom: 1,
    top: 0
}

const HeaderStyles = {
    AppBar: {
        boxShadow: "none",
        borderWidth: `${HeaderBorderWidth.top}px 0 ${HeaderBorderWidth.bottom}px 0 `,
        borderStyle: "solid",
        borderColor: theme.palette.divider
    }
}

export default HeaderStyles
