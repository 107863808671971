import FormControl, { FormControlProps } from "@mui/material/FormControl"
import { FormControlLabel, FormGroup, FormLabel, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxGroupStyles from "./CheckBoxGroup.styles"
import InfoIcon from "../icons/infoIcon/InfoIcon"

export interface CheckBoxGroupOption {
    id: string
    label: string
    text?: string
}

interface CheckBoxGroupProps extends Omit<FormControlProps, "onChange"> {
    label: string
    options: CheckBoxGroupOption[]
    values: Record<string, boolean>
    onChange: (values: Record<string, boolean>) => void
    infoText?: string
}

export default function CheckBoxGroup({ label, options, values, onChange, infoText, ...props }: CheckBoxGroupProps) {
    return (
        <FormControl {...props}>
            <FormLabel>
                <span>{label}</span>
                {infoText ? <InfoIcon info={infoText} /> : null}
            </FormLabel>
            <FormGroup>
                {options.map(option => (
                    <FormControlLabel
                        key={option.id}
                        sx={CheckBoxGroupStyles.formControlLabel}
                        control={
                            <Checkbox checked={values[option.id]} onChange={event => onChange({ ...values, [option.id]: event.target.checked })} name="gilad" />
                        }
                        label={
                            <>
                                <Typography variant="subtitle1">{option.label}</Typography>
                                <Typography variant="caption" sx={CheckBoxGroupStyles.optionCaption}>
                                    {option.text}
                                </Typography>
                            </>
                        }
                    />
                ))}
            </FormGroup>
        </FormControl>
    )
}
