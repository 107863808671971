import { RootProps } from "@encoway/cui-configurator-components"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"
import React from "react"
import AbbRootStyles from "./AbbRoot.styles"

export default function AbbRoot(props: RootProps) {
    if (props.data?.parameters?.length > 0) {
        return <div style={AbbRootStyles}>{ComponentFactory.instanceOf(ComponentName.Section, props)}</div>
    }
    return <>{ComponentFactory.instanceOf(ComponentName.Tabs, props)}</>
}
