import { IStyleFunction } from "@fluentui/merge-styles"
import { IPartialTheme, IProcessedStyleSet, IStyleSet } from "@fluentui/react/lib/Styling"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import React, { MutableRefObject } from "react"
import { Viewer, VisualEditor } from "@encoway/visual-editor"
import { IVisualEditor } from "@encoway/cui-application-components/src/visualizationService.types"
import { VisualizationStyles } from "@encoway/cui-application-components"
import { IVisualizationStyles, VisualizationProps } from "./AbbVisualization.types"

const createComponent = (readOnly: boolean, visualProps: any) => {
    if (readOnly) {
        // @ts-ignore
        return <Viewer {...visualProps} />
    }
    // @ts-ignore
    return <VisualEditor {...visualProps} />
}

function IVisualization(props: VisualizationProps) {
    const { styles, theme } = props
    const stylesFunction = styles && typeof styles === "function" ? (styles as IStyleFunction<IPartialTheme, IStyleSet<IVisualizationStyles>>) : undefined
    const style = stylesFunction && stylesFunction(theme as IPartialTheme)
    const visualEditorRef = props.visualEditorForwardRef ? (props.visualEditorForwardRef as MutableRefObject<IVisualEditor>) : React.createRef<IVisualEditor>()
    const classNames = classNamesFunction()(props.styles, props.theme) as IProcessedStyleSet<IVisualizationStyles>

    const visualProps = {
        className: classNames.visualEditor,
        settings: props.settings,
        locale: props.locale,
        visualization: props.visualization,
        eventBus: props.eventBus,
        ref: visualEditorRef,
        onContextMenu: props.onContextMenu, // CUSTOMIZING
        onReady: () => (props.onReady ? props.onReady(visualEditorRef) : visualEditorRef.current?.zoom()),
        style: style?.visualEditorDiv
    }

    return <div className={classNames.root}>{createComponent(props.readOnly || false, visualProps)}</div>
}

export const AbbVisualization = styled(IVisualization, VisualizationStyles)
AbbVisualization.displayName = "Visualization"
