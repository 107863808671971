import { HeaderBorderWidth } from "../../../rootPage/components/header/Header.styles"
import theme from "../../../../App.theme"

const ConfigurationHeaderStyles = (shrink: boolean) => {
    const header = Array.from(document.getElementsByTagName("header")).at(0)
    return {
        stack: {
            position: "fixed",
            right: 0,
            left: 0,
            top: (header ? header.clientHeight : 0) + HeaderBorderWidth.top + HeaderBorderWidth.bottom,
            zIndex: theme.zIndex.appBar,
            backgroundColor: theme.palette.background.default
        },
        container: {
            mt: 1,
            display: "flex",
            flexDirection: "column",
            rowGap: 1
        },
        productImage: {
            height: shrink ? "30px" : "80px",
            transition: `width ${theme.transitions.duration.complex}ms, height ${theme.transitions.duration.complex}ms`
        },
        productName: {
            fontWeight: "bold",
            fontSize: shrink ? theme.typography.h3.fontSize : theme.typography.h5.fontSize,
            transition: `font-size ${theme.transitions.duration.complex}ms`
        },
        productShortText: {
            fontSize: theme.typography.subtitle2.fontSize,
            fontWeight: "bold",
            display: shrink ? "none" : "inherit"
        }
    }
}
export default ConfigurationHeaderStyles
