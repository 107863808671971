import { useState } from "react"
import { Button, Stack, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ProductGroup } from "@encoway/c-services-js-client"
import ProductQuickSelectionDataGrid from "./productQuickSelectionDataGrid/ProductQuickSelectionDataGrid"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import SalesApi from "../../../../../features/sales/sales.api"
import { CharacteristicIds } from "../../../../../features/catalog/catalog.constants"

interface ProductQuickSelectionProps {
    productGroups: ProductGroup[]
    characteristicFilters: Record<string, any>
}

export default function ProductQuickSelection({ productGroups, characteristicFilters }: ProductQuickSelectionProps) {
    const [productSelection, setProductSelection] = useState<Record<string, number>>({})
    const [triggerAddProducts] = SalesApi.useAddProductsMutation()

    const updateProductQuantity = (productId: string, quantity: number) => {
        setProductSelection(prevState => ({ ...prevState, [productId]: quantity }))
    }

    const addProducts = () => {
        triggerAddProducts(productSelection)
            .unwrap()
            .then(() => setProductSelection({}))
            .catch()
    }

    return (
        <Stack spacing={5} marginTop={3}>
            <Stack spacing={5}>
                {productGroups.map(productGroup => (
                    <Stack key={productGroup.id} spacing={2}>
                        <Stack>
                            <Typography variant="h3">{productGroup.name}</Typography>
                            <Typography variant="inherit">{CatalogUtils.getCharacteristicValue(productGroup, CharacteristicIds.ShortText)}</Typography>
                        </Stack>
                        <ProductQuickSelectionDataGrid
                            key={productGroup.id}
                            productGroupId={productGroup.id}
                            characteristicFilters={characteristicFilters}
                            updateProductQuantity={updateProductQuantity}
                            selectedProducts={productSelection}
                        />
                    </Stack>
                ))}
            </Stack>
            <Stack justifyContent="flex-end" direction="row" spacing={1}>
                <Button
                    disabled={Object.values(productSelection).reduce((total: number, value: number) => total + value, 0) === 0}
                    variant="contained"
                    onClick={addProducts}
                    color="inherit"
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.addToCompositionButtonLabel)}
                </Button>
                <Button variant="contained" onClick={() => console.log("Define common options")} color="secondary">
                    {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.defineCommonOptions)}
                </Button>
            </Stack>
        </Stack>
    )
}
