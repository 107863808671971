const AbbDimensioningDcStyles = {
    stack: {
        alignItems: "start",
        marginTop: 3
    },
    dataGrid: {
        width: "100%",
        ".highlighted": {
            backgroundColor: "#e2f7df"
        }
    },
    radioButton: {
        ":hover": {
            cursor: "pointer"
        }
    }
}

export default AbbDimensioningDcStyles
