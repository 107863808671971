import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined"
import Box from "@mui/material/Box"
import LanguageStyles from "./Language.styles"
import { L10n } from "@encoway/l10n"

export default function Language() {
    return (
        <>
            <Box component="div" sx={LanguageStyles.wrapper}>
                <PublicOutlinedIcon sx={LanguageStyles.icon} onClick={() => {}} />
                {L10n.currentLanguage().toUpperCase()}
            </Box>
        </>
    )
}
