import { Bus } from "baconjs"

const eventBus = new Bus()

export default eventBus

export enum EventBusEvent {
    InitialState = "InitialState",
    UpdateState = "UpdateState",
    ParameterChanged = "ParameterChanged"
}
