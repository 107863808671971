import { SnackbarState } from "../../../../features/snackbar/snackbar.slice"
import { Stack } from "@mui/material"
import Snackbar from "./snackbar/Snackbar"
import SnackbarStackStyles from "./SnackbarStack.styles"

interface SnackbarStackProps extends SnackbarState {
    onClose: (id: number) => void
}

export default function SnackbarStack({ snackbars, onClose }: SnackbarStackProps) {
    return (
        <Stack spacing={1} sx={SnackbarStackStyles}>
            {snackbars.map(snackbar => (
                <Snackbar key={snackbar.id} {...snackbar} onClose={onClose} />
            ))}
        </Stack>
    )
}
