import ValueImage from "../ValueImage/ValueImage"
import { IProcessedStyleSet } from "@fluentui/react/lib/Styling"
import { Stack } from "@fluentui/react/lib/Stack"
import React from "react"
import { IDetailDropdownOption, IFilterDetailDropdownStyles } from "../FilterDetailDropdown.types"
import { mediaLink } from "@encoway/react-configurator"
import { IRenderFunction } from "@fluentui/react/lib/Utilities"
import FilterDetailDropdownTitleStyles from "./FilterDetailDropdownTitle.styles"

interface FilterDetailDropdownTitleProps {
    showImage: boolean
    imageResolution?: string
    mediaLink?: mediaLink
    selectedDropdownOption?: IDetailDropdownOption
    defaultRender?: IRenderFunction<IDetailDropdownOption>
    classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>
}

const FilterDetailDropdownTitle = (props: FilterDetailDropdownTitleProps) => {
    const { showImage, imageResolution, mediaLink, selectedDropdownOption, defaultRender, classNames } = props

    const sx = FilterDetailDropdownTitleStyles(classNames, showImage)

    return (
        <Stack horizontal className={classNames.detailOptionSelected} tokens={sx.titleStackTokens}>
            <ValueImage
                image={selectedDropdownOption?.image}
                imageResolution={imageResolution}
                mediaLink={mediaLink}
                className={sx.valueImage}
                size={"1.2rem"}
                showImage={showImage}
            />
            <Stack.Item style={sx.titleStack}>{defaultRender && defaultRender(selectedDropdownOption)}</Stack.Item>
        </Stack>
    )
}

export default FilterDetailDropdownTitle
