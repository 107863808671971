import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { ContentCopy, DeleteOutline, ModeEdit } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { ReadyState } from "@encoway/cui-application-components"
import { useContext } from "react"
import { LineItemDataGridContext } from "../../LineItemsDataGrid"
import { FOOTER_ROW_ID } from "../../LineItemsDataGrid.styles"
import AssignmentIcon from "@mui/icons-material/Assignment"
import Icon from "../../../../../../../components/icons/Icon"
import { BomNode } from "../../../../../../../features/sales/sales.types"

export default function ActionsCell(params: GridRowParams<BomNode>) {
    const { onConfigure, onDuplicate, onDelete, onGenerateDocument } = useContext(LineItemDataGridContext)!
    return !params.row.item.properties.isSubarticle && params.id !== FOOTER_ROW_ID
        ? [
              <GridActionsCellItem
                  key="editAction"
                  icon={<ModeEdit />}
                  disabled={!params.row.item.properties.ABB_LINE_ITEM_ID}
                  label={L10n.format(TranslationKeys.lineItem.actions.edit)}
                  onClick={() => onConfigure(params.row)}
                  showInMenu
              />,
              <GridActionsCellItem
                  key="printAction"
                  label={L10n.format(TranslationKeys.lineItem.actions.print)}
                  onClick={() => onGenerateDocument(params.row)}
                  showInMenu
                  disabled={params.row.item.properties.readystate !== ReadyState.Ready}
                  icon={
                      <Icon
                          component={AssignmentIcon}
                          tooltipProps={{
                              disabled: params.row.item.properties.readystate === ReadyState.Ready,
                              content: L10n.format(TranslationKeys.pages.configuration.generateDocumentsButton.disabledReason)
                          }}
                      />
                  }
              />,
              <GridActionsCellItem
                  key="duplicateAction"
                  icon={<ContentCopy />}
                  label={L10n.format(TranslationKeys.lineItem.actions.duplicate)}
                  onClick={() => onDuplicate(params.row)}
                  disabled={!params.row.item.permission.includes("UPDATE")}
                  showInMenu
              />,
              <GridActionsCellItem
                  key="deleteAction"
                  icon={<DeleteOutline />}
                  label={L10n.format(TranslationKeys.lineItem.actions.delete)}
                  onClick={() => onDelete(params.row)}
                  disabled={!params.row.item.permission.includes("DELETE")}
                  showInMenu
              />
          ]
        : []
}
