const LanguageStyles = {
    wrapper: {
        height: {
            xs: "1.375rem",
            sm: "2.063rem"
        },
        mr: 0,
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        fontWeight: "bold"
    },
    icon: {
        fontSize: "medium",
        color: "text.secondary",
        mr: 0.5
    }
}

export default LanguageStyles
