import { GridRenderCellParams } from "@mui/x-data-grid"
import TypeCodeCell from "../typeCodeCell/TypeCodeCell"
import { BomNode } from "../../../../../../../features/sales/sales.types"
import Tooltip from "../../../../../../../components/tooltip/Tooltip"

export default function PlusCodeCell(params: GridRenderCellParams<BomNode>) {
    const typeCode = params.row.item.properties.TYPE_CODE
    return (
        <Tooltip content={params.value} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" display="inline-block" disabled={!params.value}>
            {typeCode ? <TypeCodeCell value={params.value} /> : params.value}
        </Tooltip>
    )
}
