import { CSSProperties } from "react"

const CustomGridCellStyles: Record<string, CSSProperties> = {
    editIconDiv: {
        width: "10px",
        float: "right"
    },
    editIcon: {
        transform: "scale(60%)",
        float: "left"
    }
}

export default CustomGridCellStyles
