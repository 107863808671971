import FormControl, { FormControlProps } from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { ReactNode } from "react"
import { ExpandMore } from "@mui/icons-material"
import theme from "../../App.theme"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import InfoIcon from "../icons/infoIcon/InfoIcon"
import compareStrings from "../../utils/compareStrings"

export interface DropdownMenuItem {
    value: any
    text: string
}

interface DropdownProps extends Omit<FormControlProps, "onChange"> {
    label: string
    value?: string
    menuItems: DropdownMenuItem[]
    onChange: (event: SelectChangeEvent, child: ReactNode) => void
    infoText?: string
    unselect?: boolean
    cypressId: string
}

export default function Dropdown({ label, value, menuItems, onChange, infoText, unselect, cypressId, ...props }: DropdownProps) {
    const id = label.replace(" ", "_") + "_dropdown_label"
    return (
        <FormControl data-cy={`Dropdown.${cypressId}`} fullWidth {...props}>
            <InputLabel data-cy={`Dropdown.InputLabel`} id={id}>
                <span>{label}</span>
                {infoText ? <InfoIcon info={infoText} /> : null}
            </InputLabel>
            <Select
                data-cy={`Dropdown.Select`}
                displayEmpty
                renderValue={
                    !value
                        ? () => <span style={{ color: theme.palette.text.disabled }}>{L10n.format(TranslationKeys.components.dropdown.placeholder)}</span>
                        : undefined
                }
                labelId={id}
                onChange={onChange}
                label={label}
                value={value ?? ""}
                IconComponent={ExpandMore}
            >
                {unselect && (
                    <MenuItem key={L10n.format(TranslationKeys.components.dropdown.unselectOption)} value={""}>
                        {L10n.format(TranslationKeys.components.dropdown.unselectOption)}
                    </MenuItem>
                )}
                {menuItems
                    .sort((menuItem1, menuItem2) => compareStrings(menuItem1.text, menuItem2.text, false))
                    .map(item => (
                        <MenuItem data-cy={`Dropdown.MenuItem`} key={item.text} value={item.value}>
                            {item.text}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}
