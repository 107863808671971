import { ThemeProvider } from "@mui/material/styles"
import theme from "./App.theme"
import { CssBaseline } from "@mui/material"
import { store } from "./store/store"
import { Provider } from "react-redux"
import { DndProvider } from "react-dnd"
import { TouchBackend } from "react-dnd-touch-backend"
import { Application } from "./Application"

export default function App() {
    return (
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <Application />
                </Provider>
            </ThemeProvider>
        </DndProvider>
    )
}
