import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { IFilterDetailDropdownStyles } from "../FilterDetailDropdown.types"

const FilterDetailDropdownTitleStyles = (classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>, showImage: boolean) => {
    return {
        titleStackTokens: {
            childrenGap: "0.5em"
        },
        valueImage: showImage ? mergeStyles(classNames.imageContainer, { marginTop: "0.33rem" }) : undefined,
        titleStack: {
            width: "calc(100% - 0.6rem)"
        }
    }
}

export default FilterDetailDropdownTitleStyles
