import { MouseEvent, useContext } from "react"
import { GridRenderCellParams } from "@mui/x-data-grid"
import { L10n } from "@encoway/l10n"
import ExpandButton from "../../../../../../../components/buttons/expandButton/ExpandButton"
import { LineItemDataGridContext } from "../../LineItemsDataGrid"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { BomNode } from "../../../../../../../features/sales/sales.types"
import Tooltip from "../../../../../../../components/tooltip/Tooltip"

export default function ProductNameCell(params: GridRenderCellParams<BomNode>) {
    const { onToggle } = useContext(LineItemDataGridContext)!
    const toggle = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onToggle(params.row)
    }
    if (!params.row.item.properties.isLeaf && params.row.level > 0) {
        const value = params.row.item.properties[params.field] as string | number
        return (
            <>
                <span style={{ marginLeft: params.row.level * 10 - 20 }} />
                {params.row.item.properties.isLeaf ? null : <ExpandButton expanded={params.row.expanded} onToggle={toggle} />}
                <Tooltip content={value} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" disabled={!value}>
                    {value}
                </Tooltip>
            </>
        )
    } else if (params.row.item.properties.IS_COMMON_FOLDER === "true") {
        const value = L10n.format(TranslationKeys.pages.project.composition.commonFolder)
        return (
            <Tooltip content={value} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" disabled={!value}>
                <span style={{ marginLeft: params.row.level * 10 }}>{value}</span>
            </Tooltip>
        )
    } else {
        const value = params.row.item.properties[params.field] as string | number
        return (
            <Tooltip content={value} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" disabled={!value}>
                <span style={{ marginLeft: params.row.level * 10 }}>{value}</span>
            </Tooltip>
        )
    }
}
