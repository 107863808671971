import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ContainerTO } from "@encoway/c-services-js-client"
import { SalesService } from "@encoway/sales-api-js-client"
import SalesApi from "./sales.api"
import SalesUtils from "./sales.utils"

export interface SalesState {
    salesService?: SalesService
    salesDocumentId?: string
    salesDocumentIsSaved: boolean
    configurationIsSaved: boolean
    configurationId?: string
    configurationContainer?: ContainerTO
    configurationProductId?: string
}

export function setupSalesSlice(initialState: SalesState) {
    return createSlice({
        name: "salesSlice",
        initialState,
        reducers: {
            setSalesService: (state, action: PayloadAction<SalesService>) => {
                state.salesService = action.payload
            },

            setConfigurationContainer: (state, action: PayloadAction<ContainerTO | undefined>) => {
                state.configurationContainer = action.payload
            },

            setConfigurationIsSavedToFalse: state => {
                state.configurationIsSaved = false
            }
        },
        extraReducers: builder => {
            builder.addMatcher(SalesApi.endpoints.addProduct.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.addProducts.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.addFolder.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.addCustomLineItem.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.moveLineItem.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.updateLineItem.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.duplicateLineItem.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.deleteLineItems.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.createConfiguration.matchFulfilled, (state, action) => {
                const response = SalesUtils.configuration.readConfigurationResponse(action.payload)
                state.configurationId = response.configurationId
                state.configurationProductId = response.product
                state.configurationIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.openConfiguration.matchFulfilled, (state, action) => {
                const response = SalesUtils.configuration.readConfigurationResponse(action.payload)
                state.configurationId = response.configurationId
                state.configurationProductId = response.product
                state.configurationIsSaved = true
            })

            builder.addMatcher(SalesApi.endpoints.stopConfiguration.matchFulfilled, state => {
                state.configurationId = undefined
                state.configurationProductId = undefined
                state.configurationIsSaved = true
            })

            builder.addMatcher(SalesApi.endpoints.saveConfiguration.matchFulfilled, state => {
                state.configurationId = undefined
                state.configurationProductId = undefined
                state.configurationIsSaved = true
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.addConfiguration.matchFulfilled, state => {
                state.configurationId = undefined
                state.configurationProductId = undefined
                state.configurationIsSaved = true
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.openSalesDocument.matchFulfilled, (state, action) => {
                state.salesDocumentId = action.meta.arg.originalArgs
                state.salesDocumentIsSaved = true
            })

            builder.addMatcher(SalesApi.endpoints.closeSalesDocument.matchFulfilled, state => {
                state.salesDocumentId = undefined
                state.salesDocumentIsSaved = true
            })

            builder.addMatcher(SalesApi.endpoints.updateSalesDocument.matchFulfilled, state => {
                state.salesDocumentIsSaved = false
            })

            builder.addMatcher(SalesApi.endpoints.saveSalesDocument.matchFulfilled, state => {
                state.salesDocumentIsSaved = true
            })

            builder.addMatcher(SalesApi.endpoints.setSalesDocumentStatus.matchFulfilled, state => {
                state.salesDocumentIsSaved = true
            })
        }
    })
}

const SalesSlice = setupSalesSlice({
    salesDocumentIsSaved: true,
    configurationIsSaved: true
})

export default SalesSlice
