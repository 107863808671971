import DashboardWidget from "../dashboardWidget/DashboardWidget"
import { Stack } from "@mui/material"
import NewsCard from "./newsCard/NewsCard"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { Product } from "@encoway/c-services-js-client"

interface NewsWidgetProps {
    news: Product[]
}
export default function NewsWidget({ news }: NewsWidgetProps) {
    return (
        <DashboardWidget title={L10n.format(TranslationKeys.pages.dashboard.newsWidget.title)}>
            <Stack direction="row" display="flex" flexWrap="wrap" spacing={4}>
                {news.map(product => (
                    <NewsCard key={product.id} product={product} />
                ))}
            </Stack>
        </DashboardWidget>
    )
}
