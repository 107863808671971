import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { SalesService, UpdateServerResponse } from "@encoway/sales-api-js-client"
import BusySlice from "../busy/busy.slice"
import ErrorSlice from "../error/error.slice"
import SnackbarSlice from "../snackbar/snackbar.slice"
import ProgressSlice from "../progress/progress.slice"
import { AbbLineItem } from "./sales.types"
import { LineItemProperties } from "./sales.constants"
import readError from "../error/utils/readError"

export async function wrapSalesApiQuery<T>(api: BaseQueryApi, query: (salesService: SalesService) => Promise<T>, busyText?: string) {
    try {
        api.dispatch(BusySlice.actions.setBusy(busyText))
        const salesService = (api.getState() as any).sales.salesService // as any necessary because as RootState would cause circular dependency
        const result = await query(salesService)
        return { data: result }
    } catch (e) {
        const error = await readError(e)
        api.dispatch(ErrorSlice.actions.set(error))
        return { error }
    } finally {
        api.dispatch(BusySlice.actions.setIdle(busyText))
    }
}

export function getAddedLineItem(response: UpdateServerResponse): AbbLineItem | undefined {
    return Object.values(response.addedChildLineItems).at(0)?.at(0)
}

export function getUpdatedLineItem(lineItemId: string, response: UpdateServerResponse): AbbLineItem | undefined {
    return Object.values(response.changedLineItems).find(lineItem => lineItem.lineItemId === lineItemId)
}

export async function addProduct(api: BaseQueryApi, productId: string, amount: number) {
    try {
        const salesService: SalesService = (api.getState() as any).sales.salesService
        const response = await salesService.lineItems.addProduct(productId)
        const lineItemId = getAddedLineItem(response)!.lineItemId
        await salesService.lineItems.update(lineItemId, {
            [LineItemProperties.POSITION_QUANTITY]: amount
        })
    } catch (e) {
        const error = await readError(e)
        api.dispatch(SnackbarSlice.actions.open({ message: error.message, severity: "error" }))
        throw error
    } finally {
        api.dispatch(ProgressSlice.actions.increment(amount))
    }
}
