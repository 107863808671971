import { Link } from "@mui/material"
import { ChevronLeft } from "@mui/icons-material"
import { Link as RouterLink } from "react-router-dom"
import BackToLinkStyles from "./BackToLinkStyles"

interface BackToLinkProps {
    text?: string
    route: string
}

export default function BackToLink({ text, route }: BackToLinkProps) {
    return (
        <Link data-cy={"BackToLink"} component={RouterLink} to={route} sx={BackToLinkStyles}>
            <ChevronLeft fontSize="small" />
            {text}
        </Link>
    )
}
