import { Value } from "@encoway/c-services-js-client"
import { IDetailDropdownValue } from "./FilterDetailDropdown.types"
import { createBasicDropdownOptionType, IDropdownOption } from "@encoway/cui-configurator-components/src/components/Dropdown/Dropdown.types"
import { Error } from "@encoway/react-configurator"
import { getId } from "@fluentui/react/lib/Utilities"
import { DropdownMenuItemType } from "@fluentui/react/lib/Dropdown"

// Copied functions from CUI => therefore not testing
export function searchRegExp(searchValue?: string) {
    if (searchValue && searchValue.length > 0) {
        const escapedString = searchValue.replace(/[.+?^${}()|[\]\\]/g, "\\$&").replace(/[* ]/g, "(.*)")
        return new RegExp(`${escapedString}`, "i")
    }
}

export function determineMaxOptionsCount(max: number, limitEnabled: boolean, viewportProperty?: string) {
    let resultMax = max
    if (limitEnabled && viewportProperty) {
        const matched = viewportProperty.match(/\{([\d.]+)}/)
        if (matched && matched.length === 2) {
            const value = matched[1]
            const index = value.indexOf(".")
            const parsed = parseInt(value.substring(0, index < 0 ? value.length : index))
            if (!isNaN(parsed)) {
                resultMax = parsed
            }
        }
    }
    return resultMax
}

export function filterOptions(options?: Value[], filterRegExp?: RegExp) {
    // A) no options -> []
    // B) no filter value -> all original options
    // C) filtered options

    let filteredOptions = options
    if (options && filterRegExp) {
        filteredOptions = options.filter(option => option.translatedValue.match(filterRegExp) || option.shortText?.match(filterRegExp))
    }
    return filteredOptions || []
}

export function createDropdownOption(value: IDetailDropdownValue, unit: string, error?: Error, originalFunction?: createBasicDropdownOptionType) {
    return {
        ...(originalFunction?.(value, unit, error) || {}),
        image: value.imageUrl,
        shortText: value.shortText
    } as IDropdownOption
}

export function getOptions(getOptionsDefault: () => IDropdownOption[], maxOptionsInfo: string | null): IDropdownOption[] {
    const options = getOptionsDefault()
    if (maxOptionsInfo) {
        options.unshift({
            key: getId("option-info"),
            text: maxOptionsInfo,
            itemType: DropdownMenuItemType.Header,
            disabled: true
        })
    }
    return options
}
