import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../features/translations/TranslationKeys"
import { Typography } from "@mui/material"
import theme from "../../../App.theme"
import Dialog, { DialogProps } from "../../dialog/Dialog"
import { ButtonPropsWithLabel } from "../../dialog/dialogActions/DialogActions"
import DialogStyles from "../../dialog/Dialog.styles"

interface LockedProjectDialogProps extends Pick<DialogProps, "open"> {
    projectCanBeOpened: boolean
    lockedBy?: string
    onOpenLockedProject: () => void
    onCancelOpen: () => void
}

export default function LockedProjectDialog({ open, projectCanBeOpened, lockedBy, onOpenLockedProject, onCancelOpen }: LockedProjectDialogProps) {
    const onConfirm = projectCanBeOpened ? onOpenLockedProject : undefined

    const confirmButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.lockedDialog.submitButtonLabel,
        variant: "contained",
        onClick: onConfirm,
        sx: DialogStyles.blackButton
    }
    return (
        <Dialog
            open={open}
            onClose={onCancelOpen}
            title={L10n.format(TranslationKeys.project.lockedDialog.title)}
            message={L10n.format(TranslationKeys.project.lockedDialog.message, {
                user: lockedBy
            })}
            customDialogActions={{ actions: onConfirm ? [confirmButton] : [] }}
        >
            {projectCanBeOpened ? (
                <Typography color={theme.palette.warning.main}>{L10n.format(TranslationKeys.project.lockedDialog.unlockWarning)}</Typography>
            ) : null}
        </Dialog>
    )
}
