import { createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import prepareHeaders from "../../store/prepareHeaders"

export const FetchApi = createApi({
    reducerPath: "fetchApi",
    baseQuery: fetchBaseQuery({
        prepareHeaders: prepareHeaders
    }),
    endpoints: builder => ({
        fetch: builder.query({
            query: (args: string | FetchArgs) => args
        })
    })
})

export const { useFetchQuery, useLazyFetchQuery } = FetchApi
