import { useEffect } from "react"
import useParams from "../../router/hooks/useParams"
import useNavigate from "../../router/hooks/useNavigate"
import ConfigurationPage from "../configurationPage/ConfigurationPage"
import NavigateOptions from "../../router/constants/NavigateOptions"
import executeNavigationOptionAction from "../../router/utils/executeNavigationOptionAction"
import SalesApi from "../../features/sales/sales.api"
import { AbbLineItemProperties } from "../../features/sales/sales.types"
import { getUpdatedLineItem } from "../../features/sales/sales.api.utils"
import SalesUtils from "../../features/sales/sales.utils"
import VisualizationApi, { visualizationApiTags } from "../../features/visualization/visualization.api"

export default function OpenConfigurationPage() {
    const params = useParams()
    const navigate = useNavigate()

    const salesDocument = SalesApi.useSalesDocumentQuery().data!
    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()
    const [openConfiguration] = SalesApi.useOpenConfigurationMutation()
    const [stopConfiguration] = SalesApi.useStopConfigurationMutation()
    const [triggerSaveConfiguration] = SalesApi.useSaveConfigurationMutation()

    useEffect(() => {
        openConfiguration(params.lineItemId!)
        VisualizationApi.util?.invalidateTags([visualizationApiTags.VISUALIZATION])
        return () => {
            stopConfiguration()
        }
    }, [params.lineItemId, openConfiguration, stopConfiguration])

    const saveConfiguration = async (lineItemProperties: Partial<AbbLineItemProperties>, navigateOption: NavigateOptions) => {
        const response = await triggerSaveConfiguration().unwrap()
        const lineItem = getUpdatedLineItem(params.lineItemId!, response)
        if (lineItem && SalesUtils.lineItems.arePropertiesUpdated(lineItem.properties, lineItemProperties)) {
            await updateLineItem([params.lineItemId!, lineItemProperties]).unwrap()
        }
        executeNavigationOptionAction(navigateOption, {
            [NavigateOptions.Navigate]: () => navigate.toProjectComposition(salesDocument.salesDocumentId),
            [NavigateOptions.Stay]: () => openConfiguration(params.lineItemId!),
            [NavigateOptions.DontNavigate]: () => null
        })
    }

    const cancelConfiguration = () => {
        navigate.toProjectComposition(salesDocument.salesDocumentId)
    }

    return <ConfigurationPage onSave={saveConfiguration} onStop={cancelConfiguration} />
}
