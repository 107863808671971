import { GenericFormProps, GenericInput } from "./GenericForm"

export default function useGenericForm(
    inputs: GenericInput[],
    values: GenericFormProps["values"],
    update: GenericFormProps["update"],
    disableInput?: GenericFormProps["disableInput"]
): { isValid: boolean; numberOfInvalidInputs: number; formProps: GenericFormProps } {
    const validateInput = (input: GenericInput) => {
        return !(input.mandatory && !values[input.id]) && !(input.type === "email" && !/\S{1,255}@\S{1,255}\.\S{1,255}/.test(values[input.id] as string))
    }

    const numberOfValidInputs = inputs.filter(input => validateInput(input)).length

    return {
        isValid: numberOfValidInputs === inputs.length,
        numberOfInvalidInputs: inputs.length - numberOfValidInputs,
        formProps: { validateInput, values, update, inputs, disableInput }
    }
}
