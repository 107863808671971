import { PropsWithChildren, useCallback, useEffect, useState } from "react"
import { Http, useToken } from "@encoway/sales-showroom-auth"
import { SalesService } from "@encoway/sales-api-js-client"
import { CatalogService } from "@encoway/c-services-js-client"
import { Settings } from "../../settings"
import { useAppDispatch, useAppSelector } from "../../store/store"
import OidcSlice from "../../features/oidc/oidc.slice"
import SalesSlice from "../../features/sales/sales.slice"
import CatalogSlice from "../../features/catalog/catalog.slice"
import BusySlice from "../../features/busy/busy.slice"
import ErrorSlice from "../../features/error/error.slice"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import { useDecodedToken } from "../../features/oidc/hooks/useDecodedToken"
import { SalesRoles } from "../../features/sales/sales.constants"

const TOKEN_REFRESH_INTERVAL = 25000

const removeSearchParameters = () => {
    if (window.location.search) {
        const baseUri = window.location.protocol + "//" + window.location.host + window.location.pathname
        window.history.replaceState(null, "", baseUri)
    }
}

export const ContentWithToken = (props: PropsWithChildren<unknown>) => {
    const [servicesInitializationStarted, setServicesInitializationStarted] = useState(false)
    const token = useAppSelector(state => state.oidc.token)
    const salesService = useAppSelector(state => state.sales.salesService)
    const catalogService = useAppSelector(state => state.catalog.catalogService)
    const tokenFunction = useToken()
    const dispatch = useAppDispatch()
    const decodeToken = useDecodedToken()

    const updateToken = useCallback(() => {
        const tokenValue = tokenFunction?.()
        dispatch(OidcSlice.actions.setToken(tokenValue))
    }, [tokenFunction, dispatch])

    useEffect(() => {
        updateToken()
        const timer = setInterval(updateToken, TOKEN_REFRESH_INTERVAL)
        return () => clearInterval(timer)
    }, [updateToken])

    const initializeSalesService = useCallback(async () => {
        dispatch(BusySlice.actions.setBusy())
        try {
            const http = Http.Bearer(tokenFunction)
            const salesService = await SalesService.create(http, Settings.configuration.baseUrl)
            dispatch(SalesSlice.actions.setSalesService(salesService))
        } catch (e) {
            console.error("failed to initialize sales service", e)
            dispatch(ErrorSlice.actions.set(new Error("failed to initialize application")))
        } finally {
            dispatch(BusySlice.actions.setIdle())
        }
    }, [tokenFunction, dispatch])

    const initializeCatalogService = useCallback(() => {
        const http = Http.Bearer(tokenFunction)
        const catalogService = new CatalogService(http, Settings.configuration.baseUrl, Settings.locale)
        dispatch(CatalogSlice.actions.setCatalogService(catalogService))
    }, [tokenFunction, dispatch])

    useEffect(() => {
        if (decodeToken?.realm_access?.roles.includes(SalesRoles.QA_BASE) && !servicesInitializationStarted) {
            setServicesInitializationStarted(true)
            initializeSalesService()
            initializeCatalogService()
        }
    }, [decodeToken, servicesInitializationStarted, initializeSalesService, initializeCatalogService])

    useEffect(() => {
        if (decodeToken && !decodeToken.realm_access?.roles.includes(SalesRoles.QA_BASE)) {
            dispatch(
                ErrorSlice.actions.set({
                    activateDangerouslySetInnerHTML: true,
                    name: L10n.format(TranslationKeys.error.login.accessDenied.title),
                    message: L10n.format(TranslationKeys.error.login.accessDenied.body)
                })
            )
        }
    }, [decodeToken, dispatch])

    useEffect(removeSearchParameters, [removeSearchParameters])

    return token && salesService && catalogService ? <>{props.children}</> : null
}
