import { GridRenderCellParams } from "@mui/x-data-grid"
import ExpandButton from "../../../../../../../components/buttons/expandButton/ExpandButton"
import React, { useContext } from "react"
import { LineItemDataGridContext } from "../../LineItemsDataGrid"
import { FOOTER_ROW_ID } from "../../LineItemsDataGrid.styles"
import { Checkbox, Stack } from "@mui/material"
import { BomNode } from "../../../../../../../features/sales/sales.types"

export default function QuoteRelevantCell(params: GridRenderCellParams<BomNode>) {
    const { onToggle } = useContext(LineItemDataGridContext)!
    if (params.id === FOOTER_ROW_ID) {
        return params.value
    } else if (params.row.level === 0) {
        return (
            <Stack direction="row" justifyContent="end" alignItems="center" width="100%">
                {!params.row.item.properties.isLeaf ? <ExpandButton expanded={params.row.expanded} onToggle={() => onToggle(params.row)} /> : null}
                <Checkbox />
            </Stack>
        )
    }
    return <div />
}
