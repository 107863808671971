import theme from "../../App.theme"

const BreadcrumbsStyles = {
    link: {
        color: theme.palette.text.secondary,
        textDecoration: "none"
    },
    typography: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold
    }
}

export default BreadcrumbsStyles
