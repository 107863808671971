import { PropsWithChildren, useEffect, useMemo } from "react"

/**
 * Removes the state and scope parameter from the url. The ABB CIAM adds a state that is not expected.
 * As discussed with Sameer Nahar, this is by design and we should ignore the added state by the ABB CIAM.
 * @param props the props with children.
 */
export const RemoveState = (props: PropsWithChildren<unknown>) => {
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [])
    const hasState = !!urlParams.get("state")
    const removeState = () => {
        if (hasState) {
            const url = new URL(window.location.protocol + "//" + window.location.host + window.location.pathname)
            urlParams.forEach((value, key) => key !== "state" && key !== "scope" && key !== "nonce" && url.searchParams.append(key, value))
            window.location.href = url.protocol + "//" + url.host + url.pathname + url.search
        }
    }
    useEffect(removeState, [removeState])
    return <>{!hasState && props.children}</>
}
