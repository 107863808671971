import { GridRenderCellParams } from "@mui/x-data-grid"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { BomNode } from "../../../../../../../features/sales/sales.types"

export default function getPlusCodeCellValue(params: GridRenderCellParams<BomNode>) {
    const typeCode = params.row.item.properties.TYPE_CODE
    if (typeCode != null) {
        return typeCode.startsWith("+") ? L10n.format(TranslationKeys.lineItem.typeCodePlaceholder) + typeCode : typeCode
    } else if (!params.row.item.properties.PLUSCODE) {
        return params.row.item.properties.shortText
    } else {
        return params.row.item.properties.PLUSCODE.startsWith("+") ? params.row.item.properties.PLUSCODE : "+" + params.row.item.properties.PLUSCODE
    }
}
