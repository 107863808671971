import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import BusySlice from "../busy/busy.slice"
import readError from "../error/utils/readError"
import ErrorSlice from "../error/error.slice"
import VisualizationApi from "./visualization.api"
import { IAppSettings } from "@encoway/cui-application-components"
import { VisualizationResponse } from "./visualization.api.types"
import CatalogApi from "../catalog/catalog.api"
import CatalogUtils from "../catalog/catalog.utils"
import { CharacteristicIds } from "../catalog/catalog.constants"
import { FetchApi } from "../fetch/fetch.api"

export async function wrapVisualizationApiQuery<T>(api: BaseQueryApi, query: () => Promise<T>, busyText?: string) {
    try {
        api.dispatch(BusySlice.actions.setBusy(busyText))
        const result = await query()
        return { data: result }
    } catch (e) {
        const error = await readError(e)
        api.dispatch(ErrorSlice.actions.set(error))
        return { error }
    } finally {
        api.dispatch(BusySlice.actions.setIdle(busyText))
    }
}

export async function getVisualization(api: BaseQueryApi, configurationSettings: IAppSettings): Promise<VisualizationResponse> {
    const visualizationQuery = api.dispatch(VisualizationApi.endpoints.visualization.initiate(configurationSettings))
    visualizationQuery.unsubscribe()
    return (await visualizationQuery.unwrap())!
}

export async function getProductSettings(api: BaseQueryApi, productId: string) {
    const productQuery = api.dispatch(CatalogApi.endpoints.product.initiate(productId))
    productQuery.unsubscribe()
    const product = await productQuery.unwrap()
    const mediaUri = CatalogUtils.getMediaUri(product.product, CharacteristicIds.VisualizationSettings)
    let settings = {}
    if (mediaUri) {
        const mediaQuery = api.dispatch(FetchApi.endpoints.fetch.initiate(mediaUri))
        mediaQuery.unsubscribe()
        settings = await mediaQuery.unwrap()
    }
    return {
        product,
        settings
    }
}
