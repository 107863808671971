import { L10n } from "@encoway/l10n"
import { Stack } from "@mui/material"
import { GridActionsCellItem, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid"
import { ContentCopy, DeleteOutline, LockPerson, ModeEdit } from "@mui/icons-material"
import ProjectsDataGridColumns from "./ProjectsDataGridColumns"
import DataGrid, { DataGridProps } from "../../../../components/dataGrid/DataGrid"
import Icon from "../../../../components/icons/Icon"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { AbbSalesDocumentEntity } from "../../../../features/sales/sales.types"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import SalesUtils from "../../../../features/sales/sales.utils"
import ProjectsDataGridStyles from "./ProjectsDataGrid.styles"

interface ProjectDataGridProps extends Omit<DataGridProps, "columns"> {
    rows: AbbSalesDocumentEntity[]
    onSelectProject: (salesDocument: AbbSalesDocumentEntity) => void
    onDeleteProject: (salesDocument: AbbSalesDocumentEntity) => void
    onCopyProject: (salesDocumentId: string) => any
}

export default function ProjectsDataGrid({ onSelectProject, onDeleteProject, onCopyProject, ...dataGridProps }: ProjectDataGridProps) {
    const renderLockCell = (params: GridRenderCellParams<AbbSalesDocumentEntity>) => {
        return params.row.properties.quote_locked_by ? <LockPerson /> : null
    }

    const renderStatusCell = (params: GridRenderCellParams<AbbSalesDocumentEntity>) => {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Icon
                    component={SalesUtils.salesDocument.getStatusIcon(params.row.properties.quote_status)}
                    color={SalesUtils.salesDocument.getStatusColor(params.row.properties.quote_status)}
                />
                <span>{SalesUtils.salesDocument.getStatusTranslation(params.row.properties.quote_status)}</span>
            </Stack>
        )
    }

    const getActions = (params: GridRowParams<AbbSalesDocumentEntity>) => [
        <GridActionsCellItem
            key="editAction"
            icon={<ModeEdit />}
            label={L10n.format(TranslationKeys.project.actions.open)}
            onClick={() => onSelectProject(params.row)}
            showInMenu
        />,
        <GridActionsCellItem
            key="copyAction"
            icon={<ContentCopy />}
            label={L10n.format(TranslationKeys.project.actions.duplicate)}
            onClick={() => onCopyProject(params.row.salesDocumentId)}
            showInMenu
        />,
        <GridActionsCellItem
            key="deleteAction"
            icon={<DeleteOutline />}
            label={L10n.format(TranslationKeys.project.actions.delete)}
            onClick={() => onDeleteProject(params.row)}
            showInMenu
        />
    ]

    return (
        <DataGrid
            getRowId={params => params.salesDocumentId}
            columns={ProjectsDataGridColumns(renderLockCell, renderStatusCell, getActions)}
            filterMode="server"
            paginationMode="server"
            sx={ProjectsDataGridStyles}
            sortingMode="server"
            onRowClick={params => onSelectProject(params.row)}
            noRowsLabel={L10n.format(TranslationKeys.pages.projects.noProjectsFound)}
            noResultsOverlayLabel={L10n.format(TranslationKeys.pages.projects.noProjectResults)}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        [SalesDocumentProperties.CREATED_AT]: false,
                        [SalesDocumentProperties.MODIFIED_BY]: false
                    }
                }
            }}
            {...dataGridProps}
        />
    )
}
