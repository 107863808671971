import { L10n } from "@encoway/l10n"
import { Settings } from "../../settings"
import { useLazyFetchQuery } from "../fetch/fetch.api"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { useCallback, useEffect, useState } from "react"
import { translations as cuiApplicationTranslations } from "@encoway/cui-application-components"
import { translations as cuiConfiguratorTranslations } from "@encoway/cui-configurator-components"
import { merge } from "lodash"
import CatalogUtils from "../catalog/catalog.utils"
import BusySlice from "../busy/busy.slice"
import { CharacteristicIds } from "../catalog/catalog.constants"

export default function useTranslations() {
    const [language, setLanguage] = useState<string>(Settings.locale)
    const [translationsLoaded, setTranslationsLoaded] = useState<boolean>(false)
    const catalogService = useAppSelector(state => state.catalog.catalogService)
    const [triggerFetchQuery] = useLazyFetchQuery()
    const dispatch = useAppDispatch()

    const initializeL10n = useCallback(
        async (newLanguage: string) => {
            L10n.currentLocale(newLanguage)
            const response = await triggerFetchQuery({ url: "/api/catalog/products/application_texts" })
            const mediaUri = CatalogUtils.getMediaUri(response.data.product, CharacteristicIds.Translations)!
            const result = await triggerFetchQuery({ url: mediaUri })
            L10n.source("ui", { [newLanguage]: flattenTranslationObject(result.data) }, true)
            await L10n.reloadResources(newLanguage)
        },
        [triggerFetchQuery]
    )

    const changeLanguage = useCallback(
        async (newLanguage: string) => {
            dispatch(BusySlice.actions.setBusy())
            setTranslationsLoaded(false)
            setLanguage(newLanguage)
            Settings.locale = newLanguage
            await initializeL10n(newLanguage)
            setTranslationsLoaded(true)
            dispatch(BusySlice.actions.setIdle())
        },
        [dispatch, initializeL10n]
    )

    useEffect(() => {
        Settings.translations = merge(cuiConfiguratorTranslations, cuiApplicationTranslations)
        changeLanguage(Settings.locale)
    }, [changeLanguage])

    useEffect(() => {
        if (catalogService) {
            catalogService.useLanguage(language)
        }
    }, [catalogService, language])

    return { translationsLoaded, language, changeLanguage: changeLanguage }
}

function flattenTranslationObject(translationObject: Object) {
    let result: Record<string, string> = {}
    transformTranslationObject("", translationObject, result)
    return result
}

function transformTranslationObject(objKey: string, translationObject: Object | string, objectTo: Record<string, string>) {
    Object.entries(translationObject).forEach(([key, value]) => {
        if (typeof value === "string") {
            objectTo[objKey + key] = value
        } else if (value !== null && typeof value === "object") {
            transformTranslationObject(objKey + key + ".", value, objectTo)
        }
    })
}
