import dimensioningApi from "../../../../../../features/dimensioning/dimensioning.api"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import { Button } from "@mui/material"
import DownloadFileService from "../../../../../../features/download/DownloadFileService"

/**
 * Just a simple button for debugging. WILL BE REMOVED AFTER CALCULATION HAS BEEN FINISHED. JUST FOR TESTING!!!
 */
export const AbbDimensioningMv = (props: InputFieldProps) => {
    const [triggerLazyDimensioningMvQuery, { isFetching }] = dimensioningApi.useLazyDimensioningMvQuery()
    const onTriggerDimensioning = async () => {
        const result = await triggerLazyDimensioningMvQuery(
            {
                configurationId: (props.options as any).appSettings.configuration.options.configurationId
            },
            false
        )
        const json = JSON.stringify(result.data, undefined, 2)
        DownloadFileService.downloadFile(DownloadFileService.jsonToBlob(json), "MV_Dimensioning.json")
    }
    return (
        <>
            <Button variant="contained" size="large" onClick={onTriggerDimensioning} disabled={isFetching}>
                TEST MV DIMENSIONING
            </Button>
        </>
    )
}
