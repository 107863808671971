import { Product } from "@encoway/c-services-js-client"
import { Button, Card, Stack, Typography } from "@mui/material"
import { Add, Settings } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import ProductCardStyles from "./ProductCard.styles"
import ABBLogo from "../../../../../assets/logo.svg"
import useTextBox from "../../../../../components/textBox/useTextBox"
import TextBox from "../../../../../components/textBox/TextBox"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import Image from "../../../../../components/image/Image"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { CharacteristicIds } from "../../../../../features/catalog/catalog.constants"
import { NewTabLink } from "../../../../../components/links/newTabLink/NewTabLink"

interface ProductCardProps {
    product: Product
    onConfigureClick: (product: Product) => void
    onAddToCompositionClick: (product: Product) => void
    disabled?: boolean
}

const ProductCard = ({ product, onConfigureClick, onAddToCompositionClick, disabled = false }: ProductCardProps) => {
    const textBox = useTextBox()
    const catalogLink = CatalogUtils.getCharacteristicValue<string>(product, CharacteristicIds.CatalogLink)
    const configurableProductReferenceModel = CatalogUtils.getCharacteristicValue<string>(product, CharacteristicIds.ConfigurableProductReferenceModel)
    const sx = ProductCardStyles(product.configurable || !!configurableProductReferenceModel, disabled)
    return (
        <Card data-cy={`ProductCard.Card.${product.id}`} component={Stack} spacing={2} sx={sx.card}>
            <Stack spacing={2} sx={sx.stack}>
                <Image
                    src={CatalogUtils.getMediaUri(product, CharacteristicIds.ProductImage)}
                    fallbackImageProps={{ src: ABBLogo, alt: "image of " + product.name, style: sx.productImage }}
                />
                <Typography data-cy={"Typography:ProductCard"} sx={sx.PRODUCT_NAME}>
                    {product.name}
                </Typography>
            </Stack>

            <TextBox
                text={CatalogUtils.getCharacteristicValue<string>(product, CharacteristicIds.ShortText) || ""}
                sx={sx.description}
                expand={textBox.isExpanded}
                onExpansionChange={textBox.setIsExpanded}
                characterLimit={100}
                buttonProps={{ sx: sx.expandDescriptionButton }}
            />

            <Stack spacing={2} sx={sx.stack}>
                <NewTabLink text={L10n.format(TranslationKeys.pages.project.catalog.link)} href={catalogLink} disabled={disabled} />
                <Button
                    data-cy={"OpenConfigureButton"}
                    variant="contained"
                    size="small"
                    disabled={disabled}
                    startIcon={<Settings sx={sx.configureButtonIcon} />}
                    onClick={() => onConfigureClick(product)}
                    sx={sx.configureButton}
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.productSelection.configureButtonLabel)}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={disabled}
                    startIcon={<Add />}
                    onClick={() => onAddToCompositionClick(product)}
                    sx={sx.addCompositionButton}
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.productSelection.addToCompositionButtonLabel)}
                </Button>
            </Stack>
        </Card>
    )
}

export default ProductCard
