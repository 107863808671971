import { Icon as MuiIcon, IconProps as MuiIconProps } from "@mui/material"
import Tooltip, { TooltipProps } from "../tooltip/Tooltip"
import { ElementType } from "react"

export interface IconProps extends MuiIconProps {
    component: ElementType
    tooltipProps?: TooltipProps
}

export default function Icon({ tooltipProps, ...iconProps }: IconProps) {
    const actualTooltipProps = tooltipProps ?? { content: "", disabled: true }
    return (
        <Tooltip {...actualTooltipProps}>
            <MuiIcon {...iconProps} />
        </Tooltip>
    )
}
