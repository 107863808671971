const SnackbarStackStyles = {
    position: "sticky",
    top: 100,
    height: 0,
    alignItems: "end",
    marginRight: 3,
    zIndex: 100
}

export default SnackbarStackStyles
