import { getTheme } from "@fluentui/react"

const theme = getTheme()

const thBorderBottom = `2px solid ${theme.palette.themeLighterAlt}`
const th = {
    padding: "6px",
    textAlign: "left",
    borderBottom: thBorderBottom
}

const thrBorderBottom = `1px solid ${theme.palette.themeLighterAlt}`
const thr = {
    padding: "6px",
    textAlign: "left",
    borderBottom: thrBorderBottom
}

const sticky = {
    background: theme.palette.white,
    position: "sticky",
    left: 0,
    zIndex: 1
}

export const TableStyles = {
    box: {
        overflowX: "auto",
        overflowY: "hidden"
    },
    th: th,
    thSticky: {
        ...th,
        ...sticky,
        borderBottom: thBorderBottom
    },
    thr: thr,
    thrSticky: {
        ...thr,
        ...sticky,
        borderBottom: thrBorderBottom
    },
    td: {
        padding: "6px",
        textAlign: "left",
        borderBottom: `1px solid ${theme.palette.themeLighterAlt}`
    },
    table: {
        borderSpacing: "0px",
        borderCollapse: "collapse",
        marginTop: "24px",
        width: "100%"
    }
}
