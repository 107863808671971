import { BorderLayoutContent, Orientation } from "@encoway/cui-application-components"

const CuiProperties = {
    LAYOUT: [
        { name: BorderLayoutContent.Header, position: Orientation.North },
        { name: BorderLayoutContent.Configuration, position: Orientation.Center },
        { name: BorderLayoutContent.VisualEditor, position: Orientation.South }
    ],
    BORDER_STYLES: {
        center: { overflow: "hidden" },
        west: { width: 0 }
    }
}

export default CuiProperties
