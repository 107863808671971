import { GenericInput } from "../../../../../components/forms/genericForm/GenericForm"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { LineItemProperties } from "../../../../../features/sales/sales.constants"

export default function useNewArticleFormInputs(): GenericInput[] {
    return [
        {
            id: LineItemProperties.PRODUCT_NAME,
            label: L10n.format(TranslationKeys.lineItem.properties.productName),
            mandatory: true,
            maxLength: 50
        },
        {
            id: LineItemProperties.SHORT_TEXT,
            label: L10n.format(TranslationKeys.lineItem.properties.shortText),
            mandatory: true,
            maxLength: 50
        },
        {
            id: LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY,
            label: L10n.format(TranslationKeys.lineItem.properties.plainListPrice),
            mandatory: true,
            maxLength: 50,
            type: "number"
        },
        {
            id: LineItemProperties.PLAIN_TRANSFER_PRICE,
            label: L10n.format(TranslationKeys.lineItem.properties.plainTransferPrice),
            mandatory: true,
            maxLength: 50,
            type: "number"
        }
    ]
}
