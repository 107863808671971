import {
    Aggregation,
    Characteristic,
    Characteristics,
    Filter,
    MediaValue,
    NumberValue,
    Product,
    ProductGroup,
    Search,
    Selection,
    SelectProductsResult
} from "@encoway/c-services-js-client"
import getAppPath from "../../router/utils/getAppPath"
import compareStrings from "../../utils/compareStrings"
import { CharacteristicIds } from "./catalog.constants"
import { cloneDeep } from "lodash"

const searchCharacteristics = [CharacteristicIds.ShortText]

type CharacteristicValue = string | NumberValue | MediaValue | undefined
type CatalogEntity = Product | ProductGroup | Characteristic

class CatalogUtils {
    static getCharacteristicValue<T extends CharacteristicValue>(catalogEntity: CatalogEntity, characteristicId: string) {
        return catalogEntity.characteristicValues[characteristicId]?.values.at(0) as T | undefined
    }

    static getMediaUri(catalogEntity: CatalogEntity, characteristicId: string) {
        const mediaValue = this.getCharacteristicValue<MediaValue>(catalogEntity, characteristicId)
        return process.env.REACT_APP_DEVELOPMENT ? mediaValue?.uri?.replace(/^.*\/api\//, getAppPath() + "/api/") : mediaValue?.uri
    }

    static getPossibleValues(characteristic: Characteristic, sortStringValues?: boolean) {
        const possibleValues = Object.values(characteristic.possibleValues || {})
        return sortStringValues ? possibleValues.sort((pv1, pv2) => compareStrings(pv1.value as string, pv2.value as string)) : possibleValues
    }

    static sortCharacteristicsByView(selectProductsResult: SelectProductsResult, viewId: string) {
        const characteristicView = selectProductsResult.characteristicViews[viewId]
        if (characteristicView) {
            const clone = cloneDeep(selectProductsResult)
            clone.characteristics.sort((a, b) => characteristicView.indexOf(a.id) - characteristicView.indexOf(b.id))
            return clone
        } else {
            return selectProductsResult
        }
    }

    static getProductSelection(
        productGroupId: string,
        characteristicFilters: Record<string, any> = {},
        characteristicViewId?: string,
        aggregationViewId?: string,
        searchValue?: string,
        includeSubGroups = false
    ) {
        const selection = new Selection()
            .limit(1000)
            .filter(this.getFilter(productGroupId, characteristicFilters, includeSubGroups))
            .characteristics(characteristicViewId ? new Characteristics().viewId(characteristicViewId) : new Characteristics().all())

        if (aggregationViewId) selection.aggregation(new Aggregation().viewId(aggregationViewId))
        if (searchValue) selection.search(new Search().term(`*${searchValue}*`).idSearch(true).characteristic(searchCharacteristics))

        return selection
    }

    static joinNameAndUnit(characteristic?: Characteristic) {
        if (characteristic) {
            const unitPart = characteristic.unitOfMeasure ? " (" + characteristic.unitOfMeasure.unitSymbol + ")" : ""
            return characteristic.name + unitPart
        }
        return ""
    }

    private static getFilter(productGroupId: string, characteristicFilters: Record<string, any>, includeSubGroups: boolean) {
        const productGroupFilter = Filter.productGroupFilter().id(productGroupId).includeSubgroups(includeSubGroups)
        const filter = Filter.boolFilter().must(productGroupFilter)
        Object.entries(characteristicFilters).forEach(([characteristicId, value]) =>
            filter.must(Filter.characteristicFilter().id(characteristicId).equal(value))
        )
        return filter
    }
}

export default CatalogUtils
