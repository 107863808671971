import { Stack } from "@mui/material"
import useProjectForms from "../../hooks/useProjectForms/useProjectForms"
import ProjectForm from "./projectForm/ProjectForm"
import SalesApi from "../../../../features/sales/sales.api"
import { AbbSalesDocumentProperty } from "../../../../features/sales/sales.types"

export default function ProjectDetails() {
    const [updateSalesDocument] = SalesApi.useUpdateSalesDocumentMutation()
    const salesDocument = SalesApi.useSalesDocumentQuery().data!

    const updateSalesDocumentProperty = (property: AbbSalesDocumentProperty, value: string | number) => {
        updateSalesDocument([{ [property]: value }, {}])
    }

    const { forms } = useProjectForms(salesDocument, updateSalesDocumentProperty)

    return (
        <Stack spacing={3}>
            {forms.map(form => (
                <ProjectForm key={form.title} {...form} />
            ))}
        </Stack>
    )
}
