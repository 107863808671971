import { ReactNode } from "react"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { Characteristic, Product } from "@encoway/c-services-js-client"
import { GridValueGetterParams } from "@mui/x-data-grid/models/params/gridCellParams"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"

function catalogColumns(characteristics: Characteristic[]): GridColDef[] {
    return characteristics.map(characteristic => ({
        field: characteristic.id,
        headerName: characteristic.name,
        flex: characteristics.indexOf(characteristic) === characteristics.length - 1 ? 2 : 0,
        valueGetter: (params: GridValueGetterParams<Product>) => CatalogUtils.getCharacteristicValue(params.row, params.field),
        minWidth: 200
    }))
}

const ProductQuickSelectionColumns = (
    renderQuantityCell: (params: GridRenderCellParams<Product>) => ReactNode,
    renderInfoCell: (params: GridRenderCellParams<Product>) => ReactNode,
    characteristics: Characteristic[]
): GridColDef[] => [
    {
        field: "quantity",
        headerName: "QUANTITY",
        width: 100,
        renderCell: renderQuantityCell
    },
    {
        field: "name",
        headerName: "NAME",
        width: 200
    },
    ...catalogColumns(characteristics),
    {
        field: "info",
        headerName: "",
        width: 10,
        renderCell: renderInfoCell
    }
]

export default ProductQuickSelectionColumns
