import { Box, Grid, Typography } from "@mui/material"
import { Product } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import ProductCard from "../productCard/ProductCard"
import useNavigate from "../../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { useSearchParams } from "react-router-dom"
import { SEARCH_PARAM } from "../ProductSelection"
import SalesApi from "../../../../../features/sales/sales.api"
import CatalogApi from "../../../../../features/catalog/catalog.api"
import { CharacteristicIds, ProductGroupIds, ViewIds } from "../../../../../features/catalog/catalog.constants"

interface ProductCatalogProps {
    products: Product[]
    characteristicFilters: Record<string, any>
}

export default function ProductStandardSelection({ products, characteristicFilters }: ProductCatalogProps) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const searchValue = searchParams.get(SEARCH_PARAM) ?? undefined
    const salesDocument = SalesApi.useSalesDocumentQuery().data!
    const [addProduct] = SalesApi.useAddProductMutation()
    const productCategory = salesDocument.properties.PRODUCT_CATEGORY
    const noProductsFoundWithSearch = !!searchValue && products.length === 0

    const extendedSearchProductSelection = CatalogUtils.getProductSelection(
        ProductGroupIds.Catalog,
        characteristicFilters,
        undefined,
        ViewIds.SALES_FILTER,
        searchValue,
        true
    )

    const productsOfExtendedSearch = CatalogApi.useProductsQuery(extendedSearchProductSelection, { skip: !noProductsFoundWithSearch }).data?.products ?? []

    return (
        <>
            {noProductsFoundWithSearch && (
                <Typography>
                    {L10n.format(
                        productsOfExtendedSearch.length > 0
                            ? TranslationKeys.pages.project.catalog.searchResultsOfCatalog
                            : TranslationKeys.pages.project.catalog.noSearchResultsMessage
                    )}
                </Typography>
            )}

            <Box>
                <Grid container spacing={4}>
                    {(noProductsFoundWithSearch ? productsOfExtendedSearch : products).map(product => {
                        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(product, CharacteristicIds.ProductCategory)
                        return (
                            <Grid item key={product.id}>
                                <ProductCard
                                    product={product}
                                    onConfigureClick={product => navigate.toNewConfiguration(salesDocument.salesDocumentId, product.id)}
                                    onAddToCompositionClick={product => addProduct(product.id)}
                                    disabled={!!productCategory && !!characteristicValue && productCategory !== characteristicValue}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </>
    )
}
