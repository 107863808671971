import theme from "../../../../../../App.theme"

const ProductQuickSelectionDataGridStyles = {
    ".MuiDataGrid-columnSeparator": {
        display: "none"
    },
    "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "transparent"
    },
    "& .quickSelectionBackground": {
        backgroundColor: theme.palette.background.default
    },
    ".MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal",
        lineHeight: "normal",
        textTransform: "uppercase"
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "transparent"
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
        {
            outline: "none"
        }
}

export default ProductQuickSelectionDataGridStyles
