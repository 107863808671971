import { GridRenderCellParams } from "@mui/x-data-grid"
import { Stack } from "@mui/material"
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import { DcsDimensioningResultEntry } from "../../../../../../features/dimensioning/dimensioning.types"
import AbbDimensioningDcStyles from "./AbbDimensioningDc.styles"
import { AbbDimensioningDcsDataGrid } from "./dcs/AbbDimensioningDcsDataGrid"
import { AbbDimensioningDctDataGrid } from "./dct/AbbDimensioningDctDataGrid"
import { DcType, determineDcType } from "./AbbDimensioningDc.utils"

export default function AbbDimensioningDc(props: InputFieldProps) {
    const dcType: DcType = determineDcType(props.data.name)
    const selectedProductId = props.data.selectedValues?.at(0)?.value
    const configurationId = (props.options as any).appSettings.configuration.options.configurationId
    const parameterId = props.data.id

    const renderProductNameCell = (params: GridRenderCellParams<DcsDimensioningResultEntry>) => {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                {params.row.id === selectedProductId ? (
                    <RadioButtonChecked />
                ) : (
                    <RadioButtonUnchecked sx={AbbDimensioningDcStyles.radioButton} onClick={() => props.onValueChanged(props.data, params.row.id)} />
                )}
                <span>{params.value}</span>
            </Stack>
        )
    }

    return (
        <Stack spacing={1} sx={AbbDimensioningDcStyles.stack}>
            {dcType === DcType.DCS ? (
                <AbbDimensioningDcsDataGrid
                    configurationId={configurationId}
                    parameterId={parameterId}
                    renderProductNameCell={renderProductNameCell}
                    dataGridProps={{
                        hideFooter: true,
                        sx: AbbDimensioningDcStyles.dataGrid
                    }}
                />
            ) : (
                <AbbDimensioningDctDataGrid
                    configurationId={configurationId}
                    parameterId={parameterId}
                    renderProductNameCell={renderProductNameCell}
                    dataGridProps={{
                        hideFooter: true,
                        sx: AbbDimensioningDcStyles.dataGrid
                    }}
                />
            )}
        </Stack>
    )
}
