import { createContext, PropsWithChildren, useMemo } from "react"
import useTranslations from "./useTranslations"

interface ITranslationsContext {
    language: string
    changeLanguage: (language: string) => void
}

export const TranslationsContext = createContext<ITranslationsContext | undefined>(undefined)

export default function TranslationsProvider(props: PropsWithChildren<unknown>) {
    const { translationsLoaded, language, changeLanguage } = useTranslations()
    const value = useMemo(() => ({ language, changeLanguage }), [language, changeLanguage])
    return translationsLoaded ? <TranslationsContext.Provider value={value}>{props.children}</TranslationsContext.Provider> : null
}
