import jwt_decode from "jwt-decode"
import { useAppSelector } from "../../../store/store"
import { useMemo } from "react"

export type DecodedToken = {
    name?: string
    username?: string
    realm_access?: {
        roles: string[]
    }
}

export const useDecodedToken = (): DecodedToken | undefined => {
    const token = useAppSelector(state => state.oidc.token)

    return useMemo((): DecodedToken | undefined => {
        if (token) {
            const decodedToken: any = jwt_decode(token)
            return { ...decodedToken, username: decodedToken.username || decodedToken.preferred_username }
        }
    }, [token])
}
