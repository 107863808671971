import { createBrowserRouter, redirect } from "react-router-dom"
import RootPage from "../../pages/rootPage/RootPage"
import ProjectsPage from "../../pages/projectsPage/ProjectsPage"
import ProjectPage from "../../pages/projectPage/ProjectPage"
import DashboardPage from "../../pages/dashboardPage/DashboardPage"
import ProjectDetails from "../../pages/projectPage/components/projectDetails/ProjectDetails"
import ProductSelection from "../../pages/projectPage/components/productSelection/ProductSelection"
import ProjectComposition from "../../pages/projectPage/components/projectComposition/ProjectComposition"
import ProjectReports from "../../pages/projectPage/components/projectReports/ProjectReports"
import getBasename from "../utils/getBasename"
import RouterPaths from "./RouterPaths"
import CreateConfigurationPage from "../../pages/configurationPage/CreateConfigurationPage"
import OpenConfigurationPage from "../../pages/configurationPage/OpenConfigurationPage"

function redirectToDashboard() {
    return redirect(RouterPaths.DASHBOARD)
}

const router = createBrowserRouter(
    [
        {
            path: RouterPaths.INDEX,
            loader: redirectToDashboard
        },
        {
            path: RouterPaths.ROOT_PAGE,
            Component: RootPage,
            children: [
                {
                    index: true,
                    loader: redirectToDashboard
                },
                {
                    path: RouterPaths.DASHBOARD,
                    Component: DashboardPage
                },
                {
                    path: RouterPaths.PROJECTS,
                    Component: ProjectsPage
                },
                {
                    path: RouterPaths.PROJECT,
                    Component: ProjectPage,
                    children: [
                        {
                            path: RouterPaths.PROJECT_DETAILS,
                            Component: ProjectDetails
                        },
                        {
                            path: RouterPaths.PROJECT_CATALOG,
                            Component: ProductSelection
                        },
                        {
                            path: RouterPaths.PROJECT_COMPOSITION,
                            Component: ProjectComposition
                        },
                        {
                            path: RouterPaths.PROJECT_REPORTS,
                            Component: ProjectReports
                        },
                        {
                            path: RouterPaths.NEW_CONFIGURATION,
                            Component: CreateConfigurationPage
                        },
                        {
                            path: RouterPaths.CONFIGURATION,
                            Component: OpenConfigurationPage
                        }
                    ]
                }
            ]
        }
    ],
    {
        basename: getBasename()
    }
)

export default router
