export const CuiViewPorts = {
    FilterDetailDropdown: "filterdetaildropdown",
    Graph: "Graph",
    MatlabButton: "matlabButton",
    MatlabWarning: "matlabWarning",
    MultilineDisplay: "multilineDisplay",
    Table: "Table",
    Notification: "notification",
    Visualization: "visPreview",
    Dimensioning: "dimensioning",
    DimensioningMv: "dimensioningMv"
} as const
