const UserStyles = {
    wrapper: {
        height: {
            xs: "22px",
            sm: "33px"
        },
        mr: 0,
        display: "flex",
        alignItems: "center"
    },
    text: {
        display: {
            xs: "none",
            sm: "block"
        },
        mr: 2
    },
    textWelcome: {
        display: {
            xs: "none",
            md: "block"
        }
    }
}

export default UserStyles
