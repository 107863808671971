import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { IDetailDropdownStyles } from "@encoway/cui-configurator-components/src/components/DetailDropdown/DetailDropdown.types"
import { IDetailDropdownOption } from "../FilterDetailDropdown.types"

const getShortTextClass = (dropdownOption: IDetailDropdownOption, classNames: IProcessedStyleSet<IDetailDropdownStyles>) => {
    let shortTextClass = classNames.shortTextContainer
    if (!dropdownOption.selected && !dropdownOption.selectable) {
        shortTextClass = mergeStyles(shortTextClass, classNames.shortTextContainerNotSelected)
    }

    return shortTextClass
}

const DetailDropdownShortTextStyles = {
    getShortTextClass: (dropdownOption: IDetailDropdownOption, classNames: IProcessedStyleSet<IDetailDropdownStyles>) => {
        return getShortTextClass(dropdownOption, classNames)
    }
}

export default DetailDropdownShortTextStyles
