import theme from "../../App.theme"
import { TabsStyles } from "../../components/tabs/Tabs"

const ProjectPageStyles = {
    tabStyles: {
        tabs: {
            minHeight: 0,
            "& .MuiTabs-indicator": {
                backgroundColor: "transparent"
            }
        },
        tab: (tab: number, selectedTab: number) => ({
            display: "block",
            minHeight: 0,
            minWidth: "8px",
            padding: 0,
            backgroundColor: tab === selectedTab ? theme.palette.background.default : theme.palette.common.white
        }),
        tabLabel: (tab: number, selectedTab: number) => ({
            backgroundColor: selectedTab === tab ? theme.palette.common.white : theme.palette.background.default,
            borderRadius: tab === selectedTab ? "8px 8px 0 0" : 0,
            borderBottomLeftRadius: tab === selectedTab + 1 ? "8px" : 0,
            borderBottomRightRadius: tab === selectedTab - 1 ? "8px" : 0,
            color: tab === selectedTab ? theme.palette.text.primary : theme.palette.text.secondary,
            fontWeight: tab === selectedTab ? theme.typography.fontWeightBold?.toString() || "inherit" : "inherit",
            textTransform: "none",
            height: "100%",
            padding: 1,
            "& .MuiSvgIcon-root": {
                fontSize: 14
            }
        }),
        contentBox: (selectedTab: number) => ({
            backgroundColor: theme.palette.background.paper,
            padding: 3,
            borderRadius: (selectedTab === 0 ? 0 : 8) + "px 8px 8px 8px"
        })
    } as TabsStyles,
    badge: {
        ".MuiBadge-badge": { transform: "none", position: "inherit" }
    },
    container: {
        marginTop: 3
    },
    projectNameContainer: {
        width: "100%"
    },
    projectName: {
        flexShrink: 0
    },
    editButton: {
        color: theme.palette.text.primary,
        textDecoration: "underline",
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightBold
    }
}

export default ProjectPageStyles
