import { useEffect, useMemo, useState } from "react"
import { Alert, Box, Grid, Stack, Typography } from "@mui/material"
import { Checklist, ChevronRight, EditNote, Error } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import { NumberValue } from "@encoway/c-services-js-client"
import ProductSelectionStyles from "./ProductSelection.styles"
import ProductGroupCard, { ProductTileSize } from "./productGroupCard/ProductGroupCard"
import ProductStandardSelection from "./productStandardSelection/ProductStandardSelection"
import ProductQuickSelection from "./productQuickSelection/ProductQuickSelection"
import useNavigate from "../../../../router/hooks/useNavigate"
import useParams from "../../../../router/hooks/useParams"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Breadcrumbs, { Breadcrumb } from "../../../../components/breadcrumbs/Breadcrumbs"
import SearchInput from "../../../../components/input/searchInput/SearchInput"
import Tabs from "../../../../components/tabs/Tabs"
import { generatePath, useSearchParams } from "react-router-dom"
import RouterPaths from "../../../../router/constants/RouterPaths"
import ProductFilters from "./productFilters/ProductFilters"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import CatalogApi from "../../../../features/catalog/catalog.api"
import { CharacteristicIds, ProductGroupIds, ViewIds } from "../../../../features/catalog/catalog.constants"
import SalesApi from "../../../../features/sales/sales.api"

export const SEARCH_PARAM = "search"

export default function ProductSelection() {
    const [selectedTab, setSelectedTab] = useState(0)
    const [characteristicFilters, setCharacteristicFilters] = useState<Record<string, any>>({})

    const navigate = useNavigate()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchValue = searchParams.get(SEARCH_PARAM) ?? undefined

    const productGroupIds = useMemo(() => params.productGroupIds ?? [ProductGroupIds.Catalog], [params])
    const productGroups = CatalogApi.useProductGroupsQuery(productGroupIds).data ?? []
    const currentProductGroupId = productGroupIds.at(-1)!
    const subGroupsQuery = CatalogApi.useSubGroupsQuery(currentProductGroupId)
    const productGroup = productGroups.find(productGroup => productGroup.id === currentProductGroupId)
    const quickSelectionCharacteristic = productGroup
        ? CatalogUtils.getCharacteristicValue<NumberValue>(productGroup, CharacteristicIds.QuickSelectionEnabled)?.value
        : 0
    const quickSelectionIsAvailable = Boolean(quickSelectionCharacteristic)

    const productSelection = useMemo(
        () => CatalogUtils.getProductSelection(currentProductGroupId, characteristicFilters, undefined, ViewIds.SALES_FILTER, searchValue, !!searchValue),
        [currentProductGroupId, characteristicFilters, searchValue]
    )
    const productsQuery = CatalogApi.useProductsQuery(productSelection)
    const products = productsQuery.data?.products ?? []

    const salesDocument = SalesApi.useSalesDocumentQuery().data!
    const productCategory = salesDocument?.properties.PRODUCT_CATEGORY

    const tabs = [
        {
            cypressId: "tab.catalog",
            label: L10n.format(TranslationKeys.pages.project.catalog.tabs.catalog),
            icon: <Checklist />
        },
        {
            cypressId: "tab.quickSelection",
            label: L10n.format(TranslationKeys.pages.project.catalog.tabs.quickSelection),
            disabled: !quickSelectionIsAvailable,
            icon: <EditNote />
        }
    ]

    useEffect(() => {
        setCharacteristicFilters({})
    }, [currentProductGroupId])

    useEffect(() => {
        setSelectedTab(quickSelectionIsAvailable ? 1 : 0)
    }, [quickSelectionIsAvailable])

    const breadcrumbs: Breadcrumb[] = productGroups.map((group, index) => {
        const ids = productGroups.slice(0, index + 1).map(g => g.id)
        const route = generatePath(RouterPaths.PROJECT_CATALOG, { salesDocumentId: salesDocument.salesDocumentId, productGroupIds: ids.join("+") })
        return { label: group.name!, route: route }
    })

    if (searchValue) {
        breadcrumbs.push({ label: L10n.format(TranslationKeys.pages.project.catalog.searchResultBreadcrumb) })
    }

    const onUpdateFilters = (characteristicId: string, value: any) => {
        setCharacteristicFilters(prevState => {
            const newState = { ...prevState }
            if (value) {
                newState[characteristicId] = value
            } else {
                delete newState[characteristicId]
            }
            return newState
        })
    }

    return (
        <Stack spacing={3}>
            {productGroups.length > 1 ? <Breadcrumbs separator={<ChevronRight fontSize="small" color="secondary" />} breadcrumbs={breadcrumbs} /> : null}

            <SearchInput
                onSearch={searchValue => setSearchParams({ [SEARCH_PARAM]: searchValue })}
                placeholder={L10n.format(TranslationKeys.pages.project.catalog.searchInputPlaceholder)}
                keepSearchValue={!!searchValue}
            />

            {productGroups.length === 1 && productGroups.at(0)!.id === ProductGroupIds.Catalog ? (
                <Typography variant="h3">{L10n.format(TranslationKeys.pages.project.catalog.selectDriveSegmentTitle)}</Typography>
            ) : null}

            {productCategory ? (
                <Alert severity="info" icon={<Error color="warning" fontSize="small" />} sx={ProductSelectionStyles.warning}>
                    {L10n.format(TranslationKeys.pages.project.catalog.productCategoryHint)}
                </Alert>
            ) : null}

            <Box>
                <Grid container spacing={1}>
                    {subGroupsQuery.data?.productGroups.map(subGroup => {
                        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(subGroup, CharacteristicIds.ProductCategory)
                        return (
                            <Grid paddingTop={0} item key={subGroup.id}>
                                <ProductGroupCard
                                    productGroup={subGroup}
                                    onSelect={productGroup => navigate.toProductGroup(salesDocument.salesDocumentId, productGroupIds.concat([productGroup.id]))}
                                    size={productGroupIds.length > 1 ? ProductTileSize.Default : ProductTileSize.Big}
                                    disabled={!!productCategory && !!characteristicValue && productCategory !== characteristicValue}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            {productGroups.length > 1 || searchValue ? (
                <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabsStyles={ProductSelectionStyles.tabStyles} />
            ) : null}

            <ProductFilters
                productGroupId={currentProductGroupId}
                filterValues={characteristicFilters}
                onUpdateFilter={onUpdateFilters}
                aggregatedCharacteristicValues={productsQuery.data?.aggregatedCharacteristicValues ?? {}}
            />

            {selectedTab === 0 ? (
                <ProductStandardSelection products={products} characteristicFilters={characteristicFilters} />
            ) : (
                <ProductQuickSelection
                    productGroups={(productGroup ? [productGroup] : []).concat(subGroupsQuery.data?.productGroups ?? [])}
                    characteristicFilters={characteristicFilters}
                />
            )}
        </Stack>
    )
}
