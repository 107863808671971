import { ComponentPropsWithRef } from "react"
import { DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps, GRID_DEFAULT_LOCALE_TEXT, GridRow } from "@mui/x-data-grid"
import DataGridStyles from "./DataGrid.styles"
import AbbSpinner from "../../pages/configurationPage/components/cui/components/AbbSpinner/AbbSpinner"
import { deepClone } from "@mui/x-data-grid/utils/utils"
import CustomGridCell, { customGridCellInput } from "./components/customGridCell/CustomGridCell"

export interface DataGridProps extends MuiDataGridProps {
    noRowsLabel?: string
    noResultsOverlayLabel?: string
    onCellChangeCommit?: DataGridProps["onCellEditStop"]
}

const CypressGridRow = (props: ComponentPropsWithRef<typeof GridRow>) => {
    return <GridRow {...props} data-cy={"row"} />
}

export default function DataGrid({ noRowsLabel, noResultsOverlayLabel, ...props }: DataGridProps) {
    //FIXME in ABBM-543
    const onCellEditStopOverwrite: DataGridProps["onCellEditStop"] = (params, event, details) => {
        const value = customGridCellInput?.value
        if (value) {
            props.onCellEditStop?.({ ...params, value: params.colDef.type === "number" ? parseFloat(value) : value }, event, details)
        } else {
            props.onCellEditStop?.(params, event, details)
        }
    }

    return (
        <MuiDataGrid
            data-cy="DataGrid"
            pageSizeOptions={[10, 20, 50]}
            autoHeight={true}
            disableRowSelectionOnClick
            localeText={{
                ...GRID_DEFAULT_LOCALE_TEXT,
                noRowsLabel: noRowsLabel ?? GRID_DEFAULT_LOCALE_TEXT.noRowsLabel,
                noResultsOverlayLabel: noResultsOverlayLabel ?? GRID_DEFAULT_LOCALE_TEXT.noResultsOverlayLabel
            }}
            {...props}
            rows={deepClone(props.rows)}
            slots={{ loadingOverlay: AbbSpinner, row: CypressGridRow, cell: CustomGridCell, ...props.slots }}
            sx={[DataGridStyles, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
            onCellEditStop={onCellEditStopOverwrite}
        />
    )
}
