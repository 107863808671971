import useParams from "../../router/hooks/useParams"
import useConfigurationProduct from "../sales/hooks/useConfigurationProduct"
import VisualizationApi from "./visualization.api"
import useCuiAppSettings from "../../pages/configurationPage/hooks/useCuiAppSettings"

export default function useAr() {
    const id = useParams().lineItemId
    const product = useConfigurationProduct()
    const cuiAppSettings = useCuiAppSettings()
    const [updateAR, { error, isLoading }] = VisualizationApi.useLazyArQuery()

    return {
        error: typeof error === "string" ? error : error?.message,
        update: () => updateAR({ product, id, cuiAppSettings: cuiAppSettings! }).unwrap(),
        isLoading
    }
}
