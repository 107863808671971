import { registerIcons } from "@fluentui/react"
import { CompletedIcon } from "@fluentui/react-icons-mdl2"
import { StateIconProps } from "@encoway/cui-configurator-components/src/components/StateIcon/StateIcon.types"
import { StateIcon } from "@encoway/cui-configurator-components"

const icons = {
    SET_BY_EXTERNAL: "SetByExternalIcon"
}

registerIcons({
    icons: {
        [icons.SET_BY_EXTERNAL]: <CompletedIcon style={{ visibility: "hidden" }} />
    }
})

// fix for ABBM-186 (PSU-4951)
export default function AbbStateIcon(props: StateIconProps) {
    // @ts-ignore
    return <StateIcon {...props} icons={icons} />
}
