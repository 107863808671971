import { Button, Menu, MenuItem, Stack, Tooltip } from "@mui/material"
import { Lock, MoreVert } from "@mui/icons-material"
import ProjectActionsStyles from "./ProjectActions.styles"
import usePopover from "../../../../../../hooks/usePopover"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { L10n } from "@encoway/l10n"
import { AbbSalesDocumentEntity } from "../../../../../../features/sales/sales.types"

export interface ProjectActionProps {
    project: AbbSalesDocumentEntity
    onOpenProject: () => void
}

export default function ProjectActions({ project, onOpenProject }: ProjectActionProps) {
    const popover = usePopover()
    return (
        <Stack direction="row" justifyContent="end" alignItems="center">
            {project.properties.quote_locked_by ? (
                <Tooltip arrow title={L10n.format(TranslationKeys.project.lockedDialog.message, { user: project.properties.quote_locked_by })}>
                    <Button sx={ProjectActionsStyles.button} onClick={event => event.stopPropagation()}>
                        <Lock />
                    </Button>
                </Tooltip>
            ) : null}

            <Button
                sx={ProjectActionsStyles.button}
                onClick={event => {
                    popover.open(event.currentTarget)
                    event.stopPropagation()
                }}
            >
                <MoreVert />
            </Button>

            <Menu
                open={popover.isOpen}
                anchorEl={popover.anchorEl}
                onClose={popover.close}
                onClick={event => event.stopPropagation()}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={onOpenProject}>{L10n.format(TranslationKeys.project.actions.open)}</MenuItem>
            </Menu>
        </Stack>
    )
}
