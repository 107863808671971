import theme from "../../../../../App.theme"

const UserAvatarStyles = {
    wrapper: {
        height: {
            xs: "1.375rem",
            sm: "2.063rem"
        },
        ml: "1rem",
        mr: 0,
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        bgcolor: theme.palette.divider,
        color: theme.palette.text.primary,
        fontSize: 15,
        fontWeight: "bold"
    }
}

export default UserAvatarStyles
