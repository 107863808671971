import theme from "../../../../../App.theme"
import { ProductTileSize } from "./ProductGroupCard"

const ProductGroupCardStyles = (size: ProductTileSize, hover: boolean, expand: boolean, disabled: boolean) => {
    const getConditionalValue = (condition: boolean, value: string | number, otherValue: string | number) => (condition ? value : otherValue)
    return {
        card: {
            backgroundColor: getConditionalValue(disabled, theme.palette.background.default, "transparent"),
            border: "1px solid " + theme.palette.divider,
            height: getConditionalValue(expand, "100%", getConditionalValue(size === ProductTileSize.Big, 300, 250)),
            width: getConditionalValue(size === ProductTileSize.Big, 350, 300),
            flexDirection: getConditionalValue(size === ProductTileSize.Big, "column", "row"),
            ":hover": {
                boxShadow: disabled ? "none" : theme.shadows.at(5)
            }
        },
        image: {
            display: "block",
            height: getConditionalValue(size === ProductTileSize.Big && (!hover || disabled), 100, 50),
            width: getConditionalValue(size === ProductTileSize.Big && (!hover || disabled), 100, 50),
            objectFit: "contain",
            flexShrink: 0,
            opacity: disabled ? 0.5 : 1,
            transition: `width ${theme.transitions.duration.complex}ms, height ${theme.transitions.duration.complex}ms`
        },
        name: {
            fontWeight: "bold",
            fontSize: hover && !disabled ? theme.typography.body1.fontSize : theme.typography.h6.fontSize,
            opacity: getConditionalValue(disabled, 0.5, 1),
            transition: `font-size ${theme.transitions.duration.complex}ms`
        },
        description: {
            fontSize: theme.typography.body2.fontSize,
            opacity: getConditionalValue(disabled, 0.5, 1)
        },
        expandDescriptionButton: {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
            marginBottom: 1,
            opacity: getConditionalValue(disabled, 0.5, 1),
            ":hover": {
                backgroundColor: "transparent",
                textDecoration: "underline"
            }
        },
        button: {
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            display: getConditionalValue(hover && !disabled, "inherit", "none"),
            minWidth: getConditionalValue(size === ProductTileSize.Big, "inherit", 0),
            width: getConditionalValue(size === ProductTileSize.Big, "100%", 150),
            maxWidth: getConditionalValue(size === ProductTileSize.Big, "100%", 150),
            overflow: "hidden",
            marginTop: "auto !important",
            ":hover": {
                backgroundColor: theme.palette.divider
            },
            "@media (hover: none)": {
                backgroundColor: theme.palette.divider
            }
        }
    }
}

export default ProductGroupCardStyles
