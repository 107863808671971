import { CharacteristicValueInfo } from "@encoway/c-services-js-client/src/catalog/types/response/characteristic/CharacteristicValueInfo"
import { Characteristic, CharacteristicValue } from "@encoway/sales-api-js-client"

/**
 * Merges aggregated characteristic values with filter characteristics.
 * @param aggregatedCharacteristicValues The object containing information about the aggregated possible values.
 * @param filterCharacteristics contains all filters that should be shown.
 */
export default function mergeFilters(aggregatedCharacteristicValues: Record<string, CharacteristicValueInfo[]>, filterCharacteristics?: Characteristic[]) {
    return Object.entries(aggregatedCharacteristicValues)
        .reduce((result: Characteristic[], [characteristicId, characteristicValueInfos]) => {
            const filterCharacteristic = filterCharacteristics?.find(characteristic => characteristic.characteristicId === characteristicId)
            return !filterCharacteristic
                ? result
                : result.concat([
                      { ...filterCharacteristic, possibleValues: mergePossibleValues(characteristicValueInfos, filterCharacteristic.possibleValues) }
                  ])
        }, [])
        .filter(filterCharacteristic => filterCharacteristic.possibleValues.length > 0)
}

/**
 * Merges characteristic value info and possible values.
 * @param characteristicValueInfos The characteristic values of the aggregated characteristics.
 * @param possibleValues The possible values of the default characteristics.
 */
function mergePossibleValues(characteristicValueInfos: CharacteristicValueInfo[], possibleValues: CharacteristicValue[]) {
    return characteristicValueInfos.reduce((result: CharacteristicValue[], characteristicValueInfo) => {
        const matchingCharacteristicValue = possibleValues.find(possibleValue => characteristicValueInfo.value === possibleValue.value)
        return !matchingCharacteristicValue ? result : result.concat([{ ...matchingCharacteristicValue, value: characteristicValueInfo.value }])
    }, [])
}
