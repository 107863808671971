import { GridValueFormatterParams } from "@mui/x-data-grid"
import { Settings } from "../../settings"
import toFormattedString from "../../utils/toFormattedString"

export const dateFormatter = (params: GridValueFormatterParams<string>) => {
    return params.value ? new Date(params.value).toLocaleDateString(Settings.locale) : ""
}

export const numberFormatter = (params: GridValueFormatterParams<number>) => toFormattedString(params.value)

export const priceFormatter = (params: GridValueFormatterParams<number>) => {
    return Boolean(params.value) ? toFormattedString(params.value, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""
}
