import { useEffect } from "react"

/**
 * This widget is used to show the visualization in the configuration UI.
 * It uses the existing Visualization provided by the AbbVisualizationWrapper
 * and moves it on mount into this viewport widget and on unmount back to the AbbVisualizationWrapper
 */
export const AbbVisualizationWidget = () => {
    useEffect(() => {
        //mount: move the visualization from the portal to this widget
        const content = document.getElementById("abb.visualization.content")
        const target = document.getElementById("abb.visualization.target")
        target!.appendChild(content!)
        return () => {
            //unmount: move the visualization back to its portal
            const portal = document.getElementById("abb.visualization.portal")
            portal && portal.appendChild(content!)
        }
    }, [])
    return <div id="abb.visualization.target" />
}
