import AssignmentIcon from "@mui/icons-material/Assignment"
import { Button, Popover } from "@mui/material"
import { GenerateDocumentsButtonStyles } from "./GenerateDocumentsButton.styles"
import { L10n } from "@encoway/l10n"
import Tooltip from "../../../../../components/tooltip/Tooltip"
import usePopover from "../../../../../hooks/usePopover"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import DocumentGenerationForm from "../../../../../components/forms/documentGenerationForm/DocumentGenerationForm"
import { ProductIds } from "../../../../../features/catalog/catalog.constants"
import CatalogApi from "../../../../../features/catalog/catalog.api"
import usePrint from "../../../../../features/sales/hooks/usePrint"

interface GenerateDocumentsButtonProps {
    lineItemId?: string
    disabled: boolean
    disabledReason?: string
}

export default function GenerateDocumentsButton({ lineItemId, disabled, disabledReason }: GenerateDocumentsButtonProps) {
    const popover = usePopover()
    const openPopover = (event: any) => popover.open(event.currentTarget)
    const documentTypes = CatalogApi.useDocumentTypesQuery(ProductIds.DocumentTypes).data ?? []
    const print = usePrint()
    const submit = (documentTemplate: string, documentFormat: string) => print({ documentTemplate, documentFormat, lineItemId })
    return (
        <>
            <Tooltip content={disabledReason ?? ""} disabled={!disabled}>
                <Button sx={GenerateDocumentsButtonStyles.button} variant="outlined" disabled={disabled} endIcon={<AssignmentIcon />} onClick={openPopover}>
                    {L10n.format(TranslationKeys.components.documentGenerationButton.label)}
                </Button>
            </Tooltip>

            <Popover
                open={popover.isOpen}
                onClose={popover.close}
                anchorEl={popover.anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={GenerateDocumentsButtonStyles.popover}
            >
                <DocumentGenerationForm documentTypes={documentTypes} onSubmit={submit} />
            </Popover>
        </>
    )
}
