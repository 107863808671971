import { ITabsStyles } from "@encoway/cui-configurator-components"
import { getTheme } from "@fluentui/react"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const theme = getTheme()

const AbbTabsStyles: ITabsStyles = {
    tabsBar: {
        backgroundColor: "transparent",
        button: {
            backgroundColor: "transparent",
            ":hover": {
                backgroundColor: "transparent"
            }
        }
    },
    content: {
        backgroundColor: theme.palette.white,
        borderRadius: CuiStyleConstants.CONTAINER_BORDER_RADIUS,
        marginTop: 8
    }
}

export default AbbTabsStyles
