import { GridRenderCellParams } from "@mui/x-data-grid"
import Icon from "../../../../../../../components/icons/Icon"
import { FolderOutlined, Person, Settings, SpaceDashboardOutlined } from "@mui/icons-material"
import { Badge } from "@mui/material"
import { isFooter } from "../row/rowUtils"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { BomNode } from "../../../../../../../features/sales/sales.types"
import SalesUtils from "../../../../../../../features/sales/sales.utils"

export default function LineItemTypeCell(params: GridRenderCellParams<BomNode>) {
    if (params.row.item.properties.isCustomLineItem) {
        return (
            <Badge badgeContent={<Person />}>
                <Icon component={SpaceDashboardOutlined} tooltipProps={{ content: L10n.format(TranslationKeys.lineItem.types.customArticle) }} />
            </Badge>
        )
    } else if (params.row.item.properties.configurable) {
        return (
            <Badge badgeContent={<Settings />}>
                <Icon component={SpaceDashboardOutlined} tooltipProps={{ content: L10n.format(TranslationKeys.lineItem.types.configurableArticle) }} />
            </Badge>
        )
    } else if (params.row.item.properties.isArticle) {
        return <Icon component={SpaceDashboardOutlined} tooltipProps={{ content: L10n.format(TranslationKeys.lineItem.types.usualArticle) }} />
    } else if (SalesUtils.lineItems.isFolder(params.row.item) && !isFooter(params.row.item)) {
        return <Icon component={FolderOutlined} tooltipProps={{ content: L10n.format(TranslationKeys.lineItem.types.folder) }} />
    } else {
        return null
    }
}
