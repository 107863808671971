import { defaultSettings, IAppSettings } from "@encoway/cui-application-components"
import { cloneDeep, merge } from "lodash"
import { Settings } from "../../../settings"
import { L10n } from "@encoway/l10n"
import { useToken } from "@encoway/sales-showroom-auth"
import { useAppSelector } from "../../../store/store"
import EnvironmentApi from "../../../features/environment/environment.api"
import { useEffect, useState } from "react"

export default function useCuiAppSettings(): IAppSettings | undefined {
    const tokenFunction = useToken()
    const configurationId = useAppSelector(state => state.sales.configurationId)
    const articleName = useAppSelector(state => state.sales.configurationProductId)
    const visualizationToken = EnvironmentApi.useEnvironmentQuery().data?.visualizationToken
    const [state, setState] = useState<IAppSettings>()

    useEffect(() => {
        setState(
            !configurationId
                ? undefined
                : merge(cloneDeep(defaultSettings), Settings, {
                      locale: L10n.currentFullLocale(),
                      visualization: { token: visualizationToken, settings: defaultSettings.visualization?.settingsConfiguration },
                      configuration: {
                          token: tokenFunction,
                          options: {
                              configurationId: configurationId,
                              articleName: articleName
                          }
                      }
                  })
        )
    }, [configurationId, visualizationToken, tokenFunction, articleName])

    return state
}
