import { GridRenderCellParams } from "@mui/x-data-grid"
import Icon from "../../../../../../../components/./icons/Icon"
import { Computer, Warning } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { Stack } from "@mui/material"
import { BomNode } from "../../../../../../../features/sales/sales.types"
import SalesUtils from "../../../../../../../features/sales/sales.utils"

export default function RemarksCell(params: GridRenderCellParams<BomNode>) {
    return (
        <Stack direction="row" alignItems="center">
            {params.row.item.properties.readystate && (
                <Icon
                    component={SalesUtils.lineItems.getStatusIcon(params.row.item.properties.readystate)}
                    color={SalesUtils.lineItems.getStatusColor(params.row.item.properties.readystate)}
                    tooltipProps={{ content: SalesUtils.lineItems.getStatusTranslation(params.row.item.properties.readystate)! }}
                />
            )}

            {params.row.item.properties.QUANTITY_CHANGED_MANUALLY === "true" && (
                <Icon component={Warning} color="warning" tooltipProps={{ content: L10n.format(TranslationKeys.lineItem.manualQuantityChangeWarning) }} />
            )}

            {params.row.item.properties.COMMON_OPTION_SET_BY_SYSTEM === "true" && (
                <Icon
                    component={Computer}
                    color="secondary"
                    tooltipProps={{ content: L10n.format(TranslationKeys.pages.project.composition.commonOptionSetBySystem) }}
                />
            )}
        </Stack>
    )
}
