import { GridCell, GridCellProps, useGridApiContext } from "@mui/x-data-grid"
import { MouseEvent, useEffect, useRef } from "react"
import CustomGridCellStyles from "./CustomGridCell.styles"
import EditIcon from "@mui/icons-material/Edit"
import Tooltip from "../../../tooltip/Tooltip"

//FIXME in ABBM-543
export let customGridCellInput: HTMLInputElement | null | undefined

export default function CustomGridCell({ children, ...props }: GridCellProps) {
    const apiRef = useGridApiContext()
    const cellRef = useRef<HTMLDivElement>(null)

    const value =
        props.column.valueFormatter?.({
            id: props.rowId,
            field: props.field,
            value: props.value,
            api: apiRef.current
        }) ?? props.value

    //FIXME in ABBM-543
    useEffect(() => {
        if (props.cellMode === "edit") {
            customGridCellInput = cellRef.current?.querySelector("input")
        }
    }, [value, props.cellMode])

    const showEditIcon = props.isEditable && props.cellMode !== "edit" && props.column.type !== "boolean"

    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        if (showEditIcon) {
            event.stopPropagation()
            apiRef.current.startCellEditMode({ id: props.rowId, field: props.field })
        }
    }

    return (
        <Tooltip
            content={value}
            disabled={props.cellMode === "edit" || !value || !!props.column.renderCell}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            onClick={onClick}
        >
            <GridCell {...props} ref={cellRef} data-cy={`cell.${props.field}`}>
                {children ?? value}
                {showEditIcon && (
                    <div className="soenfo" style={CustomGridCellStyles.editIconDiv}>
                        <EditIcon style={CustomGridCellStyles.editIcon} />
                    </div>
                )}
            </GridCell>
        </Tooltip>
    )
}
