import { Card, CardProps } from "@mui/material"
import ProjectInformation from "./projectInformation/ProjectInformation"
import ProjectActions from "./projectActions/ProjectActions"
import { AbbSalesDocumentEntity } from "../../../../../features/sales/sales.types"

export interface RecentProjectsStackItemProps extends CardProps {
    project: AbbSalesDocumentEntity
    onOpenProject: (salesDocument: AbbSalesDocumentEntity) => void
}

export default function ProjectCard({ project, onOpenProject, ...props }: RecentProjectsStackItemProps) {
    const openProject = () => onOpenProject(project)
    return (
        <Card {...props} onClick={openProject}>
            <ProjectActions project={project} onOpenProject={openProject} />
            <ProjectInformation project={project} />
        </Card>
    )
}
