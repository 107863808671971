import { LinkedTree, LinkedTreeProps } from "@encoway/cui-configurator-components"
import AbbLinkedTreeStyles from "./AbbLinkedTree.styles"

export default function AbbLinkedTree(props: LinkedTreeProps) {
    return (
        <div data-cy={"AbbLinkedTree"}>
            <LinkedTree {...props} styles={AbbLinkedTreeStyles} />
        </div>
    )
}
