import usePopover from "../../hooks/usePopover"
import { Popover, PopoverProps, Stack, StackProps, Typography } from "@mui/material"
import { MouseEvent, ReactNode, TouchEvent, useRef } from "react"

export interface TooltipProps extends Omit<StackProps, "ref" | "content"> {
    content: string | ReactNode
    disabled?: boolean
    anchorOrigin?: PopoverProps["anchorOrigin"]
    transformOrigin?: PopoverProps["transformOrigin"]
}

export default function Tooltip({ content, disabled, anchorOrigin, transformOrigin, children, ...stackProps }: TooltipProps) {
    const ref = useRef<HTMLDivElement>(null)
    const popover = usePopover()

    const onMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
        stackProps.onMouseEnter?.(event)
        popover.open(ref.current!, 500)
    }

    const onMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
        stackProps.onMouseLeave?.(event)
        popover.close()
    }

    const onTouchEnd = (event: TouchEvent<HTMLDivElement>) => {
        stackProps.onTouchStart?.(event)
        popover.open(ref.current!)
    }

    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        popover.close()
        stackProps.onClick?.(event)
    }

    return (
        <>
            <Stack {...stackProps} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onTouchEnd={onTouchEnd} onClick={onClick}>
                {children}
            </Stack>
            <Popover
                open={popover.isOpen && !disabled}
                onClose={popover.close}
                anchorEl={popover.anchorEl}
                disableRestoreFocus
                disableScrollLock
                anchorOrigin={anchorOrigin ?? { vertical: "top", horizontal: "center" }}
                transformOrigin={transformOrigin ?? { vertical: "bottom", horizontal: "center" }}
                sx={{ pointerEvents: "none" }}
            >
                {typeof content === "string" ? <Typography sx={{ mx: 1, my: 0.5 }}>{content}</Typography> : content}
            </Popover>
        </>
    )
}
