const TranslationKeys = {
    project: {
        properties: {
            additionalInformation: "project.properties.additionalInformation",
            areaSalesManagerEmail: "project.properties.areaSalesManagerEmail",
            areaSalesManagerName: "project.properties.areaSalesManagerName",
            createdAt: "project.properties.createdAt",
            createdBy: "project.properties.createdBy",
            currency: "project.properties.currency",
            customer: "project.properties.customer",
            customerNumber: "project.properties.customerNumber",
            destinationCountry: "project.properties.destinationCountry",
            driveSmart6Id: "project.properties.driveSmart6Id",
            endCustomer: "project.properties.endCustomer",
            factoryOfferRef: "project.properties.factoryOfferRef",
            factorySapOrderNumber: "project.properties.factorySapOrderNumber",
            id: "project.properties.id",
            industrySegment: "project.properties.industrySegment",
            localSalesUnit: "project.properties.localSalesUnit",
            lockedBy: "project.properties.lockedBy",
            modifiedAt: "project.properties.modifiedAt",
            modifiedBy: "project.properties.modifiedBy",
            priceList: "project.properties.priceList",
            projectName: "project.properties.projectName",
            salesforceOpportunityNumber: "project.properties.salesforceOpportunityNumber",
            salesUnitContactEmail: "project.properties.salesUnitContactEmail",
            salesUnitContactName: "project.properties.salesUnitContactName",
            status: "project.properties.status"
        },
        statusValues: {
            draft: "project.statusValues.draft",
            offered: "project.statusValues.offered",
            ordered: "project.statusValues.ordered",
            closed: "project.statusValues.closed"
        },
        actions: {
            open: "project.actions.open",
            delete: "project.actions.delete",
            duplicate: "project.actions.duplicate"
        },
        lockedDialog: {
            title: "project.lockedDialog.title",
            message: "project.lockedDialog.message", // use {user} for the user who locks the project
            unlockWarning: "project.lockedDialog.unlockWarning",
            submitButtonLabel: "project.lockedDialog.submitButtonLabel"
        },
        deleteDialog: {
            title: "project.deleteDialog.title",
            message: "project.deleteDialog.message",
            confirmButtonLabel: "project.deleteDialog.confirmButtonLabel",
            cancelButtonLabel: "project.deleteDialog.cancelButtonLabel"
        },
        newProject: {
            button: {
                label: "project.newProject.button.label"
            },
            dialog: {
                title: "project.newProject.dialog.title",
                cancelButtonLabel: "project.newProject.dialog.cancelButtonLabel",
                submitButtonLabel: "project.newProject.dialog.submitButtonLabel"
            },
            exists: {
                title: "project.newProject.exists.title",
                showExistingProjects: "project.newProject.exists.showExistingProjects",
                createNewProject: "project.newProject.exists.createNewProject",
                message: "project.newProject.exists.message"
            }
        }
    },

    lineItem: {
        copyAppendix: "lineItem.copyAppendix",
        manualQuantityChangeWarning: "lineItem.manualQuantityChangeWarning",
        typeCodePlaceholder: "lineItem.typeCodePlaceholder",
        properties: {
            articleId: "lineItem.properties.articleId",
            configurationName: "lineItem.properties.configurationName",
            createdAt: "lineItem.properties.createdAt",
            createdBy: "lineItem.properties.createdBy",
            id: "lineItem.properties.id",
            modifiedAt: "lineItem.properties.modifiedAt",
            modifiedBy: "lineItem.properties.modifiedBy",
            multiplier: "lineItem.properties.multiplier",
            option: "lineItem.properties.option",
            plainListPrice: "lineItem.properties.plainListPrice",
            plainListPriceWithCurrency: "lineItem.properties.plainListPriceWithCurrency",
            plainTransferPrice: "lineItem.properties.plainTransferPrice",
            plusCode: "lineItem.properties.plusCode",
            positionNumber: "lineItem.properties.positionNumber",
            positionQuantity: "lineItem.properties.positionQuantity",
            priceGroup: "lineItem.properties.priceGroup",
            product: "lineItem.properties.product",
            productName: "lineItem.properties.productName",
            quoteRelevant: "lineItem.properties.quoteRelevant",
            shortText: "lineItem.properties.shortText",
            status: "lineItem.properties.status",
            totalListPrice: "lineItem.properties.totalListPrice",
            totalTransferPrice: "lineItem.properties.totalTransferPrice",
            typeCode: "lineItem.properties.typeCode"
        },
        types: {
            label: "lineItem.types.label",
            folder: "lineItem.types.folder",
            configurableArticle: "lineItem.types.configurableArticle",
            customArticle: "lineItem.types.customArticle",
            usualArticle: "lineItem.types.usualArticle"
        },
        statusValues: {
            ready: "lineItem.statusValues.ready",
            notReady: "lineItem.statusValues.notReady",
            conflict: "lineItem.statusValues.conflict"
        },
        actions: {
            delete: "lineItem.actions.delete",
            duplicate: "lineItem.actions.duplicate",
            edit: "lineItem.actions.edit",
            print: "lineItem.actions.print"
        },
        deleteDialog: {
            title: "lineItem.deleteDialog.title",
            message: "lineItem.deleteDialog.message",
            confirmButtonLabel: "lineItem.deleteDialog.confirmButtonLabel",
            cancelButtonLabel: "lineItem.deleteDialog.cancelButtonLabel"
        },
        newArticle: {
            button: {
                label: "lineItem.newArticle.button.label"
            },
            dialog: {
                title: "lineItem.newArticle.dialog.title",
                cancelButtonLabel: "lineItem.newArticle.dialog.cancelButtonLabel",
                submitButtonLabel: "lineItem.newArticle.dialog.submitButtonLabel"
            }
        }
    },

    configuration: {
        notification: {
            warning: "configuration.notification.warning",
            error: "configuration.notification.error",
            info: "configuration.notification.info"
        },
        invalidPresetsMessageTitle: "configuration.invalidPresetsMessageTitle"
    },

    pages: {
        dashboard: {
            pageName: "pages.dashboard.pageName",
            recentProjectsWidget: {
                title: "pages.dashboard.recentProjectsWidget.title",
                viewAllProjects: "pages.dashboard.recentProjectsWidget.viewAllProjects",
                created: "pages.dashboard.recentProjectsWidget.created",
                modified: "pages.dashboard.recentProjectsWidget.modified"
            },
            documentsWidget: {
                title: "pages.dashboard.documentsWidget.title",
                inputLabel: "pages.dashboard.documentsWidget.inputLabel",
                buttonLabel: "pages.dashboard.documentsWidget.buttonLabel"
            },
            helpWidget: {
                headline: "pages.dashboard.helpWidget.headline",
                text: "pages.dashboard.helpWidget.text",
                link: "pages.dashboard.helpWidget.link"
            },
            newsWidget: {
                title: "pages.dashboard.newsWidget.title",
                viewAllNews: "pages.dashboard.newsWidget.viewAll"
            }
        },

        projects: {
            pageName: "pages.projects.pageName",
            searchInputPlaceholder: "pages.projects.searchInputPlaceholder",
            noProjectsFound: "pages.projects.noProjectsFound",
            noProjectResults: "pages.projects.noProjectResults"
        },

        project: {
            changeStatusButtonLabel: "pages.project.changeStatusButtonLabel",
            saveButtonLabel: "pages.project.saveButtonLabel",
            editButtonLabel: "pages.project.editButtonLabel",
            tabs: {
                details: "pages.project.tabs.details",
                catalog: "pages.project.tabs.catalog",
                composition: "pages.project.tabs.composition",
                reports: "pages.project.tabs.reports"
            },
            details: {
                generalInformation: "pages.project.details.generalInformation",
                projectInformation: "pages.project.details.projectInformation",
                customerInformation: "pages.project.details.customerInformation",
                contactInformation: "pages.project.details.contactInformation"
            },
            catalog: {
                showMore: "pages.project.catalog.showMore",
                showLess: "pages.project.catalog.showLess",
                link: "pages.project.catalog.link",
                selectProductGroupButtonLabel: "pages.project.catalog.selectProductGroupButtonLabel",
                searchInputPlaceholder: "pages.project.catalog.searchInputPlaceholder",
                searchResultBreadcrumb: "pages.project.catalog.searchResultBreadcrumb",
                noSearchResultsMessage: "pages.project.catalog.noSearchResultsMessage",
                searchResultsOfCatalog: "pages.project.catalog.searchResultsOfCatalog",
                productCategoryHint: "pages.project.catalog.productCategoryHint",
                selectDriveSegmentTitle: "pages.project.catalog.selectDriveSegmentTitle",
                quickSelection: {
                    addToCompositionButtonLabel: "pages.project.catalog.quickSelection.addToCompositionButtonLabel",
                    addToCompositionSuccessMessage: "pages.project.catalog.quickSelection.addToCompositionSuccessMessage", // use {numberOfProductsToAdd} for number of products to add
                    addToCompositionErrorMessage: "pages.project.catalog.quickSelection.addToCompositionErrorMessage", // use {numberOfFailedRequests} for number of failed requests
                    defineCommonOptions: "pages.project.catalog.quickSelection.defineCommonOptions",
                    progress: "pages.project.catalog.quickSelection.progress" // use {count} as counter for added products, use total for total number of products
                },
                productSelection: {
                    configureButtonLabel: "pages.project.catalog.productSelection.configureButtonLabel",
                    addToCompositionButtonLabel: "pages.project.catalog.productSelection.addToCompositionButtonLabel",
                    addToCompositionSuccessMessage: "pages.project.catalog.productSelection.addToCompositionSuccessMessage", // use {numberOfProductsToAdd} for number of products to add
                    addToCompositionErrorMessage: "pages.project.catalog.productSelection.addToCompositionErrorMessage" // use {numberOfFailedRequests} for number of failed requests
                },
                tabs: {
                    catalog: "pages.project.catalog.tabs.catalog",
                    quickSelection: "pages.project.catalog.tabs.quickSelection"
                }
            },
            composition: {
                addFolderButtonLabel: "pages.project.composition.addFolderButtonLabel",
                addLineItemButtonLabel: "pages.project.composition.addLineItemButtonLabel",
                copyTypeCode: "pages.project.composition.copyTypeCode",
                noLineItemResults: "pages.project.composition.noLineItemResults",
                noLineItemsFound: "pages.project.composition.noLineItemsFound",
                newConfigurationName: "pages.project.composition.newConfigurationName",
                newFolderName: "pages.project.composition.newFolderName",
                remarks: "pages.project.composition.remarks",
                total: "pages.project.composition.total",
                commonOptionSetBySystem: "pages.project.composition.commonOptionSetBySystem",
                commonFolder: "pages.project.composition.commonFolder"
            }
        },
        configuration: {
            dimensioningButtonLabel: "pages.configuration.dimensioningButtonLabel",
            cancelButtonLabel: "pages.configuration.cancelButtonLabel",
            generateDocumentsButton: {
                disabledReason: "pages.configuration.generateDocumentsButton.disabledReason"
            },
            applyButton: {
                label: "pages.configuration.applyButton.label",
                stayOptionLabel: "pages.configuration.applyButton.stayOptionLabel",
                navigateOptionLabel: "pages.configuration.applyButton.navigateOptionLabel"
            },
            matlab: {
                notTerminalError: {
                    title: "pages.configuration.matlab.notTerminalError.title",
                    message: "pages.configuration.matlab.notTerminalError.message"
                },
                error: {
                    title: "pages.configuration.matlab.error.title",
                    unknownErrorMessage: "pages.configuration.matlab.error.unknownErrorMessage"
                }
            },
            graph: {
                renderError: "pages.configuration.graph.renderError" // use {graphName} to render the name of the graph
            },
            abbInputField: {
                rangeText: "pages.configuration.abbInputField.rangeText" // use {minValue, maxValue} to render the values of the range
            },
            visualization: {
                ar: {
                    buttonLabel: "pages.configuration.visualization.ar.buttonLabel",
                    dialogTitle: "pages.configuration.visualization.ar.dialogTitle",
                    qrCodeAltText: "pages.configuration.visualization.ar.qrCodeAltText",
                    errorMessage: "pages.configuration.visualization.ar.errorMessage",
                    dialogText: "pages.configuration.visualization.ar.dialogText",
                    dialogCloseButton: "pages.configuration.visualization.ar.dialogCloseButton"
                },
                exitFullscreenButtonLabel: "pages.configuration.visualization.exitFullscreenButtonLabel"
            }
        }
    },

    components: {
        dataGrid: {
            columns: {
                actions: "components.dataGrid.columns.actions",
                dragAndDrop: "components.dataGrid.columns.dragAndDrop"
            },
            filters: {
                before: "components.dataGrid.filters.before",
                after: "components.dataGrid.filters.after",
                contains: "components.dataGrid.filters.contains"
            }
        },
        dialog: {
            defaultCancelButtonLabel: "components.dialog.defaultCancelButtonLabel",
            defaultConfirmButtonLabel: "components.dialog.defaultConfirmButtonLabel"
        },
        documentGenerationButton: {
            label: "components.documentGenerationButton.label"
        },
        documentGenerationForm: {
            typeInputLabel: "components.documentGenerationForm.typeInputLabel",
            formatInputLabel: "components.documentGenerationForm.formatInputLabel",
            submitButtonLabel: "components.documentGenerationForm.submitButtonLabel"
        },
        dropdown: {
            unselectOption: "components.dropdown.filterDropdownUnselect",
            placeholder: "components.dropdown.placeholder"
        },
        error: {
            title: "components.error.title",
            message: "components.error.message"
        },
        header: {
            title: "components.header.title",
            subTitle: "components.header.subTitle",
            welcome: "components.header.welcome",
            sessionTimeout: {
                timeUntil: "components.header.sessionTimeout.timeUntil", // use {time} for the formatted time left
                dialog: {
                    title: "components.header.sessionTimeout.dialog.title", // use {time} for the formatted time left
                    message: "components.header.sessionTimeout.dialog.message",
                    confirmButtonLabel: "components.header.sessionTimeout.dialog.confirmButtonLabel",
                    cancelButtonLabel: "components.header.sessionTimeout.dialog.cancelButtonLabel",
                    loggedOutTitle: "components.header.sessionTimeout.dialog.loggedOutTitle",
                    loggedOutMessage: "components.header.sessionTimeout.dialog.loggedOutMessage"
                },
                browserNotification: {
                    title: "components.header.sessionTimeout.browserNotification.title",
                    message: "components.header.sessionTimeout.browserNotification.message"
                }
            },
            logoutDialog: {
                title: "components.header.logoutDialog.title",
                message: "components.header.logoutDialog.message",
                confirmButtonLabel: "components.header.logoutDialog.confirmButtonLabel",
                cancelButtonLabel: "components.header.logoutDialog.cancelButtonLabel"
            }
        },
        input: {
            inputPlaceholder: "components.input.inputPlaceholder",
            searchInputPlaceholder: "components.input.searchInputPlaceholder"
        },
        textBox: {
            expand: "components.textBox.expand",
            truncate: "components.textBox.truncate"
        },
        unsavedChangesDialog: {
            title: "components.unsavedChangesDialog.title",
            message: "components.unsavedChangesDialog.message",
            save: "components.unsavedChangesDialog.save",
            decline: "components.unsavedChangesDialog.decline",
            cancel: "components.unsavedChangesDialog.cancel"
        }
    },
    error: {
        login: {
            accessDenied: {
                title: "error.login.accessDenied.title",
                body: "error.login.accessDenied.body"
            }
        },
        button: "error.button"
    },
    busy: {
        lineItem: {
            update: "busy.lineItem.update"
        },
        configuration: {
            add: "busy.configuration.add",
            save: "busy.configuration.save"
        },
        visualization: {
            ar: "busy.visualization.ar",
            image: "busy.visualization.image",
            save: "busy.visualization.save"
        }
    }
} as const

export default TranslationKeys
