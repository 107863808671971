import VisualizationApi from "./visualization.api"
import useCuiAppSettings from "../../pages/configurationPage/hooks/useCuiAppSettings"
import { useEffect, useState } from "react"
import { SerializedError } from "@reduxjs/toolkit"
import { Visualization } from "@encoway/visual-editor"
import { useAppDispatch, useAppSelector } from "../../store/store"
import BusySlice from "../busy/busy.slice"
import { cloneDeep } from "lodash"
import CuiUtils from "../../pages/configurationPage/components/cui/utils/CuiUtils"
import { CuiViewPorts } from "../../pages/configurationPage/components/cui/constants/CuiViewPorts"

export default function useVisualization() {
    const cuiAppSettings = useCuiAppSettings()
    const [error, setError] = useState<SerializedError | undefined>()
    const configurationContainer = useAppSelector(state => state.sales.configurationContainer)
    const skip = !(configurationContainer && CuiUtils.findParameter(configurationContainer, parameter => parameter.viewPort === CuiViewPorts.Visualization))
    const visualizationQuery = VisualizationApi.useVisualizationQuery(cuiAppSettings, { skip })
    const [visualization, setVisualization] = useState<Visualization | undefined>(visualizationQuery.data?.visualization)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (visualizationQuery.data) {
            setVisualization(visualizationQuery.data.visualization)
        }
    }, [visualizationQuery.data])

    useEffect(() => {
        if (visualizationQuery.error) {
            setError(visualizationQuery.error)
        }
    }, [visualizationQuery.error])

    const render = async () => {
        if (visualization) {
            try {
                dispatch(BusySlice.actions.setBusy())
                await visualizationQuery.data!.update()
            } catch (e) {
                console.error("failed to render", e)
                throw new Error("failed to render")
            } finally {
                dispatch(BusySlice.actions.setIdle())
            }
        }
    }

    return {
        error: error?.message,
        settings: cloneDeep(visualizationQuery?.data?.settings),
        visualization,
        render,
        update: visualizationQuery?.data?.update
    }
}
