import { CSSProperties, ReactElement, ReactNode } from "react"
import { Box, Stack, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material"
import { SxProps } from "@mui/system"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import TabLabel from "./tabLabel/TabLabel"

export interface Tab {
    cypressId: string
    label: string
    disabled?: boolean
    icon?: ReactElement
    children?: ReactNode
    route?: string
}

export interface TabsStyles {
    tabs?: SxProps
    tabsIndicator?: CSSProperties
    tab?: (tab: number, selectedTab: number) => SxProps
    tabLabel?: (tab: number, selectedTab: number) => SxProps
    contentBox?: (selectedTab: number) => SxProps
}

export interface TabsProps {
    tabs: Tab[]
    selectedTab?: number
    setSelectedTab?: (tab: number) => void
    tabsStyles?: TabsStyles
    children?: ReactNode
}

export default function Tabs({ tabs, selectedTab, setSelectedTab, tabsStyles, children }: TabsProps) {
    const navigate = useNavigate()
    const location = useLocation()
    selectedTab = selectedTab || tabs.findIndex(tab => tab?.route && location.pathname.includes(tab.route))
    if (selectedTab < 0) selectedTab = 0
    return (
        <Stack>
            <MuiTabs
                value={selectedTab}
                TabIndicatorProps={{ style: { transition: "none", ...tabsStyles?.tabsIndicator } }}
                sx={tabsStyles?.tabs}
                indicatorColor="secondary"
                data-cy={"MuiTabs"}
            >
                {tabs.map((tab, index) => (
                    <MuiTab
                        data-cy={`MuiTabs.MuiTab.${tab.cypressId}`}
                        key={tab.label}
                        disabled={tab.disabled}
                        disableRipple
                        label={
                            <TabLabel
                                data-cy={"MuiTabs.MuiTab.TabLabel"}
                                tab={tab}
                                sx={tabsStyles?.tabLabel ? tabsStyles?.tabLabel(index, selectedTab!) : undefined}
                            />
                        }
                        sx={tabsStyles?.tab ? tabsStyles.tab(index, selectedTab!) : undefined}
                        onClick={() => {
                            setSelectedTab && setSelectedTab(index)
                            tab.route && navigate(tab.route)
                        }}
                    />
                ))}
                <MuiTab
                    data-cy={"MuiTabs.MuiTab"}
                    label={
                        <TabLabel data-cy={"MuiTabs.MuiTab.TabLabel"} sx={tabsStyles?.tabLabel ? tabsStyles?.tabLabel(tabs.length, selectedTab) : undefined} />
                    }
                    sx={tabsStyles?.tab ? tabsStyles.tab(tabs.length, selectedTab) : undefined}
                    disabled
                />
            </MuiTabs>
            <Box sx={tabsStyles?.contentBox ? tabsStyles.contentBox(selectedTab) : undefined}>{children || tabs[selectedTab].children}</Box>
        </Stack>
    )
}
