import { IStyle, mergeStyles } from "@fluentui/react/lib/Styling"

const searchBoxBaseStyles: IStyle = {
    minHeight: "2.0rem",
    display: "flex",
    alignItems: "center",
    marginBottom: "-2.0rem",
    position: "relative",
    zIndex: "999 !important"
}

const FilterDetailDropdownStyles = {
    searchBoxDefault: mergeStyles(
        {
            visibility: "hidden"
        },
        searchBoxBaseStyles
    ),
    searchBoxVisible: mergeStyles(
        {
            visibility: "visible"
        },
        searchBoxBaseStyles
    )
}

export default FilterDetailDropdownStyles
