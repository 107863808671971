import { ResizeHandle as ResizeHandleProps } from "react-resizable"
import { ForwardedRef, forwardRef } from "react"
import theme from "../../App.theme"

export const ResizeHandle = forwardRef((props: ResizeHandleProps, ref: ForwardedRef<any>) => {
    // @ts-ignore
    const { handleAxis, ...restProps } = props
    return (
        <div
            ref={ref}
            className={`custom-handle-${handleAxis}`}
            {...restProps}
            style={{
                width: "auto",
                marginLeft: "48px",
                height: "8px",
                cursor: "ns-resize",
                borderBottom: `3px solid ${theme.palette.divider}`,
                borderTop: `3px solid ${theme.palette.divider}`
            }}
        />
    )
})
