import { getTheme } from "@fluentui/react"
import { ISectionStyles } from "@encoway/cui-configurator-components/src/components/Section/Section.types"

const theme = getTheme()

const AbbSectionStyles: Partial<ISectionStyles> = {
    root: {
        paddingBottom: 8,
        selectors: {
            ".ms-Label": {
                color: theme.palette.themePrimary
            }
        }
    }
}

export default AbbSectionStyles
