import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { dateFormatter, numberFormatter, priceFormatter } from "../../../../../../components/dataGrid/dataGridValueFormatters"
import RemarksCell from "../components/remarksCell/RemarksCell"
import QuoteRelevantCell from "../components/quoteRelevantCell/QuoteRelevantCell"
import ProductNameCell from "../components/productNameCell/ProductNameCell"
import PlusCodeCell from "../components/plusCodeCell/PlusCodeCell"
import ActionsCell from "../components/actionsCell/ActionsCell"
import getPlusCodeCellValue from "../components/plusCodeCell/getPlusCodeCellValue"
import setCellValue from "../utils/setCellValue"
import { DragAndDropButtonCell } from "../components/dragAndDropButtonCell/DragAndDropButtonCell"
import renderEmptyHeader from "../../../../../../components/dataGrid/utils/renderEmptyHeader"
import renderHeaderWithCurrency from "../utils/renderHeaderWithCurrency"
import LineItemTypeCell from "../components/lineItemTypeCell/LineItemTypeCell"
import { LineItemProperties, SalesDocumentProperties } from "../../../../../../features/sales/sales.constants"
import { GridColDef } from "@mui/x-data-grid"

const lineItemsDataGridColumns: GridColDef[] = [
    {
        field: "dragAndDrop",
        headerName: TranslationKeys.components.dataGrid.columns.dragAndDrop,
        width: 50,
        editable: false,
        renderCell: DragAndDropButtonCell,
        renderHeader: renderEmptyHeader
    },
    {
        field: LineItemProperties.QUOTE_RELEVANT,
        headerName: TranslationKeys.lineItem.properties.quoteRelevant,
        renderCell: QuoteRelevantCell,
        type: "boolean",
        editable: false
    },
    {
        field: LineItemProperties.POSITION_NUMBER,
        headerName: TranslationKeys.lineItem.properties.positionNumber,
        width: 80
    },
    {
        field: LineItemProperties.POSITION_QUANTITY,
        headerName: TranslationKeys.lineItem.properties.positionQuantity,
        valueFormatter: numberFormatter,
        type: "number",
        width: 75,
        valueSetter: params => (Number.isInteger(params.value) && params.value >= 0 ? setCellValue(params, LineItemProperties.POSITION_QUANTITY) : params.row)
    },
    {
        field: "remarks",
        headerName: TranslationKeys.pages.project.composition.remarks,
        sortable: false,
        maxWidth: 80,
        editable: false,
        renderCell: RemarksCell
    },
    {
        field: "articleType",
        headerName: TranslationKeys.lineItem.types.label,
        sortable: false,
        width: 0,
        editable: false,
        renderCell: LineItemTypeCell,
        renderHeader: renderEmptyHeader
    },
    {
        field: LineItemProperties.PRODUCT_NAME,
        headerName: TranslationKeys.lineItem.properties.productName,
        minWidth: 200,
        flex: 1,
        renderCell: ProductNameCell
    },
    {
        field: LineItemProperties.PLUS_CODE,
        headerName: TranslationKeys.lineItem.properties.plusCode,
        minWidth: 200,
        flex: 1,
        renderCell: PlusCodeCell,
        valueGetter: getPlusCodeCellValue
    },
    {
        field: LineItemProperties.ARTICLE_ID,
        headerName: TranslationKeys.lineItem.properties.articleId,
        width: 220
    },
    {
        field: "option",
        headerName: TranslationKeys.lineItem.properties.option,
        width: 80,
        editable: false
    },
    {
        field: LineItemProperties.LIST_PRICE,
        headerName: TranslationKeys.lineItem.properties.plainListPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.PRICES_CURRENCY)
    },
    {
        field: LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY,
        headerName: TranslationKeys.lineItem.properties.plainListPriceWithCurrency,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.MULTIPLIER,
        headerName: TranslationKeys.lineItem.properties.multiplier,
        valueFormatter: priceFormatter
    },
    {
        field: LineItemProperties.PRICEGROUP,
        headerName: TranslationKeys.lineItem.properties.priceGroup
    },
    {
        field: LineItemProperties.PLAIN_TRANSFER_PRICE,
        headerName: TranslationKeys.lineItem.properties.plainTransferPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.TOTAL_LIST_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalListPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.TOTAL_TRANSFER_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalTransferPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.ABB_LINE_ITEM_ID,
        headerName: TranslationKeys.lineItem.properties.id,
        width: 120
    },
    {
        field: LineItemProperties.CONFIGURATION_NAME,
        headerName: TranslationKeys.lineItem.properties.configurationName,
        width: 200
    },
    {
        field: LineItemProperties.CREATED_BY,
        headerName: TranslationKeys.lineItem.properties.createdBy,
        width: 150
    },
    {
        field: LineItemProperties.CREATED_AT,
        headerName: TranslationKeys.lineItem.properties.createdAt,
        type: "date",
        valueFormatter: dateFormatter
    },
    {
        field: LineItemProperties.MODIFIED_AT,
        headerName: TranslationKeys.lineItem.properties.modifiedAt,
        type: "date",
        width: 150,
        valueFormatter: dateFormatter
    },
    {
        field: LineItemProperties.MODIFIED_BY,
        headerName: TranslationKeys.lineItem.properties.modifiedBy
    },
    {
        field: "actions",
        headerName: TranslationKeys.components.dataGrid.columns.actions,
        type: "actions",
        width: 10,
        hideable: false,
        editable: false,
        renderHeader: renderEmptyHeader,
        getActions: ActionsCell
    }
]

export default lineItemsDataGridColumns
